import { Component } from '@angular/core';

@Component({
  selector: 'app-sobre-recarrega-sp',
  templateUrl: './sobre-recarrega-sp.component.html',
  styleUrls: ['./sobre-recarrega-sp.component.css']
})
export class SobreRecarregaSpComponent {

}
