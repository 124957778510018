import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PwaInstallService {

  private deferredPrompt: any;

  constructor() { }

  setupInstallBanner() {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.deferredPrompt = event;
    });
  }

  installPWA() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
        } else {
        }
        this.deferredPrompt = null;
      });
    }
  }

  //Verifica se esta instalado
  isPWAInstalled(): boolean {
    const navigatorAny: any = window.navigator;
    const isInStandaloneMode = () => ('standalone' in navigatorAny) && (navigatorAny.standalone);
    return (isInStandaloneMode() || (window.matchMedia('(display-mode: standalone)').matches));
  }
  
}
