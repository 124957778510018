import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/services/dataService';

@Component({
  selector: 'app-email-check-esqueci-senha',
  templateUrl: './email-check-esqueci-senha.component.html',
  styleUrls: ['../email-check/email-check.component.css']
})
export class EmailCheckEsqueciSenhaComponent {

  email: string = '';

  constructor(private router: Router,private dataService: DataService) {
    this.email = this.dataService.getEmail();

  }

  redirectToEmailProvider(domain: string) {
    const emailProviderUrl = `https://${domain}`;
    window.open(emailProviderUrl, '_blank'); // Abre o provedor de e-mail em uma nova aba.

  }

  irParaTelaLogin() {
    this.router.navigate(['/login']);
  }
}
