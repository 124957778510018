import { Component, OnInit, OnDestroy } from '@angular/core';
import L from 'leaflet';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit, OnDestroy {

  mapa: any;

  ngOnInit(): void {
    this.mapa = L.map('mapa', {
      center: [-23.5505, -46.6333], // Coordenadas de São Paulo
      zoom: 12,
      maxBounds: L.latLngBounds([-24.0, -47.0], [-23.0, -45.0]) // Limites para São Paulo
    });

    L.tileLayer('https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg', {
      attribution: '&copy; <a href="https://maps.stamen.com/">Stamen Design</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 20
    }).addTo(this.mapa);
    

    this.mapa.scrollWheelZoom.disable();

    this.mapa.on('zoomend', () => {
      if (this.mapa.getZoom() < 12) {
        this.mapa.setZoom(12); // Define o nível de zoom mínimo
      }
    });

    // Adiciona um marcador no CEP 05569-120 com um pop-up
    const marcador = L.marker([-23.5801, -46.7000]).addTo(this.mapa);
    marcador.bindPopup('Evento Horta').openPopup();


    // Adiciona um botão fora do mapa que, ao ser clicado, move para o marcador com zoom
    const botaoExterno = document.createElement('button');
    botaoExterno.innerHTML = 'Ir para Evento Horta';
    botaoExterno.addEventListener('click', () => {


      this.mapa.flyTo([-23.5801, -46.7000], 16); // 16 é o nível de zoom desejado
    });



    // Adiciona o botão ao corpo do documento
    document.body.appendChild(botaoExterno);
  }





ngOnDestroy() {
  this.mapa.invalidateSize();
}
}
