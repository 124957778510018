import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AprendaService } from 'src/services/Aprenda.service';



@Component({
  selector: 'app-aprenda',
  templateUrl: './aprenda.component.html',
  styleUrls: ['./aprenda.component.css']
})


export class AprendaComponent {
  conteudos_aprenda: any[] = [];
  isLoading: boolean = false; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao

  constructor(private router: Router, private serviceAPrenda: AprendaService) {
  }

  ngOnInit(): void {
 
    this.CarregaConteudoAprenda();
  }
  CarregaConteudoAprenda(){
    this.isLoading = true;

    this.serviceAPrenda.getAprendaConteudo().subscribe(
      (data: any) => {
      //  this.cidadesMockadas = data;
       this.isLoading = false;
      this.conteudos_aprenda=data;
      console.log(data)

      },
      (error) => {
       this.isLoading = false;
        console.error('Erro ao buscar cidades e temas:', error);
      }
    )
  }
  irParaTelamodulosIndex() {
    this.router.navigate(['/modulos/index']);
  }

  irParaTelaModelAprenda(id: number) {
    this.router.navigate(['/modulos/aprenda/modal', id]);
  }
}
