<div class="backgroud">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  
      <div class="container-title background-color">
        <h1 class="title">ALTERAR SENHA</h1>
        <img (click)="irParaTelaHome()" class="seta" src="./assets/seta.png" />
      </div>
  
    
      <div class="containerName" [class.error-input]="showEmailError">
        <input class="input" type="text" formControlName="email" placeholder="E-MAIL" (input)="onInput('email')" />
        <span *ngIf="showEmailError" class="error-message alert alert-danger" role="alert">{{ emailErrorMessage
          }}</span>
      </div>
     
  
      <br />
      <div class="root-btn">
        <div class="tela-7-inner">
          <div class="boto-2-parent">
            <button type="submit" class=" custom-button">
              <div class="boto-2"></div>
              <div class="quero-me-cadastrar-wrapper">
                <div class="text-input">RECEBER EMAIL</div>
              </div>
            </button>
          </div>
        </div>
      </div>
  
      <app-carregamento-padrao *ngIf="isLoading"></app-carregamento-padrao>
      <div class="root-voltar">
        <h5 class="text-voltar" (click)="irParaTelaHome()">
          < VOLTAR </h5>
      </div>
    </form>
  </div>