import { Injectable } from '@angular/core';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class Tokenfcm {
  token: string = '';
  message: any = null;
  private apiUrl: string;
  private sessionStorageKey = 'firebaseToken'; // Chave para armazenar o token na sessão


  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }
  requestPermission(): Promise<void> {
    const messaging = getMessaging();

    const storedToken = sessionStorage.getItem(this.sessionStorageKey);


    return getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.token = currentToken;

          if (!storedToken || currentToken !== storedToken) {
            this.sendTokenToBackend(currentToken);
          }

        } else {
          console.log('Nenhum token de registro disponível. Solicite permissão para gerar um.');
        }
      })
      .catch((err) => {
        console.error('Ocorreu um erro ao recuperar o token:', err);
      });

  }


  private sendTokenToBackend(token: string) {

    const userId = sessionStorage.getItem('userID'); // Obter user_id da sessionStorage

    const data = { token, id_usuario: userId };
    const tokenjwt = sessionStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${tokenjwt}`);

    this.http.post(this.apiUrl + '/update-token', data,{headers}).subscribe(
      (response) => {
        console.log( response)
        sessionStorage.setItem(this.sessionStorageKey, token);
      },
      (error) => {
        console.error('Erro ao enviar o token para o backend: ', error.error.message);
      }
    );
  }
}
