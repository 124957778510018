import { Component ,Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { CadastroService } from 'src/services/Cadastrar-User.service.';
import { DataService } from 'src/services/dataService';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { opcoesGenero } from '../../../services/genero';

// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatInputModule } from '@angular/material/input';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatNativeDateModule } from '@angular/material/core';


@Component({
  selector: 'app-cadastrar-usuario',
  templateUrl: './cadastrar-usuario.component.html',
  styleUrls: ['./cadastrar-usuario.component.css']
})
export class CadastrarUsuarioComponent {
  opcoesGenero = opcoesGenero;
  @Output() emailEnviado = new EventEmitter<string>();
  // Defina a faixa de anos desejada para a seleção de anos
  yearRange = [-50, 50];

  // Defina a data mínima permitida (por exemplo, 100 anos atrás da data atual)
  minDate: NgbDateStruct;
  termosAceitos: boolean = false; // Inicialmente, a caixa de seleção não está marcada



  isLoading: boolean = false; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao
  public loginForm: FormGroup;
  public submitted = false;

  showNomeError: boolean = false;
  showEmailError: boolean = false;
  showPasswordError: boolean = false;
  showConfirmPassword: boolean = false;
  showIdadeError: boolean = false;

  IdadeErrorMessage :  string = '';
  nomeErrorMessage: string = '';
  emailExistsErrorMessage: string = '';
  passwordErrorMessage: string = '';
  emailErrorMessage: string = '';
  confirmPasswordErrorMessage: string = '';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private cadastroService: CadastroService,
    private dataService: DataService
  ) {

    const currentDate = new Date();

    // Calcule a data mínima
    this.minDate = { year: currentDate.getFullYear() - 100, month: 1, day: 1 };

    this.loginForm = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      genero: ['', Validators.required],
      celular: ['', Validators.required],
      idade: ['', Validators.required],

    });
  }


  irParaTelaHome() {
    this.router.navigate(['/login']);
  }


  resetErrors() {
    this.showNomeError = false;
    this.showEmailError = false;
    this.showPasswordError = false;
    this.showConfirmPassword = false;
    this.showIdadeError=false;
  }
  
  onInput(field: string) {
    this.loginForm.get(field)?.setErrors(null);
  }
  
  onSubmit() {

    this.resetErrors();

    const nome = this.loginForm.get('nome')?.value;
    const email = this.loginForm.get('email')?.value;
    this.dataService.setEmail(email);// enviar para o outro componente
    const password = this.loginForm.get('password')?.value;
    const celular = this.loginForm.get('celular')?.value;
    const idade = this.transformDateToString(this.loginForm.get('idade')?.value);
    const genero = this.loginForm.get('genero')?.value;
    const confirmPassword = this.loginForm.get('confirmPassword')?.value;

    const liberarPoridade = this.verificarIdade(idade);
    const estaMarcada = this.verificarCheckbox();
    if (estaMarcada) {
      console.log('A caixa de seleção está marcada.');
    } else {
      alert('É obrigatório aceitar o termos de uso.')
    }
    

    if (!nome || nome.trim() === '') {
      this.showNomeError = true;
      this.nomeErrorMessage = 'Nome inválido ou em branco';
      return;
    }

    if (!email || email.trim() === '' || !this.validateEmail(email)) {
      this.showEmailError = true;
      this.emailErrorMessage = 'E-mail inválido ou em branco';
      return;
    }

    if (!celular || celular.trim() === '' || !idade || idade.trim() === ''|| !genero || genero.trim() === '' ) {
      this.showConfirmPassword = true; // Aparece lá em baixo 
      this.confirmPasswordErrorMessage = 'Todos os campos são obrigatórios';
      return;
    }

    if (liberarPoridade) {
      this.showIdadeError = true;
      this.IdadeErrorMessage = 'Idade menor que 16 anos';
      return;
    }

    if (!password || password.trim() === '') {
      this.showPasswordError = true;
      this.passwordErrorMessage = 'Senha inválida ou em branco.';
      return;
    }

    if (password.length < 8) {
      this.showPasswordError = true;
      this.passwordErrorMessage = 'Obrigatório ter pelo menos 8 caracteres.';
      return;
    }

    if (password !== confirmPassword) {
      this.showConfirmPassword = true;
      this.confirmPasswordErrorMessage = 'Senhas diferentes.';
      return;
    }
    this.isLoading = true;

    this.cadastroService.cadastrarUsuario(nome, email, password,celular,idade,genero)
      .subscribe(
        response => {
          this.isLoading = false;
          // Swal.fire({
          //   title: 'Parabéns!',
          //   text: 'Cadastro realizado com sucesso! Verifique seu email e confirme sua conta.',
          //   icon: 'success'
          // });
          
          this.router.navigate(['/emailcheck']);

        },
        error => {
          this.isLoading = false;
          console.error('Erro ao cadastrar usuário:', error.error.errors.email[0]);
          if (error.error.errors.email[0] == 'The email has already been taken.') {
            this.showEmailError = true;
            this.emailErrorMessage = 'Já existe uma conta cadastrada nesse e-mail.';
          }
          else{
            this.showEmailError = true;
            this.emailErrorMessage = 'E-mail invalido.';
          } 
        }
      );
  }

  validateEmail(email: string): boolean {
    // Implemente a lógica de validação do e-mail aqui
    // Pode usar expressões regulares ou outras técnicas de validação
    // Neste exemplo, verifica apenas se contém um '@' no e-mail
    return email.includes('@');
  }

  transformDateToString(dateInfo: any): string {
    const day = dateInfo.day < 10 ? `0${dateInfo.day}` : dateInfo.day;
    const month = dateInfo.month < 10 ? `0${dateInfo.month}` : dateInfo.month;
  
    return `${day}-${month}-${dateInfo.year}`;
  }

  formatDateControlValue(dateInfo: any): string {
    if (dateInfo) {
      const day = dateInfo.day < 10 ? `0${dateInfo.day}` : dateInfo.day;
      const month = dateInfo.month < 10 ? `0${dateInfo.month}` : dateInfo.month;
      return `${day}/${month}/${dateInfo.year}`;
    }
    return '';
  }

  onNavigate(event: { year: number; month: number }): void {
    const currentDate = this.loginForm.get('idade').value;
    const newDate = {
      year: event.year,
      month: event.month,
      day: currentDate ? currentDate.day : 1 // Use um valor padrão para o dia caso ainda não haja data selecionada
    };
    this.loginForm.get('idade').setValue(newDate);
  }
  
  transformStringToDate(dateString: string): NgbDateStruct {
    const parts = dateString.split('/');
    if (parts.length === 3) {
      return {
        year: +parts[2],
        month: +parts[1],
        day: +parts[0]
      };
    }
    return null;
  }
  verificarIdade(dataNascimento: string) :boolean {
    // Divide a data em dia, mês e ano
    const partes = dataNascimento.split('-');
    const dia = parseInt(partes[0], 10);
    const mes = parseInt(partes[1], 10);
    const ano = parseInt(partes[2], 10);

    // Cria um objeto de data com a data de nascimento
    const dataNasc = new Date(ano, mes - 1, dia);

    // Cria um objeto de data com a data atual
    const dataAtual = new Date();

    // Calcula a diferença em milissegundos
    const diferencaEmMilissegundos = dataAtual.getTime() - dataNasc.getTime();

    // Converte a diferença em anos
    const diferencaEmAnos = Math.floor(diferencaEmMilissegundos / (365.25 * 24 * 60 * 60 * 1000));

    // Verifica se a pessoa tem menos de 14 anos
    return diferencaEmAnos < 16;

  }

 
  verificarCheckbox(): boolean {
    const checkbox = document.getElementById('termos-checkbox') as HTMLInputElement;
  
    return checkbox.checked;
  }
  

  
}
