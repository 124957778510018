import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PwaInstallService } from 'src/services/pwa-install.service';
import L from 'leaflet';

@Component({
  selector: 'app-home-ce',
  templateUrl: './home-ce.component.html',
  styleUrls: ['./home-ce.component.css']
})
export class HomeCeComponent {
  estouLogado: boolean
  constructor(private router: Router,
    private elementRef: ElementRef,
    private pwaInstallService: PwaInstallService
  ) { }

  baixarApp: boolean;
  mapa: any;

  ngOnInit(): void {
    const token = sessionStorage.getItem('token')  

    if(token){
      console.log('estou logado')
      this.estouLogado = true
    }else{
      console.log('não estou logado')
      this.estouLogado = false
    }

    this.mapa = L.map('mapa', {
      center: [-4.04185, -38.6378], // Coordenadas do Ceará
      zoom: 10,
      maxBounds: L.latLngBounds([-5.0, -41.0], [-2.0, -37.0]) // Limites para o Ceará
    });

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }).addTo(this.mapa);
    

    // this.mapa.scrollWheelZoom.disable();

    // this.mapa.on('zoomend', () => {
    //   if (this.mapa.getZoom() < 16) {
    //     this.mapa.setZoom(16); 
    //   }
    // });
    // Adiciona um marcador no CEP 61800-000 com um pop-up
    const marcador = L.marker([-4.07444722222, -38.6966722222]).addTo(this.mapa);
    marcador.bindPopup('Guaiúba (CE) <br>Propriedade particular <br>Tecnologias instaladas: <br>1. restauração ecológica <br>2. manejo sustentável do solo').openPopup();
    const marcador2 = L.marker([-4.04194444444,  -38.645475]).addTo(this.mapa);
    marcador2.bindPopup('Guaiúba (CE) <br>Propriedade particular <br>Tecnologias instaladas: <br>1. restauração ecológica <br>2. manejo sustentável do solo').openPopup();
    const marcador3 = L.marker([-4.11136388889, -38.6950555556]).addTo(this.mapa);
    marcador3.bindPopup('Guaiúba (CE) <br>Assentamento Pedra d`água <br>Tecnologias instaladas: <br>1. Manejo sustentável do solo <br>2. sistema agroflorestal').openPopup();
    const marcador4 = L.marker([-4.07297222222, -38.6737222222]).addTo(this.mapa);
    marcador4.bindPopup('Guaiúba (CE) <br>Propriedade particular <br>Tecnologias instaladas: <br> Manejo sustentável do solo').openPopup();
    const marcador5 = L.marker([-4.07658888889, -38.6967583333]).addTo(this.mapa);
    marcador5.bindPopup('Guaiúba (CE) <br>Propriedade particular <br>Tecnologias instaladas: <br> Manejo sustentável do solo').openPopup();
    const marcador6 = L.marker([-4.04460555556, -38.6255805556]).addTo(this.mapa);
    marcador6.bindPopup('Guaiúba (CE) <br>EEEP José Ivanilton Nocrato <br>Tecnologias instaladas: <br>1. Manejo sustentável do solo <br>2. sistema agroflorestal').openPopup();
    const marcador7 = L.marker([-4.03727777778, -38.6320055556]).addTo(this.mapa);
    marcador7.bindPopup('Guaiúba (CE) <br>EEMTi José Tristão Filho <br>Tecnologias instaladas: <br>1. Manejo sustentável do solo <br>2. sistema agroflorestal').openPopup();
    const marcador8 = L.marker([-4.02063888889, -38.7831111111]).addTo(this.mapa);
    marcador8.bindPopup('Maranguape (CE) <br>Comunidades de Riacho Verde, Caboré e Mocozal <br>Tecnologias instaladas: <br>Saneamento básico').openPopup();
    const marcador9 = L.marker([-4.02980555556,  -38.8079166667]).addTo(this.mapa);
    marcador9.bindPopup('Maranguape (CE) <br>Comunidades de Forquilha e Massapê <br>Tecnologias instaladas: <br>Saneamento básico').openPopup();
    

  }
 

  installPWA() {
    if (!this.pwaInstallService.isPWAInstalled()) {
      alert("Deseja baixar aplicativo para ter uma experiencia melhor ?")
      this.pwaInstallService.installPWA();
    }
    else {
      alert("Aplicativo ja instalado")
    }
  }

  irParaTelaLogin() {
    this.router.navigate(['/login']);
  }

  getCookie(name: string): string | null {
    const cookies: string[] = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie: string = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  irParaCadastro() {
    this.router.navigate(['/cadastro_usuario']);
  }

  scrollToDiv(divId: string) {
    const targetDiv = document.getElementById(divId);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
