import { Component, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { DataService } from 'src/services/dataService';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-novidade',
  templateUrl: './novidade.component.html',
  styleUrls: ['./novidade.component.css']
})
export class NovidadeComponent {

  showModal: boolean = false;
  selectedButton: number | null = null;
  clickCount: number = 0;
  elementoRefModalNovidadesSubscription: Subscription | undefined;
  isWebShareSupported: boolean;


  constructor(
    private router: Router
  ) {
    // this.isWebShareSupported = 'share' in navigator;
  }

  ngOnInit() {
    // Verifica se a API navigator.share está disponível
    if (navigator.share) {
      this.isWebShareSupported = true;
    } else {
    }
  }

  // openModal(button: number): void {
  //   console.log('abrindo modal');
  //   this.showModal = true;
  //   this.selectedButton = button;
  //   this.clickCount = 0;

  //   // Adicionar evento de clique fora do modal
  //   this.ngZone.runOutsideAngular(() => {
  //     document.addEventListener('click', this.onDocumentClick);
  //   });
  // }

  compartilharLink(url,titulo,text) {
    if (navigator.share) {
      navigator.share({
        title: titulo,
        text: text,
        url:  'https://www.recarrega.app.br/model/novidades/'+url,
      })
        .then(() => console.log('Conteúdo compartilhado com sucesso!'))
        .catch((error) => console.error('Erro ao compartilhar conteúdo:', error));
    }
  }
  openComponenteModal(button: number): void {
    this.selectedButton = button;
    // Navegar para o caminho desejado com o valor de button como parâmetro
    setTimeout(() => {
      this.router.navigate(['/model/novidades', button]);
    }, 100);
  }


}
