import { Component, Input, Output, EventEmitter } from '@angular/core';
import {  Router } from '@angular/router';
import { DataService } from 'src/services/dataService';
import { Subscription,forkJoin  } from 'rxjs';
import { NotificationService } from 'src/services/notification.service';

interface Atividade {
  id_Atividade: number;
  id_tema: number;
  descricao: string;
  horario: string;
}

@Component({
  selector: 'app-modal-notificaton',
  templateUrl: './modal-notificaton.component.html',
  styleUrls: ['./modal-notificaton.component.css']
})
export class ModalNotificationComponent {
 
  @Input() showModal: boolean = true;
  @Input() DadosNotification:any[];

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private notificationService: NotificationService,
    private router:Router) {}

  fecharmodal() {
    // Criar um array de observables para armazenar as chamadas de atualização
    const observables = this.DadosNotification.map((notification) =>
      this.notificationService.updateNotification(notification.id, { lida: 1 })
    );

    if (window.confirm('Deseja marcar como lida as notificações?')) {
      forkJoin(observables).subscribe(
        (responses) => {
          console.log('Notificações atualizadas:', responses);
          this.closeModal.emit();
          location.reload();

        },
        (error) => {
          console.error('Erro ao atualizar notificações:', error);
        }
      );
    } else {
      this.closeModal.emit();
      //Nao faz nada
      // console.log('O usuário clicou em "Cancelar" ou fechou o diálogo');
    }
   
  }

  irParaComponente(Componente:String){

    Componente='modulos/aprenda'

    this.router.navigate([`/${Componente}`])
  }

  getDadosNotification(){
    this.DadosNotification = this.DadosNotification
    return this.DadosNotification;
  }

}





