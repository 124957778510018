<div class="boto">

    <div class="boto-top">
    <h1>Cairé</h1>
    <div class="texto-top" >Em parceria com a cooperativa de costureiras formada através das oficinas promovidas pelo Recarrega em São Paulo, a Cairé atua na produção e venda de absorventes reutilizáveis. </div>
    <h1>A marca</h1>
    </div>
    <div class="display-caire" >
        <div class="boto-caire">
            <img class="caire" src="../../assets/LogotipoCaire.svg" alt="">

        </div>

        <div class="container-texto">
        <div class="texto">
            <div>- <strong>Estimula</strong>  a autonomia financeira das mulheres em situação de vulnerabilidade</div>
            <div>-  <strong>Promove</strong>  o desenvolvimento de um pensamento crítico sobre questões de saúde das pessoas que menstruam</div>
            <div>- <strong> Repensa </strong>  o impacto ambiental dos absorventes descartáveis</div>

     
        </div>

        
        <div class="saiba-mais">
            <p class="titulo-3">PARA SABER MAIS:</p>

            <div class="saiba-mais-icon">
            <div class="icon">
              <img class="insta" src="../../assets/instagram.svg" alt="">
  
            </div>
            <div class="icon">
              <img class="internet" src="../../assets/internet.svg" alt="">
            </div>
        </div>
          </div>
    </div>
       
    </div>
</div>