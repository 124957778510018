<app-carregamento-padrao *ngIf="!isLoading"></app-carregamento-padrao>
<div class="modal-content" *ngIf="isLoading">
  <div>
    <img (click)="irParaHomeENovidades()" class="icon-fechardesk" src="../../assets/fechar.svg">
  </div>
  <div class="carousel-inner">
    <ngb-carousel class="ngb-carousel" #carousel [interval]="60000"> <!-- 5000 milissegundos (5 segundos) -->
      <ng-template ngbSlide *ngFor="let imagem of imagensComLegendasPorOpcao[selectedButton]; let i = index">
        <div>
          <img (click)="irParaHomeENovidades()" class="icon-fechar" src="../../assets/fechar.svg">
        </div>
        <img class="img-carousel" [src]="imagem.src" alt="Imagem">
        <div class="carousel-caption ">
          <!-- <p>{{ page.legend }}</p> -->
        </div>
      </ng-template>
    </ngb-carousel>
  </div>