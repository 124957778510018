import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/services/dataService';
import { Subscription, forkJoin } from 'rxjs';
import { NotificationService } from 'src/services/notification.service';

@Component({
  selector: 'app-modal-mural',
  templateUrl: './modal-mural.component.html',
  styleUrls: ['./modal-mural.component.css']
})
export class ModalMuralComponent {


  @Input() showModalMural: boolean = false;
  @Input() DadosMural: any[];
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private dataservice: DataService,) { }

  fecharmodal() {
     this.closeModal.emit()
    this.dataservice.Resetclick()
    this.showModalMural = false
  }

  getStyle(notificationtipo: string) {
    switch (notificationtipo) {
      case 'Atualizações app':
        return { background: '#ED7202' };
      case 'Agende':
        return { background: '#9DA34A' };
      case 'Novo conteúdo':
        return { background: '#532014' };
      case 'Notícia':
        return { background: '#003636' };
      default:
        return {}; // Se não corresponder a nenhum tipo, retorna estilos vazios
    }
  }


  getDadosMural() {
    return this.DadosMural
  }


}



