<div class="menu">
    <div class="itens-menu">
     <div id="menu-mural" [ngClass]="{'selecionado': urlAtual === '/modulos/novidades' || urlAtual==='/modulos/index'}" (click)="irParaMural()" class="btn-menu">INÍCIO</div>
     <div id="menu-chat" [ngClass]="{'selecionado': urlAtual === 'menu-chat'}" (click)="irParaChat('grupo')" class="btn-menu">COLABORE</div>
     <div id="menu-aprenda" [ngClass]="{'selecionado': urlAtual === '/modulos/aprenda'}" (click)="irParaaprenda()" class="btn-menu">APRENDA</div>
     <div id="menu-suporte" [ngClass]="{'selecionado': urlAtual === '/modulos/chat/Suporte'}" (click)="irParaChat('grupo_suporte')" class="btn-menu">SUPORTE</div>
     <div [ngClass]="{'selecionado': urlAtual === '/modulos/monitore'}" (click)="irParaMonitore()" class="btn-menu">MONITORE</div>
     <div [ngClass]="{'selecionado': urlAtual === '/modulos/mapeie'}" (click)="irParaMapeia()" class="btn-menu">MAPEIE</div>
     <div [ngClass]="{'selecionado': urlAtual === '/modulos/rota_home'}" (click)="irParaORecarrega()" class="btn-menu">O RECARREGA</div>
     <div [ngClass]="{'selecionado': urlAtual === '/modulos/usuario'}" (click)="irParausuarios()" class="btn-menu">MEU PERFIL</div>
     <div [ngClass]="{'selecionado': urlAtual === '/login'}" (click)="logout()" class="btn-menu">SAIR</div>
    </div>
    <!--<div ><img class="img-menu" src="" alt=""></div> -->
  </div>