import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';



@Component({
  selector: 'app-modal-recarregar-hidricas',
  templateUrl: './modal-recarregar-hidricas.component.html',
  styleUrls: ['./modal-recarregar-hidricas.component.css']
})
export class ModalRecarregarHidricasComponent {
  @Input() showModal: boolean = false;
  @Input() selectedButton: number | null = null;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

    // Variáveis para armazenar os títulos e conteúdos correspondentes a cada número
    titulosPorNumero: { [key: number]: string } = {
      1: 'Uso de água da chuva',
      2: 'Saneamento básico',
      3: 'Infiltração de água no solo',
      4: 'Redução de sedimentos'
    };
  
    conteudoPorNumero: { [key: number]: string } = {
      1: 'A recarga hídrica está diretamente relacionada com a segurança hídrica e, por isso, a primeira etapa a ser considerada envolve a captação, o armazenamento e o uso de água da chuva. Com a implantação de cisternas ou de sistemas de aquaponia, por exemplo, a disponibilidade de água na região pode aumentar.',
      2: 'O acesso a saneamento básico também tem relação direta com a segurança e a recarga hídricas. Por meio da coleta e tratamento de esgoto e de líquidos da decomposição do lixo orgânico, é possível evitar a poluição de córregos, rios, lagos e açudes, preservando as fontes de abastecimento de água.',
      3: 'O processo de infiltração é de extrema importância para a conservação da água e do solo e, apesar de acontecer naturalmente, também pode ser impulsionado por tecnologias sociais como os sistemas agroflorestais (SAF), que reduzem a erosão e aumentam a biodiversidade e a umidade do solo.',
      4: 'Sedimentos são fragmentos de solo, rochas e matéria orgânica que podem ser transportados para dentro de córregos, rios e lagos, prejudicando a qualidade da água. Tecnologias sociais como a restauração florestal podem evitar que isso aconteça, pois agem como um filtro da água que escorre sob o solo e ajuda a estabilizar as margens desses locais.'
    };
 

}
