import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class CadastroService {
  private apiUrl: string;
  private token = sessionStorage.getItem('token'); // Obtém o token de autorização da sessionStorage

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  cadastrarUsuario(nome: string, email: string, password: string, celular: string, idade: string, genero: string) {
    const payload = {
      name: nome,
      email: email,
      password: password,
      numero_celular: celular,
      idade: idade,
      genero: genero
    };

    return this.http.post(`${this.apiUrl}/cadastro`, payload);
  }


  atualizarUsuarioWithfoto(id: string, data: FormData): Observable<any> {
    const url = `${this.apiUrl}/users_atualizar/${id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post<any>(url, data, { headers });
  }

  atualizarUsuario(id: string, idade: string, celular: string, genero: string): Observable<any> {
    const payload = {
      idade: idade,
      numero_celular: celular,
      genero: genero
    };

    const url = `${this.apiUrl}/users_atualizar/${id}`; // Substitua "/users" pelo endpoint correto de atualização do usuário
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
    return this.http.post<any>(url, payload, { headers });

  }

}
