import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    private apiUrl: string;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private userInfoSource = new BehaviorSubject<any>(null);
    userInfo$ = this.userInfoSource.asObservable();
  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
}
setUserInfo(userInfo: any) {
  this.userInfoSource.next(userInfo);
}
  verificarCamposUsuario(idusuario: number): Observable<any> {
    const url = `${this.apiUrl}/verificar_users/${idusuario}`;
    return this.http.get<any>(url);
  }

  getUserData(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any>(`${this.apiUrl}/auth/me`, { headers });
  }
}
