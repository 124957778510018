import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PwaInstallService } from 'src/services/pwa-install.service';
import L from 'leaflet';


@Component({
  selector: 'app-home-sp',
  templateUrl: './home-sp.component.html',
  styleUrls: ['./home-sp.component.css']
})
export class HomeSpComponent {
  estouLogado: boolean
  constructor(private router: Router,
    private elementRef: ElementRef,
    private pwaInstallService: PwaInstallService
  ) { }

  baixarApp: boolean;
  mapa: any;
  overlayMaps: any = {}; // Objeto para armazenar as camadas adicionais


  ngOnInit(): void {



    const token = sessionStorage.getItem('token')  

    if(token){
      this.estouLogado = true
    }else{
      this.estouLogado = false
    }

    console.log(this.estouLogado)
    
    this.mapa = L.map('mapa', {
      center: [-23.5505, -46.6333], // Coordenadas de São Paulo
      zoom: 12,
      maxBounds: L.latLngBounds([-24.0, -47.0], [-23.0, -45.0]) // Limites para São Paulo
    });

    // https://leaflet-extras.github.io/leaflet-providers/preview/ layers diferentes
    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }).addTo(this.mapa);
    

    // this.mapa.scrollWheelZoom.disable();

    // this.mapa.on('zoomend', () => {
    //   if (this.mapa.getZoom() < 16) {
    //     this.mapa.setZoom(16); // Define o nível de zoom mínimo
    //   }
    // });
    // Adiciona um marcador no CEP 05569-120 com um pop-up
   // Cria os marcadores
   const marcador1 = L.marker([-23.5801, -46.7000]).bindPopup('EE JOÃO XXIII - OFICINAS PRÁTICAS');
  //  const marcador2 = L.marker([-23.5600, -46.6200]).bindPopup('Outro pop-up');

   // Adiciona os marcadores às camadas de grupo individuais
   const marcadorLayer1 = L.layerGroup([marcador1]).addTo(this.mapa);
  //  const marcadorLayer2 = L.layerGroup([marcador2]).addTo(this.mapa);

   // Adiciona as camadas de grupo ao objeto overlayMaps individualmente
   this.overlayMaps["Marcador 1"] = marcadorLayer1;
  //  this.overlayMaps["Marcador 2"] = marcadorLayer2;

   // Configura o controle de camadas
   L.control.layers(null, this.overlayMaps).addTo(this.mapa)



 
}

  installPWA() {
    if (!this.pwaInstallService.isPWAInstalled()) {
      alert("Deseja baixar aplicativo para ter uma experiencia melhor ?")
      this.pwaInstallService.installPWA();
    }
    else {
      alert("Aplicativo ja instalado")
    }
  }

  irParaTelaLogin() {
    this.router.navigate(['/login']);
  }

  getCookie(name: string): string | null {
    const cookies: string[] = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie: string = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  irParaCadastro() {
    this.router.navigate(['/cadastro_usuario']);
  }

  IrParaHortas(){
    this.router.navigate(['/modulos/hortas'])
  }

  scrollToDiv(divId: string) {
    const targetDiv = document.getElementById(divId);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
