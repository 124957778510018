<app-carregamento-padrao *ngIf="isLoading"></app-carregamento-padrao>

<body *ngIf="!isLoading">
    <app-menu></app-menu>
    <div class="background2">
        <div class="header-text">Aprenda</div>
    </div>
    <div class="content-box">
        <div class="content-background1" [style.background-color]="corbackground">

             <div class="conteudo-titulo">
                <div class="content-text">{{conteudoTitulo}}</div>
                <img (click)="irParaTelaAprenda()" src="../../assets/fechar.svg" >
            </div>

            <div class="conteudo-interno" >
            <div class="conteudo-atividade ">

                <div class="content-image ">
                    <img class="img-aprenda" [src]='imgConteudo'
                        alt="Imagem" />
                </div>

            
                <div class="visivelMobile">
                <div class="text-aprenda">
                    <p class="">{{ conteudoDescricao }}</p>
                </div>

                <div class="text-aprenda rubik">
                    <p class="">{{ conteudosubModulo }}</p>

                </div>
                <div class="container-inicar-modulo" (click)="irParaTelaModelStorysAprenda()">
                    <p class="btn-inicar-modulo">INICIAR MÓDULO</p>
                </div>


                <!-- essa div eh para da espaco para o menu ficar em baixo... -->
                <div>
                    <p class="">&nbsp;</p>
                    <p class="">&nbsp;</p>
                </div>
            </div>

            </div>


            <div class="container-btn visivelMobile">
                <div class="container-row ">
                    <div *ngFor="let subconteudo of sub_conteudos_aprenda; ">
                        <div class="icon2" *ngIf="subconteudo.valor_botao % 2 == 1">
                            <div class="circle" [ngClass]="{'selecionado': subconteudo.selecionado}"
                                (click)="atualizarConteudo(subconteudo)">
                                <div>{{ subconteudo.valor_botao }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-row-second">
                    <div *ngFor="let subconteudo of sub_conteudos_aprenda; ">
                        <div class="icon2" *ngIf="subconteudo.valor_botao % 2 == 0">
                            <div class="circle"
                            [ngClass]=" {'selecionado': subconteudo.selecionado}" (click)="
                                atualizarConteudo(subconteudo)">
                                <div>{{ subconteudo.valor_botao }}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div class="container-btn visivelPc">
                <div class="container-row ">
                    <div *ngFor="let subconteudo of sub_conteudos_aprenda; ">
                        <div class="icon2" >
                            <div class="circle" [ngClass]="{'selecionado': subconteudo.selecionado}"
                                (click)="atualizarConteudo(subconteudo)">
                                <div>{{ subconteudo.valor_botao }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="visivelPc">
                    <div class="text-aprenda">
                        <p class="">{{ conteudoDescricao }}</p>
                    </div>
    
                    <div class="text-aprenda rubik">
                        <p class="">{{ conteudosubModulo }}</p>
    
                    </div>
                    <div class="container-inicar-modulo" (click)="irParaTelaModelStorysAprenda()">
                        <p class="btn-inicar-modulo">INICIAR MÓDULO</p>
                    </div>
                </div>
    
                </div>
        </div>
    </div>
    </div>



</body>