<div class="filtro-scroll">

    <div class="titulo">Em São Paulo.
 
      <p class="sub-titulo">O Recarrega 
        atua principalmente junto a:</p>
  
    </div>
  
    <div class="container-quadros">
      <div class="quadrado_branco">
        <div class="texte-item"> Comunidades em situação de vulnerabilidade social</div>
      </div>
      <div class="quadrado_branco">
        <div class="texte-item">Mulheres</div>
      </div>
      <div class="quadrado_branco">
        <div class="texte-item">Jovens</div>
      </div>
      <div class="quadrado_branco">
        <div class="texte-item">Agricultores familiares</div>
      </div>
      <div class="quadrado_branco">
        <div class="texte-item">Instituições de ensino</div>
      </div>
      <div class="quadrado_branco">
        <div class="texte-item">Espaços comunitários</div>
      </div>
  
    </div>
  
  </div>