import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patrocinadores-sp',
  templateUrl: './patrocinadores-sp.component.html',
  styleUrls: ['./patrocinadores-sp.component.css']
})
export class PatrocinadoresSpComponent {

  constructor(private router: Router
  ) { }


  irParaCadastro() {
    this.router.navigate(['/cadastro_usuario']);
  }

}
