<!-- <div class="conteneir-recarrega-titulo">
    Você e sua comunidade também podem fazer parte desse movimento!
    <p class="sub-titulo">Cadastra-se no App para participar e colaborar com as atividades do projeto.</p>
    <div class="root-btn">
        <div class="tela-7-inner">
          <div class="boto-2-parent">
            <button type="submit" class=" custom-button">
              <div class="boto-2"></div>
              <div (click)="irParaCadastro()" class="quero-me-cadastrar-wrapper">
                <div class="text-input">QUERO ME CADASTRAR</div>
              </div>
            </button>
          </div>
        </div>
      </div>
</div>

<div class="carouse-full3 rotate-180">
    <div class="slide">
        <img src="./assets/logo_agir_ambiental.png" alt="Slide 1">
    </div>
    <div class="slide">
        <img src="./assets/logo_avina_novo.png" alt="Slide 2">
    </div>

</div>
<div class="fundo-realicacoes">
    <div class="carousel-container">
        <b class="realizao">Realização</b>

    </div>
</div>
<div class="carouse-full1 rotate-180">
   
    <div class="slide">
        <img src="./assets/logo_hilton_novo.png" alt="Slide 2">
    </div>
    <div class="slide">
        <img src="./assets/logo_hilton_global_novo.png" alt="Slide 3">
    </div>
</div>
<div class="fundo-parceiros">
    <div class="carousel-container">
        <b class="parceiros">Patrocinador</b>

    </div>
</div> -

<div class="carouse-full2 rotate-180">
    <div class="slide">
        <img src="./assets/logo_global_novo.png" alt="Slide 3">
    </div>

    <div class="slide">
        <img src="./assets/logo_jardim_novo.png" alt="Slide 4">
    </div>
    <div class="slide">
        <img src="./assets/logo_ceu_novo.png" alt="Slide 2">
    </div>
    <div class="slide">
        <img src="./assets/logo_clã.png" alt="Slide 4">
    </div>
</div>

<div class="fundo-patrocinadores">
    <div class="carousel-container">
        <b class="patrocinadores">Parceiro</b>

    </div>
</div> -->


<div class="botos">
    <div class="boto-increverse" style="background-color: #003636">
        <div class="interno-boto">
            <div class="texto">
                <div class="titulo">Você e sua comunidade também podem fazer parte desse movimento! </div>
                <div class="sub-titulo">Cadastre-se na plataforma para acompanhar as atividades do projeto.</div>
            </div>
            <div class="botao-cadastro">
                <div class="container-inicar-modulo">
                    <p class="btn-inicar-modulo">CONHEÇA AS HORTAS DO PROJETO</p>
                </div>
            </div>
        </div>
    </div>

    <div class="boto" style="background-color: #9DA34A">

        <div class="interno-logo">
            <div  class="titulo aliamento-titulo">Realização</div>
            <div class="logos">
                <div class="slide">
                    <img src="./assets/logo_agir_ambiental.png" alt="Slide 1">
                </div>
                <div class="slide">
                    <img src="./assets/logo_avina_novo.png" alt="Slide 2">
                </div>
            </div>

        </div>


    </div>

    <div class="boto" style="background-color: #ED7202">
        <div class="interno-logo ">
            <div  class="titulo  aliamento-titulo">Patrocinadores</div>
            <div class="logos">
           
    <div class="slide">
        <img src="./assets/logo_hilton_novo.png" alt="Slide 2">
    </div>
    <div class="slide">
        <img src="./assets/logo_hilton_global_novo.png" alt="Slide 3">
    </div>
            </div>
        </div>
    </div>
    <div class="boto" style="background-color: #00C8C6">
        <div class="interno-logo">
            <div  class="titulo  aliamento-titulo">Parceiros</div>
            <div class="logos">
                <div class="slide">
                    <img src="./assets/logo_global_novo.png" alt="Slide 3">
                </div>
            
                <div class="slide">
                    <img src="./assets/logo_jardim_novo.png" alt="Slide 4">
                </div>
                <div class="slide">
                    <img src="./assets/logo_ceu_novo.png" alt="Slide 2">
                </div>
                <div class="slide">
                    <img src="./assets/logo_clã.png" alt="Slide 4">
                </div>
            </div>

        </div>
    </div>


</div>