<div class="backgroud">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  
      <div class="container-title background-color">
        <h1 class="title">ALTERAR SENHA</h1>
        <img (click)="irParaTelaHome()" class="seta" src="./assets/seta.png" />
      </div>
  
      <div class="containerName" [class.error-input]="showPasswordError">
        <input class="input" type="password" formControlName="password" placeholder="SENHA" />
        <span *ngIf="showPasswordError" class="error-message alert alert-danger root-termos-erro" role="alert">{{
          passwordErrorMessage
          }}</span>
      </div>
  
      <div class="containerName" [class.error-input]="showConfirmPassword">
        <input class="input" type="password" formControlName="confirmPassword" placeholder="CONFIRMAR SENHA" />
        <span *ngIf="showConfirmPassword" class="error-message alert alert-danger" role="alert">{{
          confirmPasswordErrorMessage }}</span>
      </div>
  
      <!-- <div class="root-btn">
          <div class="buttomLogin">
            <button type="submit" class="text-btn custom-button">
              <img draggable="false" class="logo" src="../../../assets/logo2.png" alt="logo" />
              CADASTRAR
            </button>
          </div>
        </div> -->
      <br />
  
  
  
      <br />
      <div class="root-btn">
        <div class="tela-7-inner">
          <div class="boto-2-parent">
            <button type="submit" class=" custom-button">
              <div class="boto-2"></div>
              <div class="quero-me-cadastrar-wrapper">
                <div class="text-input">ATUALIZAR SENHA</div>
              </div>
            </button>
          </div>
        </div>
      </div>
  
      <app-carregamento-padrao *ngIf="isLoading"></app-carregamento-padrao>
      <div class="root-voltar">
        <h5 class="text-voltar" (click)="irParaTelaHome()">
          < VOLTAR </h5>
      </div>
    </form>
  </div>