import { Component, ElementRef, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/services/user.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ForumService } from 'src/services/Forum_Service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {
  userInfo: any; // Armazenar� as informa��es do usu�rio

  private apiUrl: string;

   AlertColabore: boolean;
   AlertSuporte: boolean;


  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private userService: UserService,
    private http: HttpClient,
    private forumService: ForumService


  ) {
    this.apiUrl = environment.apiUrl;
  }


  ngOnInit() {
    this.CarregaMensagensNaolidas()

  }

  CarregaMensagensNaolidas() {
    const userID = parseInt(sessionStorage.getItem('userID'));
    this.forumService.MessagesUnReadByUserid(userID).subscribe(
      (response) => {

        const arr = Object.values(response);
        if (arr.includes('colabore')) {
          this.AlertColabore=true
        }else{
          this.AlertColabore=false

        }
        // Verifica se o array contém 'grupo_suporte'
        if (arr.includes('grupo_suporte')) {
          this.AlertSuporte=true
        }else{
          this.AlertSuporte=false

        }
      },
      (error) => {
        console.error(error);
      }
    );

  }
  scrollToDiv(divId: string): void {
    const targetElement = this.elementRef.nativeElement.querySelector('#' + divId);
    console.log(targetElement)
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  irParaTelaLogin() {
    this.router.navigate(['/login']);
  }

  irParaChat() {
    this.router.navigate(['/modulos/chat']);
  }

  irParaMural() {
    this.router.navigate(['/modulos/novidades']);
  }

  irParaaprenda() {
    this.router.navigate(['/modulos/aprenda']);
  }

  irParaMonitore() {
    this.router.navigate(['/modulos/monitore']);
  }
  irParaMapeia() {
    this.router.navigate(['/modulos/mapeie']);
  }

  irParausuarios() {
    this.router.navigate(['modulos/usuario']);
  }

  irParaColabore(tipo: string) {
    this.router.navigate(['modulos/chat', tipo]);
  }


  irParaRotaHome() {
    this.router.navigate(['modulos/rota_home']);
  }
  logout(): void {
    const token = sessionStorage.getItem('token');
    //Removendo token do cookie
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    sessionStorage.removeItem('token');
    if (token) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      this.http.post(`${this.apiUrl}/auth/logout`, null, { headers }).subscribe(
        () => {

          this.userInfo = null;
          window.location.reload(); // Atualiza a p�gina

          // Realizar outras a��es ap�s o logout, se necess�rio
        },
        (error: any) => {
          console.error('Erro ao fazer logout:', error);
        }
      );
    }
  }

  showSubMenu = false;

  toggleSubMenu(): void {
    this.showSubMenu = !this.showSubMenu;
  }

  closeSubMenu(): void {
    this.showSubMenu = false;
  }






}
