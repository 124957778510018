<div class="modal-contentold"  *ngIf="newold">
    <div class="carousel-innerold">
      <ngb-carousel class="ngb-carouselold" #carousel [interval]="120000"> <!-- 2 minutos -->
        <ng-template ngbSlide *ngFor="let page of pages; let i = index">
          <div (click)="irParaTelaModelAprenda()" >
            <img  class="icon-fecharold"  src="../../assets/fechar.svg">
          </div>
          <div class="scrollable-contentold">
          <div class="container-textold"  [innerHTML]="page.content"></div>
        </div>
          <div class="carousel-captionold">
            <!-- <p>{{ page.legend }}</p> -->
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
</div>




    
<div class="modal-content" *ngIf="!newold">
  <div>
    <img (click)="irParaTelaModelAprenda()" class="icon-fechardesk" src="../../assets/fechar.svg">
  </div>
  <div class="carousel-inner">
    <ngb-carousel class="ngb-carousel" #carousel [interval]="60000"> <!-- 5000 milissegundos (5 segundos) -->
      <ng-template ngbSlide *ngFor="let imagem of obejetoS3; let i = index">
        <div>
          <img (click)="irParaTelaModelAprenda()" class="icon-fechar" src="../../assets/fechar.svg">
        </div>
        <img class="img-carousel" [src]=" 'https://recarrega.s3.sa-east-1.amazonaws.com/' + imagem" alt="Imagem">
        <div class="carousel-caption ">
          <!-- <p>{{ page.legend }}</p> -->
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>