<div class="backgroud">

    <div class="conteiner" id="inicio">
        <div class="logo"><img class="logo-recarrega-child" alt="" src="./assets/group-1000005244.svg" /></div>

        <div class="recarrega">Recarrega</div>
        <div (click)="irParahomeSp()" class="cidade"> <svg class="bolinhasp-1" xmlns="http://www.w3.org/2000/svg" width="6" height="6"
                viewBox="0 0 6 6" fill="none">
                <circle cx="3" cy="3" r="3" fill="white" />
            </svg>
            <div class="titulo-sp">São Paulo</div>
            <svg class="bolinhasp-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6"
                fill="none">
                <circle cx="3" cy="3" r="3" fill="white" />
            </svg>
        </div>
        <div (click)="irParahomeCe()" class="cidade"> <svg class="bolinhasp-1" xmlns="http://www.w3.org/2000/svg" width="6" height="6"
            viewBox="0 0 6 6" fill="none">
            <circle cx="3" cy="3" r="3" fill="white" />
        </svg>
        <div class="titulo-sp">Ceará</div>
        <svg class="bolinhasp-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6"
            fill="none">
            <circle cx="3" cy="3" r="3" fill="white" />
        </svg>
    </div>
<br>
<br>
<br>
    </div>
</div>
<app-menu-desk></app-menu-desk>
<app-menu></app-menu>