import { Component ,Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { CadastroService } from 'src/services/Cadastrar-User.service.';
import { DataService } from 'src/services/dataService';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AtualizarSenha } from 'src/services/Atualizar-senha.service';
@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['../cadastrar-usuario/cadastrar-usuario.component.css']
})
export class AlterarSenhaComponent {



  isLoading: boolean = false; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao
  public loginForm: FormGroup;
  public submitted = false;

  showPasswordError: boolean = false;
  showConfirmPassword: boolean = false;

  passwordErrorMessage: string = '';
  confirmPasswordErrorMessage: string = '';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private cadastroService: CadastroService,
    private route: ActivatedRoute,
    private atualizarSenha: AtualizarSenha
  ) {


    // Calcule a data mínima

    this.loginForm = this.formBuilder.group({

      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
   

    });
  }


  ngOnInit(): void {

  }

  irParaTelaHome() {
    this.router.navigate(['/login']);
  }


  resetErrors() {
    
    this.showPasswordError = false;
    this.showConfirmPassword = false;
  }
  
  onInput(field: string) {
    this.loginForm.get(field)?.setErrors(null);
  }
  
  onSubmit() {

    this.resetErrors();

    const password = this.loginForm.get('password')?.value;
  
    const confirmPassword = this.loginForm.get('confirmPassword')?.value;


    if (!password || password.trim() === '') {
      this.showPasswordError = true;
      this.passwordErrorMessage = 'Senha inválida ou em branco.';
      return;
    }

    if (password.length < 8) {
      this.showPasswordError = true;
      this.passwordErrorMessage = 'Obrigatório ter pelo menos 8 caracteres.';
      return;
    }

    if (password !== confirmPassword) {
      this.showConfirmPassword = true;
      this.confirmPasswordErrorMessage = 'Senhas diferentes.';
      return;
    }
    this.isLoading = true;

     const id = this.route.snapshot.paramMap.get('id'); 
     const token = this.route.snapshot.paramMap.get('hash'); 
  
     this.atualizarSenha.alterarSenha(id, password, token).subscribe(
       response => {
         // Faça algo com a resposta, se necessário
         alert('Senha alterada com sucesso!');
         this.isLoading = false; // Desligue o indicador de carregamento
         this.router.navigate(['/login']);

        },
       error => {
         console.error('Erro ao atualizar senha:', error);
         alert(error.error.message)
         this.isLoading = false; // Desligue o indicador de carregamento
       }
     );
   
  }

  validateEmail(email: string): boolean {
    // Implemente a lógica de validação do e-mail aqui
    // Pode usar expressões regulares ou outras técnicas de validação
    // Neste exemplo, verifica apenas se contém um '@' no e-mail
    return email.includes('@');
  }


}
