import { Component, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { CadastroService } from 'src/services/Cadastrar-User.service.';
import { DataService } from 'src/services/dataService';
import { AtualizarSenha } from 'src/services/Atualizar-senha.service';


@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['../cadastrar-usuario/cadastrar-usuario.component.css']
})
export class EmailComponent {

  // Defina a faixa de anos desejada para a seleção de anos

  // Defina a data mínima permitida (por exemplo, 100 anos atrás da data atual)


  isLoading: boolean = false; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao
  public loginForm: FormGroup;
  public submitted = false;
  showEmailError: boolean = false;
  emailExistsErrorMessage: string = '';
  emailErrorMessage: string = '';


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private emailService: AtualizarSenha,
    private dataservice: DataService
  ) {

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],

    });
  }


  irParaTelaHome() {
    this.router.navigate(['/login']);
  }


  resetErrors() {
    this.showEmailError = false;
  }

  onInput(field: string) {
    this.loginForm.get(field)?.setErrors(null);
  }

  onSubmit() {

    this.resetErrors();

    const email = this.loginForm.get('email')?.value;
    this.dataservice.setEmail(email);// enviar para o outro componente


    if (!email || email.trim() === '' || !this.validateEmail(email)) {
      this.showEmailError = true;
      this.emailErrorMessage = 'E-mail inválido ou em branco';
      return;
    }


    this.isLoading = true;

    this.emailService.enviarEmailRedefinicaoSenha(email).subscribe(
      response => {
        console.log('E-mail enviado com sucesso:', response);
        this.isLoading = false;
        this.router.navigate(['/emailcheck_esqueciminhasenha']);
        // Faça algo com a resposta, se necessário
      },
      error => {
        console.error('Erro ao enviar e-mail:', error);
        alert(error.error.message)
        this.isLoading = false;

        // Trate o erro de acordo com sua lógica
      }
    );



    // Requisição aqui 

  }

  validateEmail(email: string): boolean {
    // Implemente a lógica de validação do e-mail aqui
    // Pode usar expressões regulares ou outras técnicas de validação
    // Neste exemplo, verifica apenas se contém um '@' no e-mail
    return email.includes('@');
  }

  transformDateToString(dateInfo: any): string {
    const day = dateInfo.day < 10 ? `0${dateInfo.day}` : dateInfo.day;
    const month = dateInfo.month < 10 ? `0${dateInfo.month}` : dateInfo.month;

    return `${day}-${month}-${dateInfo.year}`;
  }

}
