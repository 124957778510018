<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="initial-scale=1, width=device-width" />
</head>

<body>
  <div class="vertical-scroll" (click)="onOutsideClick($event)">
    <div class="rolagem-1" id="inicio">
      <div class="fundo-verde"></div>
      <div class="rolagem-1-child"></div>
      <div class="gota-icon-container">
        <img class="gota-icon" alt="" src="./assets/gota.svg" />
      </div>


      <div class="boto-1"></div>
      <div class="boto-6"></div>
      <b id="Atividades" class="atividades">Atividades</b>
      <b id="novidades" class="novidades">Novidades</b>
      <b id="tecnologias" class="tecnologias-sociais">Tecnologias sociais</b>

      <div class="logo-recarrega">
        <img class="logo-recarrega-child" alt="" src="./assets/group-1000005244.svg" />

        <b class="recarrega">Recarrega</b>
      </div>
      <div class="inscreva-se">
        <img class="gota-2-icon" alt="" src="./assets/gota-2.svg" />
        <b  (click)="irParaTelaLogin()" class="participe-gratuito-container">
          <span class="pessoas-envolvidas-txt-container">
            <p class="participe">Participe,</p>
            <p class="participe">é gratuito</p>
          </span>
        </b>
      </div>
      <div class="boto-baixo">
        <img class="gota-3-icon" alt="" src="./assets/gota-3.svg" id="gota3" />
        <b class="prximas-atividades" (click)="scrollToDiv('Atividades')">próximas atividades</b>
      </div>

      <app-atividades></app-atividades>

      <div class="fundo-azul1 fundo-azul-front">
        <b class="nossos-resultados">Nossos resultados</b>

      </div>
      <div class="fundo-marrom"></div>
      <div class="fundo-azul"></div>
      <div class="recarga-hdrica">
        <div class="fundo-marrom1"></div>
        <div class="fundo-marrom2"></div>
        <div class="fundo-marrom3"></div>
        <b class="recarga-hdrica1" id="inicio_modal">Recarga hídrica</b>
      
        <div class="horta">
          <div class="horta-child"></div>
          <div class="horta-item"></div>
          <div class="horta-inner"></div>
          <div class="rectangle-div"></div>
          <div class="horta-child1"></div>
          <div class="horta-child2"></div>
          <div class="horta-child3"></div>
          <div class="horta-child4"></div>
        </div>
        <div class="casa">
          <div class="casa-child"></div>
          <div class="casa-item"></div>
          <div class="casa-inner"></div>
          <div class="casa-child1"></div>
          <div class="casa-child2"></div>
          <div class="casa-child3"></div>
          <div class="casa-child4"></div>
          <div class="casa-child5"></div>
          <div class="casa-child6"></div>
          <div class="casa-child7"></div>
          <div class="casa-child8"></div>
          <div class="casa-child9"></div>
          <div class="casa-child10"></div>
          <div class="casa-child11"></div>
          <div class="casa-child12"></div>
          <div class="casa-child13"></div>
          <div class="casa-child14"></div>
          <div class="casa-child15"></div>
          <div class="casa-child16"></div>
          <div class="casa-child17"></div>
        </div>
        <div class="ri-02" (click)="openModal(2)" (click)="scrollToDiv('inicio_modal')">
          <div class="ri-021">
            <img class="ri-02-icon" alt="" src="./assets/ri02.svg" />

            <div class="div">2</div>
          </div>
        </div>
        <img class="recarga-hdrica-child" alt="" src="./assets/group-1000005279.svg" />

        <img class="recarga-hdrica-item" alt="" src="./assets/group-1000005278.svg" />

        <img class="recarga-hdrica-inner" alt="" src="./assets/group-1000005280.svg" />

        <img class="group-icon" alt="" src="./assets/group-1000005281.svg" />

        <div class="ri-03" (click)="openModal(3)" (click)="scrollToDiv('inicio_modal')">
          <div class="ri-021">
            <img class="ri-02-icon"  alt="" src="./assets/ri02.svg" />

            <div class="div">3</div>
          </div>
        </div>
        <div class="ri-04">
          <div  (click)="openModal(4)" (click)="scrollToDiv('inicio_modal')"class="ri-021">
            <img class="ri-02-icon" alt="" src="./assets/ri02.svg" />

            <div class="div">4</div>
          </div>
        </div>
      </div>
      <div class="ri-01" (click)="openModal(1)" (click)="scrollToDiv('inicio_modal')">
        <div class="ri-021">
          <img class="ri-02-icon" alt="" src="./assets/ri02.svg" />

          <div class="div3">1</div>
        </div>
      </div>
      <app-modal-recarregar-hidricas
      [showModal]="showModal"
      [selectedButton]="selectedButton"
      (closeModal)="closeModal()"
      ></app-modal-recarregar-hidricas>  
     <app-tecnologias-socias></app-tecnologias-socias>
      <app-novidade></app-novidade>
      <img class="mask-group-icon4" alt="" src="./assets/mask-group4.svg" />

      <div class="gua-produzida">
        <div class="div4">149,8</div>
        <div class="m-de-gua">m³ de água recarregada</div>
      </div>
      <div class="tecnologias-implementadas">
        <div class="div5">214</div>
        <div class="de-alimentos-produzidos">tecnologias implementadas</div>
      </div>
      <div class="alimentos-produzidos">
        <div class="div6">
          <span class="span">0</span>
          <span class="kg">kg</span>
        </div>
        <div class="de-alimentos-produzidos">de alimentos produzidos</div>
      </div>
      <div class="rolagem-1-child3"></div>
      <div class="pessoas-envolvidas">
        <img class="pessoas-envolvidas-child" alt="" src="./assets/ellipse-10.svg" />

        <div class="parent">
          <div class="div7">
            <span>2040</span>
            <span class="span1"> </span>
          </div>
          <div class="pessoas-envolvidas1">
            <span class="pessoas-envolvidas-txt-container">
              <p class="participe">pessoas</p>
              <p class="participe">empoderadas</p>
            </span>
          </div>
        </div>
      </div>
      <div class="renda-gerada">
        <img class="pessoas-envolvidas-child" alt="" src="./assets/ellipse-10.svg" />

        <div class="div8">
          <span>R$</span>
          <span class="span2">1,5</span>
          <span>mil</span>
          <span class="span3"> </span>
        </div>
        <div class="de-renda-gerada-container">
          <span class="pessoas-envolvidas-txt-container">
            <p class="participe">de renda</p>
            <p class="participe">gerada</p>
          </span>
        </div>
      </div>
      
      <app-patrocinadores></app-patrocinadores>

      <div class="agir-ambiental-todos">
        © 2023 Agir Ambiental, Todos os direitos reservados
      </div>
      <div class="texto-principal-home">
        Nosso propósito é contribuir com a conservação e a recarga hídica através de tecnologias sociais, que são implementadas
        de forma colaborativa e integrada para adaptação às mudanças climáticas, segurança hídrica e alimentar, geração de renda
        e empoderamento.
      </div>
      <b id="Atividades" class="atividades">Atividades</b>


    </div>
    <div class="menu">
      <div class="menu-child"></div>
      <a (click)="scrollToDiv('inicio')">
      <div class="incio">
        <a (click)="scrollToDiv('inicio')" class="incio1">Inicio</a>
        <img class="incio-child" alt="" src="./assets/group-1000005236.svg" />
      </div>
      </a>
      <a (click)="scrollToDiv('tecnologias')">
      <div class="tecnologias">
        <a (click)="scrollToDiv('tecnologias')" class="tecnologias1">Tecnologias</a>

        <img class="tecnologias-child" alt="" src="./assets/group-1000005234.svg" />
      </div>
      </a>
      <a (click)="scrollToDiv('novidades')">
      <div class="atividades1">
        <div>
          <a (click)="scrollToDiv('novidades')" class="atividades2">Novidades</a>
        </div>
        <img class="vector-icon8" alt="" src="./assets/vector8.svg" />
      </div>
      </a>
      <div class="entrar2">
        <div class="entrar3">entrar</div>
        <img class="group-icon4"  (click)="irParaTelaLogin()" alt="" src="./assets/group1.svg" />
      </div>
      <div (click)="installPWA()"class="mais">
        <div class="mais1">download</div>
        <img class="mais-child" alt="" src="./assets/menu_download.svg" />
      </div>

      
    </div>
  </div>

  <script>
    var gota3 = document.getElementById("gota3");
    if (gota3) {
      gota3.addEventListener("click", function () {
        var anchor = document.querySelector("[data-scroll-to='scroll02Icon']");
        if (anchor) {
          anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      });
    }

    function scrollToDiv(event, divId) {
      event.preventDefault(); // Impede o comportamento padrão da tag <b>

      const targetDiv = document.getElementById(divId);
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth' }); // Rola até a div de destino de forma suave
      }
    }
  </script>
</body>



</html>