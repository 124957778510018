import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiUrl: string;

    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl;
    }
  
    loginWithGoogle(): void {
        const url = `${this.apiUrl}/auth/google`;
        const newTab = window.open(url, '_blank');
        // Verificar se a nova aba foi aberta com sucesso
        if (newTab) {
          // Adicione um evento para escutar a mensagem da nova aba
          window.addEventListener('message', (event) => {

            if (event.source === newTab) {
              const response = event.data;
              console.log('Resposta de login do Google:', response);
              // Aqui você pode realizar as ações necessárias com a resposta, como autenticação ou redirecionamento.
            }
          });
        }
      }
        
      login(email: string, password: string) {
        const body = {
          email: email,
          password: password
        };
    
        const url = `${this.apiUrl}/auth/login`;
    
        return this.http.post(url, body);
      }
}
