<div class="backgroud">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  
      <div class="container-title background-color">
        <h1 class="title">Cadastrar</h1>
        <img class="seta" src="./assets/seta.png" />
      </div>
  

      <div class="containerName">
        <input class="input" type="text" formControlName="celular" placeholder="CELULAR" appMaskPhoneNumber />
      </div>
  
      <div class="containerName" [class.error-input]="showIdadeError">
        <div class="input_data">
          <input
            (click)="d.toggle()"
            class="input"
            type="text"
            formControlName="idade"
            placeholder="DATA NASCIMENTO"
            ngbDatepicker
            #d="ngbDatepicker"
            [minDate]="minDate"
            [placement]="'bottom'"
            [value]="formatDateControlValue(loginForm.get('idade').value)"
          />
          <i (click)="d.toggle()" class="fa-regular fa-calendar-days custom-calendario" aria-hidden="true"></i>
        </div>
        <span *ngIf="showIdadeError" class="error-message alert alert-danger" role="alert">{{ IdadeErrorMessage }}</span>
      </div>
      
      
      <div class="containerName">
        <select class="input" formControlName="genero" id="genero">
          <option value="">SELECIONE O GÊNERO</option>
          <option *ngFor="let opcao of opcoesGenero" [value]="opcao.value">{{ opcao.label }}</option>
        </select>
      </div>
      
      <!-- <div class="root-btn">
          <div class="buttomLogin">
            <button type="submit" class="text-btn custom-button">
              <img draggable="false" class="logo" src="../../../assets/logo2.png" alt="logo" />
              CADASTRAR
            </button>
          </div>
        </div> -->
        <br/>
        <div class="root-termos">
          <div class="checkbox-container">
            <input type="checkbox" id="termos-checkbox" (change)="verificarCheckbox()">
            <label for="termos-checkbox"></label>
          </div>
          <div class="text-container">
            <span class="checkbox-text">
            Concordo com os <a href="https://api.recarrega.app.br/uploads/aprenda/fGvVbbjLYZSFG0CJF61tmpi5owwi4lr7UTlLRTyw.pdf" target="_blank">Termos de Uso</a>
              Políticas de Privacid            </span>
          </div>      
        </div>

      <br/>
      <div class="root-btn">
        <div class="tela-7-inner">
          <div class="boto-2-parent">
            <button type="submit" class=" custom-button">
              <div class="boto-2"></div>
              <div class="quero-me-cadastrar-wrapper">
                <div  class="text-input">CONFIRMAR CADASTRO</div>
              </div>
            </button>
          </div>
        </div>
      </div>
  
      <app-carregamento-padrao *ngIf="isLoading"></app-carregamento-padrao>
      <div class="root-voltar">
        <h5 class="text-voltar" (click)="irParaTelaHome()">
          < VOLTAR </h5>
      </div>
    </form>
  </div>