<div class="menu-container">

<div class="menu">
  <div class="menu-item">
    <img  (click)="irParaMural()" src="/assets/mural.svg" alt="Mural">
    <span>Mural</span>
  </div>
  <div class="menu-item" >
    <img (click)="irParaColabore('grupo')" [src]="AlertColabore ? '/assets/colabore_notificacoes.svg' : '/assets/colabore.svg'" alt="Colabore">
    <span>Colabore</span>
  </div>
  <div class="menu-item">
    <img  (click)="irParaaprenda()" src="/assets/aprenda.svg" alt="Aprenda">
    <span>Aprenda</span>
  </div>

  <div class="menu-item suporte">

    <img (click)="irParaColabore('grupo_suporte')" [src]="AlertSuporte ? '/assets/suporte_notificacao.svg' : '/assets/suporte.svg'" alt="Colabore">
  <span>Suporte</span>
</div>
  <div class="menu-item mais" (click)="toggleSubMenu()">
    <img src="/assets/group-1000005232.svg" alt="Mais">
    <span>Mais</span>
  </div>
</div>

<div class="sub-menu" *ngIf="showSubMenu">
  <div class="arrow-row">
    <div class="close-icon" (click)="closeSubMenu()">
      <img src="/assets/seta_fecha.svg" alt="Fechar">
    </div>
  </div>
  <div class="menu-row">
    <div class="submenu-item"  (click)="irParausuarios()">
      <img src="/assets/meu_perfil.svg" alt="Meu Perfil">
    </div>
    <div (click)="irParaMonitore()" class="submenu-item">
      <img src="/assets/monitore_breve.svg" alt="Monitome">
    </div>
  </div>
  <div class="menu-row">
    <div class="submenu-item">
      <img src="/assets/gotas_breve.svg" alt="Minhas Gostas">
    </div>
    <div class="submenu-item">
      <img src="/assets/mapeie_breve.svg" alt="Mapeia">
    </div>
  </div>
  <div class="menu-row">
    <div class="submenu-item" (click)="logout()">
      <img src="/assets/sair.svg" alt="Sair">
    </div> 
    <div (click)="irParaRotaHome()" class="submenu-item">
      <img src="../../assets/O Recarrega.svg" class="transparent-image" alt="Recarrega">
    </div>  
  </div>
  
</div>

