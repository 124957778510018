import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hortas',
  templateUrl: './hortas.component.html',
  styleUrls: ['./hortas.component.css']
})
export class HortasComponent {

  DadosMocados = [
    { id: 1, escola: 'EMEF Ibraim Nobre' },
    { id: 2, escola: 'Cresan Butantã ' },
    { id: 3, escola: 'Orgânico Simples  ' },
    { id: 4, escola: 'E.E. Prof. Almeida Júnior ' },
    { id: 5, escola: 'E.E. Prof. Architiclino Santos' },
    { id: 6, escola: 'E.E. Prof. Emygdio de Barros' },
    { id: 7, escola: 'E.E. Dona Ana Rosa de Araújo' },
    { id: 8, escola: 'E.E. Prof. Guiomar Rocha Rinaldi' },
    { id: 9, escola: 'E.E. Alexandre Von Humboldt' },
    { id: 10, escola: 'E.E. Odair Martiniano da Silva Mandela' },
    { id: 11, escola: 'E.E. João XXIII' },
    { id: 12, escola: 'Castelinho Cure o Mundo' },
    { id: 13, escola: 'Ponto de Economia Solidária e Cultura do Butantã' },
    { id: 14, escola: 'Horta do tio Zé' },
    { id: 15, escola: 'Espaço Cultural Monte Kemel' },
    { id: 16, escola: 'Horta Popular Criando Esperança' },
    { id: 17, escola: 'Associação Amigos de Bairro do Jd. Silvia' },
    { id: 18, escola: 'Casa de Cultura do Butantã' },
    { id: 19, escola: 'Nossa Horta' },
    { id: 20, escola: 'Comuna da Terra Irmã Alberta - Lote Rosângela' },
    { id: 21, escola: 'Comuna da Terra Irmã Alberta - Lote Pedrosa' },
    { id: 22, escola: 'Mulheres do GAU' },
  ];

  constructor(private router: Router) {
    // Ordena os dados mocados em ordem alfabética pelo nome da escola
    this.DadosMocados = this.DadosMocados.sort((a, b) => a.escola.localeCompare(b.escola));
  }

  compartilharLink(url, titulo, text) {
    if (navigator.share) {
      navigator.share({
        title: titulo,
        text: text,
        url: 'https://www.recarrega.app.br/modulos/hortas/story/' + url,
      })
        .then(() => console.log('Conteúdo compartilhado com sucesso!'))
        .catch((error) => console.error('Erro ao compartilhar conteúdo:', error));
    }
  }

  IrParaRotaHome() {
    this.router.navigate(['/home_sp']);
  }
}

