import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { DataService } from 'src/services/dataService';
import { Subscription } from 'rxjs';
import { Atividades_service } from 'src/services/Atividades.service';
import { Router } from '@angular/router';
import { ForumService } from 'src/services/Forum_Service';
import { DatePipe } from '@angular/common';




interface Atividade {
  id_Atividade: number;
  id_tema: number;
  descricao: string;
  horario: string;
}

@Component({
  selector: 'app-modal-atividades-usuarios',
  templateUrl: './modal-atividades-usuarios.component.html',
  styleUrls: ['./modal-atividades-usuarios.component.scss']
})
export class ModalAtividadesUsuariosComponent {

  atividadeId: number | null = null;
  private subscription: Subscription;


  @Input() eventos: Atividade[] = [];
  @Input() showModal: boolean = false;
  @Input() MinhdasAtividades: boolean = false;
  @Input() modalPosition: string = '0';

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() FazerRequisicaoUsers: EventEmitter<void> = new EventEmitter<void>();
  baseUploadUrl = 'https://api.recarrega.app.br/uploads/';
  uploadCaminho(caminho: string) {
    const caminhoCompleto = this.baseUploadUrl + caminho;
  }

  mostrarMinhasAtividades = false; // Inicialmente, não mostra apenas as atividades do usuário atual


  constructor(private dataservice: DataService, private atividade_service: Atividades_service, private router: Router,
    private datePipe: DatePipe,
    private forum: ForumService) {
    this.subscription = this.dataservice.atividadeId$.subscribe(
      (id: number | null) => {
        this.atividadeId = id; // Atualiza o valor do ID quando recebe uma atualização do serviço
        this.showModal = true; // Define showModal para true quando recebe uma atualização do serviço
      }
    );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe(); // Não se esqueça de cancelar a inscrição para evitar vazamentos de memória
  }

  getEventoSelecionado(): Atividade | null {
    if (this.atividadeId !== null) {
      return this.eventos.find(evento => evento.id_Atividade === this.atividadeId) || null;
    }
    return null;
  }

  fecharmodal() {
    this.showModal = false;
    this.atividadeId = null;
    this.dataservice.Resetclick();
  }

  addForumToUser() {
    const id_user = parseInt(sessionStorage.getItem('userID'), 10);


    this.forum.addForumToUser(this.atividadeId, id_user)
      .subscribe(
        response => {
          alert(response)
          //reseta ao normal
          this.showModal = false;
          this.atividadeId = null;
        },
        error => {
          console.error('Erro ao cadastrar :', error);
          // alert(error.error.message)
          this.showModal = false;
          this.atividadeId = null;
        }
      );

  }

  Cadastrar_UserAtividade() {
    this.atividade_service.postUsersAtividade(sessionStorage.getItem('userID'), this.atividadeId)
      .subscribe(
        response => {
          alert(response.message)
          //reseta ao normal
          this.showModal = false;
          this.atividadeId = null;
          this.FazerRequisicaoUsers.emit();

        },
        error => {
          //  console.error('Erro ao cadastrar :', error);
          if (error.error.message == 'O usuário já tem essa atividade cadastrada.') {
            alert(error.error.message)
          }
          //reseta ao normal
          this.showModal = false;
          this.atividadeId = null;
          if (error.error.message) {
            alert('Por favor, para realizar cadastro em alguma atividade faça login!')
            this.router.navigate(['/modulos/index']);
          }
        }
      );
  }
  onSubmit() {
    if (!this.MinhdasAtividades) {
      this.Cadastrar_UserAtividade()
    } else {
      // this.addForumToUser()
      this.router.navigate(['modulos/chat/grupo'])
    }

  }


  formatarDataHora(dataHora: string): string {
    const dataHoraObj = new Date(dataHora);
    return this.datePipe.transform(dataHoraObj, 'dd/MM/yyyy HH:mm');
  }
}





