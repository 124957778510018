import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class novidadesServices {
    private apiUrl: string;
    private token = sessionStorage.getItem('token'); // Obtém o token de autorização da sessionStorage

    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl;
    }


    getUsersNovidades(user_id: number) {
        const url = `${this.apiUrl}/lido/novidade/users/${user_id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
        return this.http.get(url, { headers });

    }

    postUsersNovidades(user_id: number, lido: boolean, novidade_id: number): Observable<any> {
        const payload = {
            user_id: user_id,
            novidade_id: novidade_id,
            lido: lido
        };
        const url = `${this.apiUrl}/lido`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);

        return this.http.post<any>(url, payload, { headers });
    }


    deleteUsersNovidades(novidade_id: number): Observable<any> {
        const url = `${this.apiUrl}/lido/${novidade_id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
        return this.http.delete(url, { headers });
      }
}
