<app-menu-desk></app-menu-desk>
<div class="backgroud">
  <!-- <div class="tudo"> -->
  <div class="box-top">
    <nav>
      <div class="icon"><img src="../../assets/perfil.png" alt=""></div>
      <div (click)="irParaTelamodulosIndex()" class="icon"> <img src="../../assets/seta.png" alt=""></div>
    </nav>
    <div class="box-text-top">
      <h1 class="text-box-top">Mapeie em breve</h1>
    </div>
  </div>
  <div>
    <img class="img-mapeie" src="../../../assets/img_mapeie.png" alt="">
  </div>
  <div class="box-bottom">
    <div class="box-textdesk">
      <h1 class="text-boxdesk">Mapeie em breve</h1>
    </div>
    <p class="text1 text">Descrição do que é o mapeie lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in augue
      nisi. Sed interdum vitae libero eget dictum. Mauris euismod lacinia augue, nec vehicula mauris blandit at. Donec
      nunc velit, tempor vel auctor non, sagittis sed leo. Etiam eleifend nisi libero, eget dictum ante convallis sed.
      Pellentesque ut ipsum</p>
    <div class="container-checar-e-mail">
      <p (click)="irParaTelamodulosIndex()" class="checar-e-mail">Voltar para o início</p>
    </div>
    <br>
  </div>
<!-- </div> -->
</div>
<app-menu></app-menu>

