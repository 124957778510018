import { Component, OnInit, ViewChild, TemplateRef, Injectable, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { chat } from './data';
import { Chats, Groups } from './chat.model';
import { Lightbox } from 'ngx-lightbox';
// Date Format
import { DatePipe } from '@angular/common';
import { ForumService } from 'src/services/Forum_Service';
import { io, Socket } from 'socket.io-client';
import { SocketService } from 'src/services/socket.service';
import { UserService } from 'src/services/user.service';
import { Atividades_service } from 'src/services/Atividades.service';


@Component({
  selector: 'app-index',
  templateUrl: './chat-index.component.html',
  styleUrls: ['./chat-index.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class chatIndexComponent implements OnInit {

  public isCollapsed = true;
  activetab = 3;
  chat: Chats[];
  groups: Groups[] = [];
  formData!: FormGroup;
  @ViewChild('scrollRef') scrollRef: any;
  emoji = '';
  isreplyMessage = false;
  isgroupMessage = false;
  mode: string | undefined;
  urlUpload: string = 'https://api.recarrega.app.br/uploads/';
  Localevento: string;
  DescricaoCompletaEevento: string;
  HorarioEvento: string

  user_id: number;
  grupoId: number;
  listLang = [];
  lang: string;
  images: { src: string; thumb: string; caption: string }[] = [];
  userID: number;
  newMessage: string = '';
  url_documento_salvo: string = '';
  senderName: any;
  senderProfile: any;
  messages: any[] = [];
  novasmessages: any[] = [];
  chatReplyUser: string = '';
  chatReplyMessage: string = '';
  isWebSocketConnected: boolean = false;
  isConnected: boolean = false;
  fotoPerfil: string = '';
  elementIndexClicked: number;
  onlineUsers: string[] = [];
  listaUsuarios: any[] = [];
  quantidadeUsuarios: number;
  TipoColabore: string;
  TipoChat: string;



  constructor(
    private router: Router,
    public translate: TranslateService,
    private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas,
    public formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private lightbox: Lightbox,
    private forumService: ForumService,
    private socketService: SocketService,
    private atividade_service: Atividades_service,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {

    /*Url foto fe perfil do usuario*/
    const foto_perfil = sessionStorage.getItem('foto_perfil');
    if (foto_perfil === 'null' || foto_perfil == null) {
      this.fotoPerfil = '../../assets/perfil_chat_padrao.svg'
    } else {
      this.fotoPerfil = this.urlUpload + foto_perfil;
    }

    this.route.paramMap.subscribe(params => {
      this.TipoColabore = params.get('tipo');
      this.groups=null;
      this.TipoChat= null

      console.log(this.TipoColabore)
      if(this.TipoColabore =='grupo'){
        this.loadGroupsByGrupo();
        this.TipoChat ='Colabore';
      }
  
      if(this.TipoColabore =='grupo_suporte'){
        this.loadGroupsBySuporte()
        this.TipoChat ='Suporte';

      }
    });


    document.body.setAttribute('data-layout-mode', 'light');
    // Validation
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });


    const username = sessionStorage.getItem('name');
    const email = sessionStorage.getItem('email');
    const user = `{"username": "${username}", "age": 30, "${email}": "john@example.com"}`;

    this.senderName = JSON.parse(user).username
    this.senderProfile = '';
    this.chat = chat;

    
    this.lang = this.translate.currentLang;
    this.onListScroll();
    this.socketService.connectWebSocket(this.userID);


    /*
   Fazendo a inscrição no web socket para poder recbeer mensagem em tempo real e verificar se ta online ou ofline
   */
    this.socketService.getNewMessageObservable().subscribe(message => {
      try {
        const dados_message = message;

        if (message.type != 'updateUserList') {

          this.novasmessages.push(message); // Adicione a nova mensagem ao array
          const groupToUpdate = this.groups.find(group => group.id === message.id_forum);

          // Se o grupo existir, incremente 'unread'
          if (groupToUpdate) {
            groupToUpdate.unread++;
          }

          if (dados_message.isimage) {
            this.CarregaMensagensa(message)

          } else {
            // Carrega apenas quando o usuario que enviado for diferente do usuario da sessao
            if (dados_message.id_user != this.userID) {
              this.CarregaMensagensa(message)
            }
          }
        }
      } catch (error) {
        console.error(error);
      }

    });

    this.socketService.getOnlineUsersList().subscribe(users => {
      this.onlineUsers = users;

    },
      (error) => {
        console.error(error);
      });

  }

  getIconForExtension(extension: string): string {
    const extensionIcons: { [key: string]: string } = {
      pdf: 'https://api.recarrega.app.br/uploads/images/7MmelC6KHzSdjxFzSitTtUqRkCUR5OsnGeNXWWZN.png',
      docx: 'https://api.recarrega.app.br/uploads/images/xvvhUsGYq3XVoYAcV6ANEF3PUwWmkQ6VTleE3ef6.png',
      // Outras extensões e ícones aqui
    };

    // Verifica se há um ícone correspondente para a extensão
    return extensionIcons[extension.toLowerCase()] || 'default-icon.svg';
  }


  // Esta função verifica se o usuário está na lista de usuários online
  isUserOnline(id: any): boolean {
    const isOnline = this.onlineUsers.includes(id.toString()); // Substitua 'id' pela propriedade correta que identifica o usuário

    return isOnline;
  }


  /*Aqui temos duas funcoas iguais o que muda eh o end point que ela chama uma vem somente os grupos de suporte e outras vem todos os grupos sem os suporte,
   feito a separacao pois no menu eh como se tivesse duas telas uma para cada grupo*/
  loadGroupsByGrupo(): void {
    this.userID = parseInt(sessionStorage.getItem('userID'));
    this.forumService.getConversationsInForum(this.userID).subscribe(
      (response: Groups[]) => {
        this.groups = response;
        // Verificar e atualizar o array imageContent, se existir adicionado para deixar dinaminaco quando tiver arquivos imagens/documentos concatena a url definida da api no aquivo environment mais o valor que bem no banco de dados
        this.groups.forEach((group) => {
          if (group.messages && group.messages.length > 0) {
            group.messages.forEach((message) => {
              if (message.imageContent) {
                message.imageContent.forEach((image) => {
                  // Concatenar o valor da chave 'src'
                  image.src = 'http://api.recarrega.app.br/' + '/uploads/' + image.src;
                  image.thumb = 'http://api.recarrega.app.br/' + '/uploads/' + image.thumb;
                });
              }
            });
          }

        });
      },
      (error) => {
        console.error(error);
      }
    );

  }

  loadGroupsBySuporte(): void {
    this.userID = parseInt(sessionStorage.getItem('userID'));
    this.forumService.getConversationsInForumBySuporte(this.userID).subscribe(
      (response: Groups[]) => {
        this.groups = response;
        // Verificar e atualizar o array imageContent, se existir adicionado para deixar dinaminaco quando tiver arquivos imagens/documentos concatena a url definida da api no aquivo environment mais o valor que bem no banco de dados
        this.groups.forEach((group) => {
          if (group.messages && group.messages.length > 0) {
            group.messages.forEach((message) => {
              if (message.imageContent) {
                message.imageContent.forEach((image) => {
                  // Concatenar o valor da chave 'src'
                  image.src = 'http://api.recarrega.app.br/' + '/uploads/' + image.src;
                  image.thumb = 'http://api.recarrega.app.br/' + '/uploads/' + image.thumb;
                });
              }
            });
          }

        });
      },
      (error) => {
        console.error(error);
      }
    );

  }

  RefazRequisicaoDosGrupos() {
    if(this.TipoColabore =='grupo'){
      this.loadGroupsByGrupo();
      this.TipoChat ='Colabore';
    }

    if(this.TipoColabore =='grupo_suporte'){
      this.loadGroupsBySuporte()
      this.TipoChat ='Suporte';

    }  }

  CarregaMensagensa(message: any) {

    if (this.grupoId == message.id_forum) {
      this.message.push({
        id: message.id,
        message: message.message,
        name: message.name,
        profile: message.profile,
        time: message.time,
        align: message.align,
        isimage: message.isimage,
        imageContent: message.imageContent,
        replayName: message.replayName,
        replaymsg: message.replaymsg,
        id_user: message.id_user,
        id_forum: message.id_forum,
        classe_referencia: `lista_${this.elementIndexClicked - 1}`
      });


      this.onListScroll();

    }
  }

  ngAfterViewInit() {
    this.scrollRef.SimpleBar.getScrollElement().scrollTop = 100;
  }

  /**
   * Show user profile
   */
  // tslint:disable-next-line: typedef
  showUserProfile() {
    document.getElementById('profile-detail').style.display = 'block';
  }

  /**
   * Close user chat
   */
  // tslint:disable-next-line: typedef
  closeUserChat() {

    var array_id_menssagens = this.groups.find((group: any) => group.id === this.grupoId);
    if (array_id_menssagens) {
      // Filtrar mensagens não lidas
      const unreadMessages = array_id_menssagens.messages.filter((message: any) => !message.is_read);
      // Obter os IDs das mensagens não lidas
      const unreadMessageIds = unreadMessages.map((message: any) => message.id);
      this.markMessagesAsRead(unreadMessageIds)
      // Agora você tem os IDs das mensagens não lidas em unreadMessageIds
      // console.log('IDs das mensagens não lidas:', unreadMessageIds);

    }
    document.getElementById('chat-room').classList.remove('user-chat-show');

    this.router.navigate(['modulos/chat', this.TipoColabore]);

  }

  /**
   * Logout the user
   */
  logout() {
    alert("saiir");
    this.router.navigate(['/account/login']);
  }

  /**
   * Set language
   * @param lang language
   */
  setLanguage(lang) {
    this.translate.use(lang);
    this.lang = lang;
  }

  openCallModal(content) {
    this.modalService.open(content, { centered: true });
  }

  openVideoModal(videoContent) {
    this.modalService.open(videoContent, { centered: true });
  }

  /**
   * Show user chat
   */
  // tslint:disable-next-line: typedef
  userName: any = '';
  userStatus: any = '';
  userProfile: any = '';
  message: any;
  showChat(event: any, id: any) {

    var removeClass = document.querySelectorAll('.chat-user-list li');
    removeClass.forEach((element: any) => {
      element.classList.remove('active');
    });

    document.querySelector('.user-chat').classList.add('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.user-chat').classList.remove('d-none');
    event.target.closest('li').classList.add('active');
    var data = this.chat.filter((chat: any) => {
      return chat.id === id;
    });
    this.userName = data[0].name
    this.userStatus = data[0].status
    this.userProfile = data[0].profilePicture
    this.message = data[0].messages
    this.onListScroll();
  }

  // Contact Search
  ContactSearch() {
    var input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
    input = document.getElementById("searchContact") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.querySelectorAll(".chat-user-list");
    ul.forEach((item: any) => {
      li = item.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("h5")[0];
        txtValue = a?.innerText;
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    })
  }

  // Message Search
  MessageSearch() {
    var input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
    input = document.getElementById("searchMessage") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.getElementById("users-conversation");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("p")[0];
      txtValue = a?.innerText;
      if (txtValue?.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }

  // Filter Offcanvas Set
  onChatInfoClicked(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  /**
   * Save the message in chat
   */
  messageSave() {

    var groupMsg = document.querySelector('.pills-groups-tab.active');
    const message = this.formData.get('message')!.value;
    if (!groupMsg) {
      document.querySelector('.chat-user-list li.active .chat-user-message').innerHTML = message ? message : this.img;
    }
    var img = this.img ? this.img : '';
    var dateTime = new Date();
    this.chatReplyUser = this.isreplyMessage == true ? (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name") as HTMLAreaElement).innerHTML : '';
    this.chatReplyMessage = this.isreplyMessage == true ? (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0") as HTMLAreaElement).innerText : '';


    const formValue = this.formData.value;
    const conteudo_menssage = formValue.message;
    const forumId = this.grupoId;

    const conversationData = {
      user_id: sessionStorage.getItem('userID'),
      content: conteudo_menssage,
      align: 'right',
      id_forum: forumId,
      replayName: this.chatReplyUser,
      replaymsg: this.chatReplyMessage,
      classe_referencia: `lista_${this.elementIndexClicked - 1}`
    };

    const fotoperfil = sessionStorage.getItem('foto_perfil') === 'null' ? '' : sessionStorage.getItem('foto_perfil');

    this.forumService.postConversationInForum(forumId, conversationData).subscribe(
      response => {
        // console.log('Conversa enviada com sucesso!', response);
        const newMessage = {
          id: this.message.length + 1,
          message: message,
          name: this.senderName,
          profile: fotoperfil,
          time: dateTime,
          align: 'right',
          isimage: false,
          imageContent: [img],
          replayName: this.chatReplyUser,
          replaymsg: this.chatReplyMessage,
          id_user: this.userID,
          id_forum: forumId,
          classe_referencia: `lista_${this.elementIndexClicked - 1}`
        };

        this.CarregaMensagensa(newMessage)
        const messageJSON = JSON.stringify(newMessage);
        // Enviar mensagem JSON para o servidor WebSocket
        this.sendMessageToServer(messageJSON);

        // Set Form Data Reset
        this.formData = this.formBuilder.group({
          message: null,
        });
        this.isreplyMessage = false;
        this.emoji = '';
        this.img = '';
        this.chatReplyUser = '';
        this.chatReplyMessage = '';
        document.querySelector('.replyCard')?.classList.remove('show');
      },
      error => {
        console.error('Erro ao enviar conversa:', error);
        // Trate o erro adequadamente
      }

    );

    this.onListScroll();
  }

  onListScroll() {
    if (this.scrollRef !== undefined) {
      setTimeout(() => {
        this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
      }, 500);
    }
  }

  scrollToDiv(divId: string) {
    const targetDiv = document.getElementById(divId);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }


  // Emoji Picker
  showEmojiPicker = false;
  sets: any = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set: any = 'twitter';
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event: any) {
    const { emoji } = this;
    const text = `${emoji}${event.emoji.native}`;
    this.emoji = text;
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }
  onBlur() {
  }

  closeReplay() {
    document.querySelector('.replyCard')?.classList.remove('show');
  }

  // Copy Message
  copyMessage(event: any) {
    navigator.clipboard.writeText(event.target.closest('.chats').querySelector('.messageText').innerHTML);
    document.getElementById('copyClipBoard')?.classList.add('show');
    setTimeout(() => {
      document.getElementById('copyClipBoard')?.classList.remove('show');
    }, 1000);
  }

  // Delete Message
  deleteMessage(event: any, data: any) {


    this.forumService.DeleteMessagesById(data.id).subscribe(
      (response) => {

        event.target.closest('.chats').remove();

      },
      (error) => {
        console.error(error);
      }
    );

  }

  // Delete All Message
  deleteAllMessage(event: any) {
    var allMsgDelete: any = document.getElementById('users-conversation')?.querySelectorAll('.chats');
    allMsgDelete.forEach((item: any) => {
      item.remove();
    })
  }


  isEditing: boolean = false;
  editedMessage: string = '';

  // Função para iniciar a edição

  editMessage(data: any) {
    // Encontre a mensagem com o ID correspondente no array de mensagens
    const messageToEdit = this.message.find(message => message.id === data.id);

    if (messageToEdit) {
      // Carregue a mensagem antiga na variável editedMessage
      this.editedMessage = data.message
      messageToEdit.isEditing = true;
    }
  }


  // Função para salvar a mensagem editada
  saveEditedMessage(novamessage: string, data: any) {

    const updateMessage = this.message.findIndex(message => message.id == data.id);
    const messageToEdit = this.message.find(message => message.id == data.id);
    var dateTime = new Date();

    if (updateMessage !== -1) {
      this.message[updateMessage].message = novamessage
      this.message[updateMessage].time = dateTime
      this.forumService.EditMessagesById(data.id, novamessage).subscribe(
        (response) => {
        },
        (error) => {
        }
      );
      messageToEdit.isEditing = false;
    }


    this.isEditing = false;
  }
  // Replay Message
  replyMessage(event: any, align: any) {
    this.isreplyMessage = true;

    document.querySelector('.replyCard')?.classList.add('show');
    var copyText = event.target.closest('.chats').querySelector('.messageText').innerHTML;
    (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0") as HTMLAreaElement).innerHTML = copyText;
    var msgOwnerName: any = event.target.closest(".chats").classList.contains("right") == true ? 'Voce' : document.querySelector(`.lista_${this.elementIndexClicked} span`)?.innerHTML;
    (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name ") as HTMLAreaElement).innerHTML = msgOwnerName;
  }
  onElementClick(index: number) {
    this.elementIndexClicked = index;
  }
  /**
  * Open center modal
  * @param centerDataModal center modal data
  */
  centerModal(centerDataModal: any) {
    this.modalService.open(centerDataModal, { centered: true });
  }

  // File Upload
  imageURL: string | undefined;
  img: any;

  imagemFile: File;

  fileChange(event: any) {
    let fileList: any = (event.target as HTMLInputElement);
    this.imagemFile = fileList.files[0];


    const nomeDoArquivo = this.imagemFile.name;
    const reader = new FileReader();
    const tamanhoMaximo = 10 * 1024 * 1024 
    if (this.imagemFile.size > tamanhoMaximo) {
      alert("Tamanho não suportado (maior que 10MB)")
      return
    }

    const partesDoNomeDoArquivo = nomeDoArquivo.split('.');


    const extensaoDoArquivo = partesDoNomeDoArquivo[partesDoNomeDoArquivo.length - 1].toLowerCase();


    const extensoesValidas = ['jpg', 'jpeg', 'png', 'gif'];


    if (extensaoDoArquivo === 'pdf' || extensaoDoArquivo === 'doc' || extensaoDoArquivo === 'docx' || extensaoDoArquivo === 'xls' || extensaoDoArquivo === 'xlsx') {
      this.salvarImagemServidor();
      return
    } else if (extensoesValidas.includes(extensaoDoArquivo)) {
      reader.onload = () => {
        this.imageURL = reader.result as string;
        this.img = this.imageURL;

        this.imageURL = reader.result as string;

      };
      reader.readAsDataURL(this.imagemFile);
    } else {
      alert("Formato de documento inválido")
      return
    }

  }

  closeImage() {
    this.imageURL = null;
    this.imagemFile = null;
  }


  handleImageURLChange() {
    // Coloque aqui a lógica que você deseja executar quando imageURL se torna verdadeiro.
    // Por exemplo, chame sua função aqui.
    this.salvarImagemServidor()
  }


  salvarImagemServidor() {
    var dateTime = new Date();
    const forumId = this.grupoId;

    const formData = new FormData();
    formData.append('user_id', sessionStorage.getItem('userID'));
    formData.append('file', this.imagemFile);
    formData.append('align', 'right');
    this.forumService.postConversationInForumDocumentos(forumId, formData).subscribe(
      response => {
        // Manipular a resposta da requisição, se necessário
        this.imageURL = null
        this.url_documento_salvo = response.imageUrl
        const newMessage = {
          id: this.message.length + 1,
          message: null,
          name: this.senderName,
          profile: this.senderProfile,
          time: dateTime,
          align: 'right',
          isimage: true,
          imageContent: [{
            caption: "Image caption here",
            id: this.message.length, // Ou algum outro ID apropriado
            src: 'https://api.recarrega.app.br/uploads/' + this.url_documento_salvo,
            thumb: 'https://api.recarrega.app.br/uploads/' + this.url_documento_salvo
          }],
          id_user: this.userID,
          id_forum: forumId
        };

        const messageJSON = JSON.stringify(newMessage);
        // Enviar mensagem JSON para o servidor WebSocket
        this.sendMessageToServer(messageJSON);
      },
      error => {
        // Lidar com erros, se ocorrerem
        console.error(error);
      }
    );
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  changeMode(mode: string) {
    this.mode = mode;
    switch (mode) {
      case 'light':
        document.body.setAttribute('data-layout-mode', "light");
        break;
      case 'dark':
        document.body.setAttribute('data-layout-mode', "dark");
        break;
      default:
        document.body.setAttribute('data-layout-mode', "light");
        break;
    }
  }

  /***
   * ========== Group Msg ============
   */
  /**
 * Show user chat
 */
  // tslint:disable-next-line: typedef
  showGroupChat(event: any, id: any) {

    var removeClass = document.querySelectorAll('.chat-list li');
    removeClass.forEach((element: any) => {
      element.classList.remove('active');
    });
    document.querySelector('.user-chat').classList.add('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.user-chat').classList.remove('d-none');
    event.target.closest('li').classList.add('active');
    var data = this.groups.filter((group: any) => {
      return group.id === id;
    });
    this.userName = data[0].name


    this.message = this.separateMessages(data[0].messages)

    this.message = this.message.map(message => {
      return { ...message, isEditing: false };
    });


    this.grupoId = id;
    this.getUsersByForum(this.grupoId);

    // Buscando dados da atividade, quando clicca na parte de cima do perfil

    this.atividade_service.getAtividadesByIdgrupotipos(parseInt(this.message[0].id_grupo_tipos, 10)).subscribe(
      (response: any) => {
        this.Localevento = response.horario;
        this.DescricaoCompletaEevento = response.grupo_tipo_descrisao
        this.HorarioEvento = response.horario;
        this.userProfile = response.img_evento

        console.log(response)


        const baseUrl = 'https://api.recarrega.app.br/'

        if (this.userProfile) {
          this.userProfile = baseUrl + '/uploads/' + this.userProfile;
        } else {
          this.userProfile = baseUrl + 'images/RsTWs8h7cu3ChOQZefJSpz35ej6CccUgZ10uGe72.png';

        }


      },
      (error) => {
        console.error(error);
      }
    );

    var array_id_menssagens = this.groups.find((group: any) => group.id === id);
    if (array_id_menssagens) {
      // Filtrar mensagens não lidas
      const unreadMessages = array_id_menssagens.messages.filter((message: any) => !message.is_read);
      // Obter os IDs das mensagens não lidas
      const unreadMessageIds = unreadMessages.map((message: any) => message.id);
      this.markMessagesAsRead(unreadMessageIds)
      // Agora você tem os IDs das mensagens não lidas em unreadMessageIds
      // console.log('IDs das mensagens não lidas:', unreadMessageIds);

    }

  }

  separateMessages(messages: any[]): any {
    const today = new Date();
    const messagesToday = [];
    const messagesYesterday = [];

    messages.forEach((message) => {
      const messageTime = new Date(message.time);

      if (messageTime >= today) {
        messagesToday.push(message);
      } else {
        messagesYesterday.push(message);
      }
    });

    if (messagesToday.length > 0 && messagesYesterday.length > 0) {
      messagesYesterday.push({ isToday: true });
    }

    messagesToday.sort((a, b) => a.id - b.id);
    messagesYesterday.sort((a, b) => a.id - b.id);


    // Concatenar as mensagens de ontem após as mensagens de hoje
    const mergedMessages = [...messagesYesterday, ...messagesToday];
    return mergedMessages;
  }


  markMessagesAsRead(messageIds: number[]) {
    this.forumService.markMessagesAsRead(messageIds).subscribe(
      (response) => {
        // console.log(response);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
   * Open add group modal
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openGroupModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  // Group Search
  GroupSearch() {
    var input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
    input = document.getElementById("searchGroup") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.querySelectorAll(".group-list");
    ul.forEach((item: any) => {
      li = item.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("h5")[0];
        txtValue = a?.innerText;
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    })
  }

  /**
 * Open lightbox
 */
  openImage(index: number, i: number): void {
    // open lightbox
    this.lightbox.open(this.message[index].imageContent, i, {
      showZoom: true
    });
  }



  isImage(url: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Adicione as extensões de imagem suportadas aqui

    // Obter a extensão do arquivo a partir da URL
    const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase();

    return imageExtensions.includes(extension);
  }

  getFileExtension(url: string): string {
    const extension = url.split('.').pop();
    return extension !== undefined ? extension.toLowerCase() : '';
  }

  async sendMessageToServer(message: string) {
    try {
      const success = await this.socketService.sendMessage(message);
      if (success) {
        // console.log('Mensagem enviada com sucesso!');
      } else {
        console.error('Falha ao enviar mensagem.');
      }
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  }
  irParaTelamodulosIndex() {
    this.router.navigate(['/modulos/index']);
  }

  getUsersByForum(id_forum: number) {
    this.forumService.getUsersByForum(id_forum).subscribe(
      response => {

        this.listaUsuarios = response
        this.quantidadeUsuarios = this.listaUsuarios.length;

      },
      error => {
        // Lidar com erros, se ocorrerem
        console.error(error);
      }
    );
  }

  /**
 * Formata um nome convertendo a primeira letra de cada palavra em maiúscula
 * e o restante das letras em minúscula.
 * @param name O nome a ser formatado.
 * @returns O nome formatado.
 */
  formatName(name: string): string {
    // Converte todo o nome para minúsculas,
    // divide-o em palavras, formata cada palavra
    // e junta novamente as palavras formatadas.
    return name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  processarMensagem(mensagem: string): string {
    // Expressão regular para encontrar links
    const pattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=_|!:,.;]*[-A-Z0-9+&@#/%=_|])/ig;
    
    // Substituir cada link encontrado por uma tag de link
    return mensagem.replace(pattern, '<a class= "link" href="$1" target="_blank">$1</a>');
  }


}
