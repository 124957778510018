import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { CadastroService } from 'src/services/Cadastrar-User.service.';
import { DataService } from 'src/services/dataService';
import { take } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { opcoesGenero } from '../../../services/genero';


@Component({
  selector: 'app-atualizar-cadastro',
  templateUrl: './atualizar-cadastro.component.html',
  styleUrls: ['../cadastrar-usuario/cadastrar-usuario.component.css']
})
export class AtualizarCadastroComponent {
  opcoesGenero = opcoesGenero;
  minDate: NgbDateStruct;

  idusuario: string;
  idade: string;
  celular: string;
  genero: string;

  showConfirmPassword: boolean = false;
  showIdadeError: boolean = false;

  IdadeErrorMessage :  string = '';
  nomeErrorMessage: string = '';

  isLoading: boolean = false; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao
  public loginForm: FormGroup;
  public submitted = false;


  confirmPasswordErrorMessage: string = '';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private cadastroService: CadastroService,
  ) {

    
    const currentDate = new Date();

    // Calcule a data mínima
    this.minDate = { year: currentDate.getFullYear() - 100, month: 1, day: 1 };

    this.loginForm = this.formBuilder.group({
      nome: ['', Validators.required],
      genero: ['', Validators.required],
      celular: ['', Validators.required],
      idade: ['', Validators.required],
    });
  }

  irParaTelaHome() {
    this.router.navigate(['/login']);
  }

  resetErrors() {
    this.showConfirmPassword = false;
    this.showIdadeError=false;

  }

  onInput(field: string) {
    this.loginForm.get(field)?.setErrors(null);
  }

  onSubmit() {
    this.resetErrors();

    const celular = this.loginForm.get('celular')?.value;
    const idade = this.transformDateToString(this.loginForm.get('idade')?.value);
    const genero = this.loginForm.get('genero')?.value;

    const liberarPoridade = this.verificarIdade(idade);
    const estaMarcada = this.verificarCheckbox();
    if (estaMarcada) {
      console.log('A caixa de seleção está marcada.');
    } else {
      alert('É obrigatório aceitar o termos de uso.')
    }
    if (!celular || celular.trim() === '' || !idade || idade.trim() === '' || !genero || genero.trim() === '') {
      this.showConfirmPassword = true; // Aparece lá em baixo
      this.confirmPasswordErrorMessage = 'Todos os campos são obrigatórios';
      return;
    }

    if (liberarPoridade) {
      this.showIdadeError = true;
      this.IdadeErrorMessage = 'Iadade menor que 16 anos';
      return;
    }

    this.isLoading = true;


    this.cadastroService.atualizarUsuario(sessionStorage.getItem('userID'), idade,celular, genero).pipe(
      take(1) // Garante que a requisição será realizada apenas uma vez
    ).subscribe(
      (response: any) => {
        this.isLoading = false; // Finaliza o carregamento após a conclusão da requisição
        this.router.navigate(['/modulos/index']);
      },
      (error) => {
        console.error('Erro ao atualizar o usuário:', error);
        this.isLoading = false; // Finaliza o carregamento após a conclusão da requisição
      }
    );
  }
  transformDateToString(dateInfo: any): string {
    const day = dateInfo.day < 10 ? `0${dateInfo.day}` : dateInfo.day;
    const month = dateInfo.month < 10 ? `0${dateInfo.month}` : dateInfo.month;
  
    return `${day}-${month}-${dateInfo.year}`;
  }

  formatDateControlValue(dateInfo: any): string {
    if (dateInfo) {
      const day = dateInfo.day < 10 ? `0${dateInfo.day}` : dateInfo.day;
      const month = dateInfo.month < 10 ? `0${dateInfo.month}` : dateInfo.month;
      return `${day}/${month}/${dateInfo.year}`;
    }
    return '';
  }

  verificarIdade(dataNascimento: string) :boolean {
    // Divide a data em dia, mês e ano
    const partes = dataNascimento.split('-');
    const dia = parseInt(partes[0], 10);
    const mes = parseInt(partes[1], 10);
    const ano = parseInt(partes[2], 10);

    // Cria um objeto de data com a data de nascimento
    const dataNasc = new Date(ano, mes - 1, dia);

    // Cria um objeto de data com a data atual
    const dataAtual = new Date();

    // Calcula a diferença em milissegundos
    const diferencaEmMilissegundos = dataAtual.getTime() - dataNasc.getTime();

    // Converte a diferença em anos
    const diferencaEmAnos = Math.floor(diferencaEmMilissegundos / (365.25 * 24 * 60 * 60 * 1000));

    // Verifica se a pessoa tem menos de 14 anos
    return diferencaEmAnos < 16;

  }

  verificarCheckbox(): boolean {
    const checkbox = document.getElementById('termos-checkbox') as HTMLInputElement;
  
    return checkbox.checked;
  }
  
  
}




