import { Component } from '@angular/core';

@Component({
  selector: 'app-tecnologias-socias-sp',
  templateUrl: './tecnologias-socias-sp.component.html',
  styleUrls: ['./tecnologias-socias-sp.component.css']
})
export class TecnologiasSociasSpComponent {

  scrollToDiv(divId: string) {
    const targetDiv = document.getElementById(divId);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
