export const environment = {
  production: true
  , // Defina como true em ambiente de producao

  apiUrl: '',
  firebaseConfig: {
    apiKey: "AIzaSyCqALZnsibY05-PlF8mqcmEnzCMj0QolRA",
    authDomain: "recarregateste.firebaseapp.com",
    projectId: "recarregateste",
    storageBucket: "recarregateste.appspot.com",
    messagingSenderId: "404895852521",
    appId: "1:404895852521:web:f55ecca7fab83d61d74d78",
    measurementId: "G-F5B8FDFLSF",
    vapidKey:"BIWcbH2oac1U4ehkfHAxScvbMoTmxh-2CTUxiqS7DGolA8d1aa07HFSIpBUtCp_97iWu9IkPyvh1lCW-baj-wzs"
  },
};


if (environment.production) {
  environment.apiUrl = 'https://api.recarrega.app.br/api'; // URL da API para ambiente de producao
} else {
  environment.apiUrl = 'http://127.0.0.1:8000/api'; // URL da API para ambiente de desenvolvimento ou teste
}




