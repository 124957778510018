<div class="filtro-scroll">

  <div class="titulo">No Ceará.

    <p class="sub-titulo">O Recarrega
      atua principalmente junto a:</p>

  </div>

  <div class="container-quadros">
    <div class="quadrado_branco">
      <div class="texte-item">Organizações públicas e privadas</div>
    </div>
    <div class="quadrado_branco">
      <div class="texte-item">Instituições de ensino</div>
    </div>
    <div class="quadrado_branco">
      <div class="texte-item">Produtores rurais</div>
    </div>
    <div class="quadrado_branco">
      <div class="texte-item">Viveiristas</div>
    </div>
  </div>

</div>