<div *ngIf="showModal" class="modal-container" [ngClass]="modalPosition">
  <div class="custom-modal">
    <div class="modal-header">
      <img src="../../assets/fechar.svg" class="icon-fechar" (click)="fecharmodal()">
      <img
        [src]="getEventoSelecionado().img_evento ? (baseUploadUrl + getEventoSelecionado().img_evento) : (baseUploadUrl + 'images/RsTWs8h7cu3ChOQZefJSpz35ej6CccUgZ10uGe72.png')"
        class="header-image">
    </div>
    <div class="modal-content">
      <h1 class="titulo-text-modal"> {{ getEventoSelecionado()?.descricao }} </h1>
      <h2 class="text-modal"> {{ getEventoSelecionado()?.descricao_completa  }} </h2>
      <p class="text-conteudo">
        <span class="titulo-text-modal">{{ 'Data: ' }}</span>{{ formatarDataHora(getEventoSelecionado()?.horario).slice(0, 10)}}<br>
        <span class="titulo-text-modal">{{ 'Horário: ' }}</span>{{ getEventoSelecionado()?.horario.slice(11, 16)}}h<br>
        <span class="titulo-text-modal">{{ 'Local: ' }}</span>{{ getEventoSelecionado()?.local }}<br>
      </p>

    </div>
    <div class="modal-footer" (click)="onSubmit()">
      <div *ngIf="MinhdasAtividades" class="text-button">COLABORE COM O GRUPO</div>
      <div *ngIf="!MinhdasAtividades" class="text-button">QUERO ME INSCREVER</div>

      <button class="modal-button">
      </button>
    </div>

  </div>