import { ForumService } from "src/services/Forum_Service";
import {  Groups } from 'src/app/chat/chat-index/chat.model';
import { Observable } from "rxjs";


const chat = [
  {
      id: 1,
      name: null,
      profilePicture: null,
      status: null,
      lastMessage: null,
      time: null,
      isActive: true,
  //     messages: [
  //       {
  //         id: 1,
  //         message: 'chat.messages.1.message',
  //         name: 'chat.messages.1.name',
  //         profile: 'assets/images/users/avatar-4.jpg',
  //         time: 'chat.messages.1.time'
  //       },
  //       {
  //           id: 2,
  //           message: 'chat.messages.2.message',
  //           name: 'chat.messages.2.name',
  //           profile: 'assets/images/users/avatar-1.jpg',
  //           time: 'chat.messages.2.time',
  //           align: 'right'
  //       },
  //       {
  //           isToday: true
  //       },
  //       {
  //           id: 3,
  //           message: 'chat.messages.3.message',
  //           message2: 'chat.messages.3.message2',
  //           name: 'chat.messages.3.name',
  //           profile: 'assets/images/users/avatar-4.jpg',
  //           time: 'chat.messages.3.time',
  //       },
  //       {
  //           id: 4,
  //           message: 'chat.messages.4.message',
  //           name: 'chat.messages.4.name',
  //           profile: 'assets/images/users/avatar-1.jpg',
  //           time: 'chat.messages.4.time',
  //           align: 'right'
  //       },
  //       {
  //           id: 5,
  //           name: 'chat.messages.5.name',
  //           profile: 'assets/images/users/avatar-1.jpg',
  //           time: 'chat.messages.5.time',
  //           isimage: true,
  //           imageContent: [
  //             {id:'1',src:'assets/images/small/img-1.jpg',caption:'Image 4 caption here',thumb:'assets/images/small/img-1.jpg'},
  //           ]
  //       },
  //       {
  //           id: 6,
  //           name: 'chat.messages.6.name',
  //           profile: 'assets/images/users/avatar-1.jpg',
  //           time: 'chat.messages.6.time',
  //           align: 'right',
  //           isfile: true,
  //           fileContent: 'admin_v1.0.zip',
  //           fileSize: '12.5 MB'
  //       },
  //       {
  //         id: 1,
  //         message: '👍',
  //         name: 'chat.messages.1.name',
  //         profile: 'assets/images/users/avatar-4.jpg',
  //         time: 'chat.messages.1.time',
  //         replayName: "You",
  //         replaymsg: `chat.messages.1.message`,
  //         align: 'right',
  //       },
  //     ]
   },
]

// const groups = [
//   {
//       id: 1, name: 'chat.tabs.groups.list.general',
//       messages: [
//         {
//           id: 1,
//           message: 'chat.messages.26.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.26.time',
//           align: 'right'
//         },
//         {
//           id: 2,
//           name: 'chat.messages.5.name',
//           profile: 'assets/images/users/avatar-1.jpg',
//           time: 'chat.messages.5.time',
//           isimage: true,
//           imageContent: [
//               {id:'3',src:'assets/images/small/img-3.jpg',caption:'Image 3 caption here',thumb:'assets/images/small/img-3.jpg'},
//           ]
//         },
//         {
//           id: 3,
//           message: 'chat.messages.27.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.27.time',
//           align: 'right'
//         }
//       ]
//   },
//   {
//       id: 2, name: 'chat.tabs.groups.list.reporting',
//       unread: '+23',
//       messages: [
//         {
//           id: 1,
//           message: 'chat.messages.9.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.9.time'
//         },
//         {
//           isToday: true
//         },
//         {
//           id: 2,
//           name: 'chat.messages.5.name',
//           profile: 'assets/images/users/avatar-1.jpg',
//           time: 'chat.messages.5.time',
//           isimage: true,
//           imageContent: [
//             {id:'2',src:'assets/images/small/img-2.jpg',caption:'Image 2 caption here',thumb:'assets/images/small/img-2.jpg'},
//           ]
//         },
//         {
//           id: 3,
//           name: 'chat.messages.6.name',
//           profile: 'assets/images/users/avatar-1.jpg',
//           time: 'chat.messages.6.time',
//           align: 'right',
//           isfile: true,
//           fileContent: 'Minible-Vertical.zip',
//           fileSize: '12.5 MB'
//         },
//         {
//           id: 4,
//           message: 'chat.messages.12.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.12.time',
//           align: 'right'
//         },
//         {
//           id: 5,
//           message: 'chat.messages.10.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.10.time'
//         },
//       ]
//   },
//   {
//       id: 3, name: 'chat.tabs.groups.list.designers',
//       messages: [
//         {
//           id: 1,
//           message: 'chat.messages.14.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.14.time'
//         },
//         {
//           id: 2,
//           message: 'chat.messages.15.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.15.time',
//           align: 'right'
//         },
//       ]
//   },
//   {
//       id: 4, name: 'chat.tabs.groups.list.developer',
//       unread: 'New',
//       messages: [
//         {
//           id: 1,
//           message: 'chat.messages.16.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.16.time'
//         },
//         {
//           id: 2,
//           name: 'chat.messages.5.name',
//           profile: 'assets/images/users/avatar-1.jpg',
//           time: 'chat.messages.5.time',
//           isimage: true,
//           imageContent: [
//             {id:'4',src:'assets/images/small/img-4.jpg',caption:'Image 4 caption here',thumb:'assets/images/small/img-4.jpg'},
//             {id:'2',src:'assets/images/small/img-2.jpg',caption:'Image 2 caption here',thumb:'assets/images/small/img-2.jpg'}
//           ],
//           align: 'right'
//         },
//         {
//           id: 3,
//           message: 'chat.messages.17.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.17.time',
//           align: 'right'
//         },
//         {
//           id: 4,
//           message: 'chat.messages.19.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.19.time',
//         },
//       ]
//   },
//   {
//       id: 5, name: 'chat.tabs.groups.list.projectalpha',
//       messages: [
//         {
//           id: 1,
//           message: 'chat.messages.9.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.1.time'
//         },
//         {
//           id: 2,
//           name: 'chat.messages.5.name',
//           profile: 'assets/images/users/avatar-1.jpg',
//           time: 'chat.messages.5.time',
//           isimage: true,
//           imageContent: [
//             {id:'4',src:'assets/images/small/img-4.jpg',caption:'Image 4 caption here',thumb:'assets/images/small/img-4.jpg'},
//             {id:'6',src:'assets/images/small/img-6.jpg',caption:'Image 6 caption here',thumb:'assets/images/small/img-6.jpg'}
//           ]
//         },
//         {
//           id: 3,
//           message: 'chat.messages.10.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.10.time',
//           align: 'right'
//         },
//         {
//           id: 34,
//           message: 'chat.messages.11.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.11.time'
//         },
//       ]
//   },
//   {
//       id: 6, name: 'chat.tabs.groups.list.snacks',
//       messages: [
//         {
//           id: 1,
//           message: 'chat.messages.8.message',
//           name: 'chat.messages.1.name',
//           profile: 'assets/images/users/avatar-4.jpg',
//           time: 'chat.messages.1.time',
//           align: 'right'
//         },
//         {
//           id: 2,
//           name: 'chat.messages.5.name',
//           profile: 'assets/images/users/avatar-1.jpg',
//           time: 'chat.messages.5.time',
//           isimage: true,
//           imageContent: [
//             {id:'4',src:'assets/images/small/img-4.jpg',caption:'Image 4 caption here',thumb:'assets/images/small/img-4.jpg'},
//             {id:'5',src:'assets/images/small/img-5.jpg',caption:'Image 5 caption here',thumb:'assets/images/small/img-5.jpg'}
//           ]
//         },
//       ]
//   }
// ];

export function getData(forumService: ForumService): Observable<Groups[]> {
  return forumService.getConversationsInForum(this.user_id);
}

export const groups = (forumService: ForumService): Observable<Groups[]> => getData(forumService).pipe(
  // Faça qualquer processamento adicional ou filtragem necessária aqui
);

export { chat };
