import { Component } from '@angular/core';

@Component({
  selector: 'app-caire',
  templateUrl: './caire.component.html',
  styleUrls: ['./caire.component.css']
})
export class CaireComponent {

}
