import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patrocinadores',
  templateUrl: './patrocinadores.component.html',
  styleUrls: ['./patrocinadores.component.css']
})

export class PatrocinadoresComponent {

  constructor(private router:Router){

  }

  irParahomeSp(){
    this.router.navigate(['home_sp'])
  }

  irParahomeCe(){
    this.router.navigate(['home_ce'])
  }

}
