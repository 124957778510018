
<app-carregamento-padrao *ngIf="isLoading"></app-carregamento-padrao>


<div class="background">
  <div class="tudo">
  <div class="box-top">
    <!-- <div (click)="irParaTelaHomeInterna()" class="text-voltar">   
      < VOLTAR</div> -->

    <nav>
      <div class="img-name">
      <ng-container *ngIf="!isEditing ; else editFielImagem">
        <div [ngClass]="{'profile-padrao': !imageLoaded, 'profile': imageLoaded}">
          <label for="profileImageInput">
            <img [src]="userData?.foto_perfil" alt="Foto de Perfil" *ngIf="userData">

          </label>
        </div>
      </ng-container>
      <ng-template #editFielImagem>
        <div class="profile-padrao profile">
          <label for="profileImageInput">
            <img [src]="userData?.foto_perfil" alt="Foto de Perfil" *ngIf="userData">
            <div class="overlay" [style.display]="userData ? 'flex' : 'none'">
              <p>Toque para editar</p>
            </div>
            <input type="file" id="profileImageInput" style="display: none" (change)="onProfileImageSelected($event)" accept="image/*">
          </label>
        </div>
      </ng-template>
      <div class="box-text-top">
        <h1 class="text-box-top">{{ userData?.name }}</h1>
      </div>
    </div>
      <div>
        <img (click)="irParaTelaHomeInterna()" class="icon-fechar" src="../../assets/fechar.svg">
      </div>
    </nav>
   
  </div>

  <div class="box-bottom">
    <div class="formularios">
    <div class="info-field">
      <span class="field-name">E-mail:</span>
      <div class="field-value">
        {{ userData?.email }}
      </div>
    </div>

    <div class="info-field">
      <span class="field-name">Celular:</span>
      <div class="field-value">
        <ng-container *ngIf="!isEditing || editField !== 'numero_celular'; else editFieldNumeroCelular">
          {{ userData?.numero_celular }}
          <ng-container *ngIf="isEditing">
            <i class='fas fa-pen-square fa-2x edit_icon' (click)="startEditing('numero_celular')"></i>
          </ng-container>
        </ng-container>
        <ng-template #editFieldNumeroCelular>
          <input type="text" class="input_format" [(ngModel)]="userData.numero_celular" appMaskPhoneNumber>
          <i class='fas fa-check-circle fa-2x save_icon' (click)="saveEdit('numero_celular')"></i>
        </ng-template>
      </div>
    </div>

    <div class="info-field">
      <span class="field-name">Dt. Nascimento:</span>
      <div class="field-value">
        <ng-container *ngIf="!isEditing || editField !== 'idade'; else editFieldIdade">
          {{ userData?.idade }}
          <ng-container *ngIf="isEditing">
            <i class='fas fa-pen-square fa-2x edit_icon' (click)="startEditing('idade')"></i>
          </ng-container>
        </ng-container>
        <ng-template #editFieldIdade>
          <input (click)="d.toggle()" class="input" type="text" [(ngModel)]="idadeTemp" placeholder="DATA NASCIMENTO"
            ngbDatepicker #d="ngbDatepicker" [minDate]="minDate" [placement]="'bottom'" />
          <i (click)="d.toggle()" class="fa-regular fa-calendar-days custom-calendario" aria-hidden="true"></i>
          <i class='fas fa-check-circle fa-2x save_icon' (click)="saveEdit('idade')"></i>

        </ng-template>
      </div>
    </div>

    <div class="info-field">
      <span class="field-name">Gênero:</span>
      <div class="field-value">
        <ng-container *ngIf="!isEditing || editField !== 'genero'; else editFieldGenero">
          {{ userData?.genero }}
          <ng-container *ngIf="isEditing">
            <i class='fas fa-pen-square fa-2x edit_icon' (click)="startEditing('genero')"></i>
          </ng-container>
        </ng-container>
        <ng-template #editFieldGenero>
          <select class="input select-style" [(ngModel)]="userData.genero" id="genero">
            <option *ngFor="let opcao of opcoesGenero" [value]="opcao.value">{{ opcao.label }}</option>
          </select>          
          <i class='fas fa-check-circle fa-2x save_icon' (click)="saveEdit('genero')"></i>
        </ng-template>
      </div>
    </div>

    <div class="info-field">
      <div class="container-btn-edit" (click)="isEditing ? saveAllEdits() : toggleEdit()">
        <p class="btn-edit"> {{ isEditing ? 'SALVAR' : 'EDITAR' }}</p>

      </div>
      <div class="field-value"></div>
      <span class="field-name">&nbsp;</span>
      <span class="field-name">&nbsp;</span>
      <div class="field-value"></div>
    </div>

  </div>
  </div>
</div>
</div>

<app-menu-desk></app-menu-desk>
<app-menu></app-menu>
