<div (click)="onOutsideClick($event)" class="backgroud">
  <app-menu-desk></app-menu-desk>
  <app-menu></app-menu>
  <app-carregamento-padrao *ngIf="isLoading"></app-carregamento-padrao>


  <app-modal-notificaton [showModal]="showModalNotification" [DadosNotification]="NovasNotification"
    (closeModal)="closeModalNotification()"></app-modal-notificaton>


  <div class="overlay" *ngIf="mostrarModal">

    <app-modal-mural [showModalMural]="mostrarModal" [DadosMural]="DadosMural"
      (closeModal)="fecharModal()"></app-modal-mural>
  </div>

  <body *ngIf="!isLoading">
    <section class="box-top">
      <div class="content-box">
        <div class="">
          <img
            [src]="foto_perfil === null || foto_perfil === 'null' ? '../../assets/perfil_menor.png' : (baseUploadUrl + foto_perfil)"
            id="referencia_modal" alt="">
        </div>
        <div class="content-text">
          <p class="text title-box-top">Olá, {{nome}} </p>
          <div class="content-2">
            <br />
            <br />
          </div>
        </div>
        <div class="icon-2">
          <ng-container *ngIf="NotificationAtiva; else sinoativo">
            <img src="../../assets/sinoon.svg" (click)="OpenModalNotification(notification)" alt="">
          </ng-container>
          <ng-template #sinoativo>
            <img src="../../assets/SinoAtual.svg" alt="">
          </ng-template>
        </div>
      </div>
      <div class="box-bol-absolute">
        <div class="box-bol">
          <div class="bol">
            <div class="img-nov">
              <img src="../../assets/icone_novidades.png" alt="">
            </div>
            <div class="novidades">
              <p>MURAL</p>
            </div>
          </div>
        </div>
        <div (click)="irParaAtividades()" class="box-bol">
          <div class="bol-1">
            <div class="img-ativ">
              <img src="../../assets/icone_atividades.png" alt="">
            </div>
            <div class="atividades">
              <p>ATIVIDADES</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="box-down">
      <div class="checkbox-limit" *ngFor="let novidade of novidades; let i = index">
        <div (click)="abrirModal(novidade)" class="box-container" [ngStyle]="getStyle(novidade.tipo_id)"
          *ngIf="novidade.lida">
          <div class="box-limit">
            <h2 class="title text">{{ novidade.titulo }}</h2>
            <p class="text text-down">{{ novidade.descricao }}</p>
          </div>
        </div>
        <div class="checkbox-wrapper" *ngIf="novidade.lida">
          <p class="termos-checkbox">LIDO</p>
          <div class="checkbox-container">
            <input type="checkbox" [id]="'termos-checkbox-' + i" (click)="lerAtividades(novidade.id)">
            <label [for]="'termos-checkbox-' + i"></label>
          </div>
        </div>
      </div>
    </section>

    <section class="box-down">
      <div class="setas" id="ref_secao_lidas">
        <p class="seta-lidas">LIDAS ( {{numeroDeItensLidos}} )</p>
        <div class="seta-baixo"><img *ngIf="estado_botao" src="../../assets/setinha_para_baixo.svg" alt=""
            (click)="toggleEstadoBotao()" /></div>
        <div (click)="scrollToDiv('ref_secao_lidas')" class="seta-lado"><img *ngIf="!estado_botao"
            src="../../assets/setinha_para_lado.svg" alt="" (click)="toggleEstadoBotao()" /></div>
      </div>
      <div class="checkbox-limit-lido" [ngStyle]="{ 'display': estado_botao ? '' : 'none' }"
        *ngFor="let novidade of novidades; let i = index">
        <div (click)="abrirModal(novidade)" class="box-container" [ngStyle]="getStyle(novidade.tipo_id)"
          *ngIf="!novidade.lida">
          <div class="box-limit">
            <h2 class="title text">{{ novidade.titulo }}</h2>
            <p class="text text-down">{{ novidade.descricao }}</p>
          </div>
        </div>
        <div class="checkbox-wrapper" *ngIf="!novidade.lida">
          <p class="termos-checkbox">DEL.</p>
          <div class="checkbox-container">
            <input type="checkbox" [id]="'termos-checkbox-' + i" (click)="deleteNovidade(novidade.lida_id)">
            <label [for]="'termos-checkbox-' + i"></label>
          </div>
        </div>
      </div>
    </section>
    <br>
    <br>
    <br>
    <br>
  </body>
</div>