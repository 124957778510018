import { Component, ElementRef } from '@angular/core';
import { Atividades_service } from 'src/services/Atividades.service';
import { DataService } from 'src/services/dataService';
import { DatePipe } from '@angular/common';


interface Cidade {
  id: number;
  nome: string;
  temas: { id: number; nomeTema: string }[];
}

interface Atividade {
  id_Atividade: number;
  id_tema: number;
  descricao: string;
  horario: string;
  descricao_completa: string,
  local: string,
  cidade_id: number,
}

@Component({
  selector: 'app-atividades',
  templateUrl: './atividades.component.html',
  styleUrls: ['./atividades.component.css']
})
export class AtividadesComponent {
  horarioFormatado: string;
  cidadeSelecionada: number = 1;
  temaSelecionado: number | null = null;
  eventosRecentes: Atividade[] = [];
  cidadesMockadas: any[] = []; // Vamos buscar as cidades do serviço agora
  eventos: Atividade[] = [];
  baseUploadUrl = 'https://api.recarrega.app.br/uploads/';
  uploadCaminho(caminho: string) {
    const caminhoCompleto = this.baseUploadUrl + caminho;
  }
  constructor(private atividadesService: Atividades_service,
    private dataservice: DataService,
    private elementRef: ElementRef,
    private datePipe: DatePipe) { }


  ngOnInit() {
    // Definir o primeiro ID como valor inicial selecionado

    this.atividadesService.getAtividades().subscribe(
      (data: Atividade[]) => {
        this.eventos = data;

        // Chame a função para carregar os eventos iniciais após obter os dados
        this.carregarTemaInicial();
      },
      (error) => {
        console.error('Erro ao buscar eventos:', error);
      }
    );

    this.atividadesService.getCidadeTema().subscribe(
      (data: any) => {
        this.cidadesMockadas = data;
        console.log(this.cidadesMockadas[0].id)
        this.cidadeSelecionada = this.cidadesMockadas[0].id;

        // Carregar o tema inicial
        this.carregarTemaInicial();
      },
      (error) => {
        console.error('Erro ao buscar cidades e temas:', error);
      }
    );

    this.atividadesService.getAtividades().subscribe(
      (data: Atividade[]) => {
        this.eventos = data;
        // Call the method to load initial events after getting the data

        this.carregarTemaInicial();
      },
      (error) => {
        console.error('Erro ao buscar eventos:', error);
      }
    );

  }

  formatarDataHora(dataHora: string): string {
    const dataHoraObj = new Date(dataHora);
    return this.datePipe.transform(dataHoraObj, 'dd/MM/yyyy HH:mm');
  }

  carregarTemaInicial() {
    const temas = this.getTemasCidadeSelecionada();
    if (temas.length > 0) {
      this.temaSelecionado = temas[0].id;
      this.carregarEventosRecentes();
    } else {
      this.temaSelecionado = null;
      this.eventosRecentes = [];
    }
  }

  getTemasCidadeSelecionada(): { id: number; nomeTema: string }[] {
    const cidadeSelecionada = this.cidadesMockadas.find(
      (cidade) => cidade.id == this.cidadeSelecionada
    );

    return cidadeSelecionada ? cidadeSelecionada.temas : [];
  }

  selecionarTema(tema: { id: number; nomeTema: string }) {
    this.temaSelecionado = tema.id;
    this.carregarEventosRecentes();
  }

  carregarEventosRecentes() {
    const eventos = this.getEventosRecentes();
    this.eventosRecentes = eventos.slice(0, 4);
  }

  getEventosRecentes() {
    const eventosFiltrados = this.eventos.filter((evento) => evento.id_tema == this.temaSelecionado && evento.cidade_id == this.cidadeSelecionada) || [];

    // Logica oara ordenar as atividades do para vim do mais recente em cima pro ususarios
    eventosFiltrados.sort((a, b) => {
      const horarioA = new Date(b.horario).getTime();
      const horarioB = new Date(a.horario).getTime();

      // Comparando os timestamps
      return horarioB - horarioA;
    });

    const eventosRecentes = eventosFiltrados.slice(0, 3);

    //console.log(eventosRecentes);
    return eventosRecentes;


  }

  /*Modal atividades*/
  showModal: boolean = false;
  id_Atividades: number;

  openModal(idAtividade: number) {
    console.log(idAtividade)
    this.id_Atividades = idAtividade;
    this.dataservice.setAtividadeId(this.id_Atividades); // Define o valor do ID usando o serviço compartilhado
    this.showModal = true;
    this.scrollToDiv('ref_modal')
  }

  closeModal() {
    this.showModal = false;
    this.id_Atividades = null;
    this.dataservice.Resetclick();

  }

  onOutsideClick(event: MouseEvent): void {
    const modalContent = this.elementRef.nativeElement.querySelector('.modal-container');

    if (modalContent != null) {

      if (this.showModal && this.dataservice.clickCount >= 1) {
        const clickedElement = event.target as HTMLElement;

        if (clickedElement !== modalContent && !(modalContent as HTMLElement).contains(clickedElement)) {
          console.log('resetando contangem')
          this.closeModal();
        }

      } else {
        this.dataservice.incrementClick();
      }
    }
  }

  scrollToDiv(divId: string) {
    const targetDiv = document.getElementById(divId);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onRequisitarNovamente() {
    // Coloque aqui a lógica para fazer a nova requisição
    // this.Carrega_cidade_tema_full();
    console.log('Evento recebido para requisitar novamente');
    // ...
  }
  irParaNovidades() {
    // this.router.navigate(['/modulos/novidades']);
  }
}
