<div *ngIf="showModalMural" class="modal-container">
    <div class="custom-modal" [ngStyle]="getStyle(getDadosMural()?.tipo_id)">
      <div class="modal-header">
        {{getDadosMural()?.titulo}}
        <img src="../../assets/fechar.svg" class="icon-fechar" (click)="fecharmodal()">
      </div>
      <div class="modal-text-conteudo">
        <div class="text-conteudo">
           
           {{getDadosMural()?.descricao_completa}}
  
          
        </div>
        
      </div>
    </div>
  
  </div>