import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/services/Auth_service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao
  public loginForm: FormGroup;
  public submitted = false;


  passwordErrorMessage: string = '';
  showEmailError: boolean = false;
  showPasswordError: boolean = false;

  emailErrorMessage: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      if (token) {
        sessionStorage.setItem('token', token);
        const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 30 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
        document.cookie = `token=${token}; expires=${new Date(Date.now() + oneMonthInMilliseconds).toUTCString()}; path=/`;
        this.router.navigate(['/modulos/index']);
      } else {
        // Lógica específica para autenticação usando o Google
      }

    });

    const tokenCache = this.getCookie('token');
    if (tokenCache) {
      sessionStorage.setItem('token', tokenCache);
      this.router.navigate(['/modulos/index']);

    }
    else {
      // O usuário não está autenticado
    }

  }

  getCookie(name: string): string | null {
    const cookies: string[] = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie: string = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  irParaTelaHome() {
    this.router.navigate(['/home']);
  }
  irParaTelaCadastroUser() {
    this.router.navigate(['/cadastro_usuario']);
  }
  irParaAlterasenha() {
    this.router.navigate(['/email']);
  }

  inputEmail() {
    this.showEmailError = false;
    this.emailErrorMessage = '';
  }

  inputPassword() {
    this.showPasswordError = false;
    this.passwordErrorMessage = '';
  }

  onSubmit() {
    const email = this.loginForm.get('email')?.value;
    const password = this.loginForm.get('password')?.value;

    if (!email || email.trim() === '' || !this.validateEmail(email)) {
      this.showEmailError = true;
      this.emailErrorMessage = 'E-mail inválido ou em branco';
      return;
    }

    if (!password || password.trim() === '') {
      this.showPasswordError = true;
      this.passwordErrorMessage = 'Senha inválida ou em branco';
      return;
    }

    // Verifica se o cookie com o token existe
    this.isLoading = true;

    this.authService.login(email, password).subscribe(
      (response: any) => {
        // Armazena o token na sessionStorage
        sessionStorage.setItem('token', response.access_token);
        const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 30 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
        document.cookie = `token=${response.access_token}; expires=${new Date(Date.now() + oneMonthInMilliseconds).toUTCString()}; path=/`;
       // Redireciona para a rota desejada
        this.isLoading = false;
        this.router.navigate(['/modulos/index']);
      },
      (error) => {
        // Logic for login error


        const errorMessage = error.error.message;
        // console.log('Mensagem de erro:', errorMessage);

        this.isLoading = false;
        if (error.error.message == 'E-mail não foi validado') {
          this.showEmailError = true;
          this.emailErrorMessage = 'E-mail não foi validado';
          return;

        }
        // Caso nao haja problema com o e-mail.
        this.showPasswordError = true;
        this.passwordErrorMessage = 'Erro ao fazer login. Verifique suas credenciais.';

      }
    );


  }

  validateEmail(email: string): boolean {
    // Implemente a lógica de validação do e-mail aqui
    // Pode usar expressões regulares ou outras técnicas de validação
    // Neste exemplo, verifica apenas se contém um '@' no e-mail
    return email.includes('@');
  }

  loginWithGoogle() {
    this.authService.loginWithGoogle();
  }


}
