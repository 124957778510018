import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AtualizarCadastroComponent } from 'src/app/cadastro-usuario/atualizar-cadastro/atualizar-cadastro.component';
import { CadastroService } from 'src/services/Cadastrar-User.service.';
import { UserService } from 'src/services/user.service';
import { take } from 'rxjs/operators';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { opcoesGenero } from '../../../services/genero';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent {
  opcoesGenero = opcoesGenero;

  userData: any;
  isLoading: boolean = false; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao
  imageLoaded = false;
  idadeTemp: string; // Propriedade temporária para usar com ngModel
  minDate: NgbDateStruct;


  isEditing = false;
  editField: string | null = null;
  editedFields: { [fieldName: string]: boolean } = {};
  profileImageFile: File | null = null;


  constructor(
    private userService: UserService,
    private router: Router,
    private atualizarUsuario: CadastroService,
    private ngbDateParserFormatter: NgbDateParserFormatter) {
   
      const currentDate = new Date();
    // Calcule a data mínima
    this.minDate = { year: currentDate.getFullYear() - 100, month: 1, day: 1 };

  }

  ngOnInit(): void {
    const token = sessionStorage.getItem('token');
    this.getUserInfo(token);
  }

  getUserInfo(token: string): void {
    this.isLoading = true;

    this.userService.getUserData(token).subscribe(
      (data) => {
        // Os dados do usuário serão retornados aqui
        this.userData = data;
        this.imageLoaded = true;
        // Verificar se a propriedade foto_perfil está presente, vazia ou nula nos dados
        this.isLoading = false;

        if (!this.userData.foto_perfil || this.userData.foto_perfil.trim() === '') {
          this.userData.foto_perfil = '../../assets/editar_perfil.svg';
          this.imageLoaded = false;
        } else {
          // Concatenar a URL da imagem apenas se a propriedade foto_perfil não estiver vazia
          // this.userData.foto_perfil = 'http://127.0.0.1:8000/uploads/' + this.userData.foto_perfil; // para desenvolvimento
          this.userData.foto_perfil = 'https://api.recarrega.app.br/uploads/' + this.userData.foto_perfil;

        }

      },
      (error) => {
        this.isLoading = false;
        console.error('Erro ao obter dados do usuário:', error);
      }
    );
  }

  irParaTelaHomeInterna() {
    this.router.navigate(['/login']);
  }


  startEditing(fieldName: string): void {
    this.isEditing = true;
    this.editField = fieldName;
  }

  saveEdit(fieldName: string): void {
    this.editField = null;
    this.editedFields[fieldName] = true;

    if(fieldName ==='idade'){
      console.log("convertendo data");
      this.userData.idade = this.transformDateToString(this.idadeTemp)
    }
  }


  toggleEdit(): void {
    this.isEditing = !this.isEditing;
    this.editField = null;
  }

  saveAllEdits(): void {
    const editedData = new FormData();

    const fieldsToCheck = ['email', 'numero_celular', 'idade', 'genero', 'foto_perfil'];
    fieldsToCheck.forEach(fieldName => {
      if (this.editedFields[fieldName]) {
        if (fieldName === 'foto_perfil') {
          editedData.append(fieldName, this.profileImageFile);
        } else {
          editedData.append(fieldName, this.userData[fieldName]);
        }
      }
    });

    console.log(editedData)
    this.atualizarUsuario.atualizarUsuarioWithfoto(sessionStorage.getItem('userID'), editedData).pipe(
      take(1)
    ).subscribe(
      (response: any) => {
        console.log('Usuário atualizado com sucesso:', response);
        this.isLoading = false;
        this.router.navigate(['/modulos/index']);
      },
      (error) => {
        console.error('Erro ao atualizar o usuário:', error);
        this.isLoading = false;
      }
    );
  }


  onProfileImageSelected(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    this.imageLoaded = true;

    reader.onload = () => {
      // Definir a imagem carregada pelo usuário como a imagem de perfil
      this.userData.foto_perfil = reader.result as string;
    };

    console.log('aqui')

    if (file) {
      reader.readAsDataURL(file);
      this.profileImageFile = file;
      this.saveEdit('foto_perfil');
    }
  }



  transformDateToString(dateInfo: any): string {
    const day = dateInfo.day < 10 ? `0${dateInfo.day}` : dateInfo.day;
    const month = dateInfo.month < 10 ? `0${dateInfo.month}` : dateInfo.month;

    return `${day}-${month}-${dateInfo.year}`;
  }
}





