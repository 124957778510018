import { Component } from '@angular/core';

@Component({
  selector: 'app-carregamento-padrao',
  templateUrl: './carregamento-padrao.component.html',
  styleUrls: ['./carregamento-padrao.component.css']
})
export class CarregamentoPadraoComponent {

}
