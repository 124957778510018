import { Component, OnInit, Renderer2, ElementRef, ViewChild, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap'; // Importe o NgbCarousel
import 'hammerjs';


@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.css']
})
export class TesteComponent implements OnInit {
  selectedFile: File;
  pages: any = [];
  convertedText: string;
  isWebShareSupported: boolean;

  @ViewChild('carousel', { static: false }) carousel: NgbCarousel; // Obtenha a referência ao carrossel

  constructor(
    private renderer: Renderer2, private el: ElementRef, private sanitizer: DomSanitizer) { }

  ngAfterViewInit() {
    // Aplica a classe aos indicadores após o conteúdo ser renderizado

    this.adicionarClasseAoH1();
    this.addSizeClassToImages();

  }




  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    // Registre a posição inicial do toque
    const touchStartX = event.touches[0].clientX;
    this.el.nativeElement.setAttribute('data-touchstart-x', touchStartX.toString());
  }

  @HostListener('document:touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    // Compare a posição inicial e final para determinar o deslizamento
    const touchEndX = event.changedTouches[0].clientX;
    const touchStartX = parseFloat(this.el.nativeElement.getAttribute('data-touchstart-x'));

    if (touchStartX < touchEndX) {
      this.detectSwipe('right');
    } else if (touchStartX > touchEndX) {
      this.detectSwipe('left');
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    // Registre a posição inicial do mouse
    this.el.nativeElement.setAttribute('data-mousestart-x', event.clientX.toString());
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    // Compare a posição inicial e final do mouse para determinar o deslizamento
    const mouseEndX = event.clientX;
    const mouseStartX = parseFloat(this.el.nativeElement.getAttribute('data-mousestart-x'));

    if (mouseStartX < mouseEndX) {
      this.detectSwipe('right');
    } else if (mouseStartX > mouseEndX) {
      this.detectSwipe('left');
    }
  }

  detectSwipe(direction: string) {
    // Função para lidar com o deslizamento (swipe)

    if (direction === 'left') {
      this.carousel.next(); // Avançar para o próximo slide
    } else if (direction === 'right') {
      this.carousel.prev(); // Voltar para o slide anterior
    }
  }

  adicionarClasseAoH1() {
    const elements = document.querySelectorAll('.text h1');
    elements.forEach((element) => {
      element.classList.add('text-h1');
    });
  }

  addSizeClassToImages() {
    const imgElements = this.el.nativeElement.querySelectorAll('img');
    imgElements.forEach((imgElement) => {
      const alt = imgElement.getAttribute('alt');
      if (alt) {
        if (alt.includes('pequena')) {
          this.renderer.addClass(imgElement, 'img-pequena');
        } else if (alt.includes('media')) {
          this.renderer.addClass(imgElement, 'img-media');
        } else if (alt.includes('grande')) {
          this.renderer.addClass(imgElement, 'img-grande');
        }
      }
    });
  }

   addFixedStyleToClass(className) {
    const elements = document.querySelectorAll('.' + className);
  
    elements.forEach((element) => {
      if (element instanceof HTMLElement) {
        console.log('add estilo')
        element.style.position = 'fixed';
      }
    });
  }
  
  // Chame a função com o nome da classe que você deseja estilizar
  
  
  // Chame a função com o nome da classe que você deseja estilizar


  splitContentIntoPages(content: string) {
    // Divide o conteúdo em páginas com base na marcação <!-- Nova Página -->
    const pagesArray = content.split('<!-- Nova Página -->');

    for (let i = 0; i < pagesArray.length; i++) {
      let pageContent = pagesArray[i].trim();

      // Verifica se o conteúdo da página contém uma tag iframe
      const iframeRegex = /<iframe[^>]*src=["'](https?:\/\/[^"']+)["'][^>]*>/i;

      const tamanhoDaImagem = this.getTamanhoDaImagem(pageContent);
      const tamanhoClass = tamanhoDaImagem !== 'desconhecido' ? `imagem-${tamanhoDaImagem}` : '';

      if (pageContent) { // Verifica se o conteúdo da página não está vazio
        this.pages.push({
          content: this.sanitizer.bypassSecurityTrustHtml(pageContent),
          legend: `Página ${this.pages.length + 1}`, // Pode personalizar as legendas aqui
          tamanhoClass: tamanhoClass,
        });
      }
    }
    console.log(this.pages);
  }


  getTamanhoDaImagem(html: string): string {
    if (html.includes('classe="grande"')) {
      return 'grande';
    } else if (html.includes('classe="media"')) {
      return 'media';
    } else if (html.includes('classe="pequena"')) {
      return 'pequena';
    } else {
      return 'desconhecido'; // Se nenhuma classe for encontrada
    }
  }

  processConvertedText(html: string) {
    // Verifica se o HTML contém um iframe
    const iframeRegex = /<iframe[^>]*src=["'](https?:\/\/[^"']+)["'][^>]*>/i;
    const match = iframeRegex.exec(html);

    if (match) {
      const iframeUrl = match[1];
      this.pages[0].videoUrl = iframeUrl; // Adiciona o URL do iframe à primeira página
    }
  }

  DadosMural: any[] = [];

  mostrarModal = false; // Inicialmente, o modal está oculto
  dadosDoMural: any[]; // Aqui você pode definir os dados a serem passados para o modal
  
  abrirModal() {
    this.mostrarModal = true;
    // Defina os dados a serem passados para o modal em this.dadosDoMural, se necessário
  }
  
  fecharModal() {
    this.mostrarModal = false;
  }

  ngOnInit() {
    // Verifica se a API navigator.share está disponível
    if (navigator.share) {
      this.isWebShareSupported = true;
    } else {
    }
  }

  // openModal(button: number): void {
  //   console.log('abrindo modal');
  //   this.showModal = true;
  //   this.selectedButton = button;
  //   this.clickCount = 0;

  //   // Adicionar evento de clique fora do modal
  //   this.ngZone.runOutsideAngular(() => {
  //     document.addEventListener('click', this.onDocumentClick);
  //   });
  // }

  compartilharLink(url,titulo,text) {
    if (navigator.share) {
      navigator.share({
        title: titulo,
        text: text,
        url:  'https://www.recarrega.app.br/model/novidades/'+url,
      })
        .then(() => console.log('Conteúdo compartilhado com sucesso!'))
        .catch((error) => console.error('Erro ao compartilhar conteúdo:', error));
    }
  }
  

}

