
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private apiUrl: string;
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl;
    }
    private token = sessionStorage.getItem('token'); // Obtém o token de autorização da sessionStorage

    getUserNotification() {
        const url = `${this.apiUrl}/index_notifications`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
        return this.http.get(url, { headers });

    }

    updateNotification(id: number, data: any): Observable<any> {
        const url = `${this.apiUrl}/index_notifications/${id}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
        
        return this.http.put(url, data, { headers });
      }
}