import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const token = sessionStorage.getItem('token');

    if (token) {
      // O usuário tem um token válido na sessionStorage, permite o acesso à rota
      return true;
    } else {
      // O usuário não tem um token válido na sessionStorage, redireciona para o componente de login
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
