<div class="container-title background-color">
  <h1 class="title1">Hortas e pomares</h1>
  <!-- <img class="seta" src="./assets/fechar.svg" /> -->
</div>

<section class="box-down">
  <div class="checkbox-limit">
    <div class="box-container">
      <div class="box-limit">
        <h2 class="title-text">Escola Pública Estadual Professor Almeida Junior</h2>
      </div>
    </div>
    <img *ngIf="isWebShareSupported"  (click)="compartilharLink('1','Hortas e pomares','')" class="group-icon1" alt="" src="./assets/group.svg" />
  </div>
  <div class="checkbox-limit">
    <div class="box-container">
      <div class="box-limit">
        <h2 class="title-text">Escola Pública Estadual Professor Almeida Junior</h2>
      </div>
    </div>
    <img class="group-icon1" alt="" src="./assets/group.svg" />
  </div>
  <div class="checkbox-limit">
    <div class="box-container">
      <div class="box-limit">
        <h2 class="title-text">Escola Pública Estadual Professor Almeida Junior</h2>
      </div>
    </div>
    <img class="group-icon1" alt="" src="./assets/group.svg" />
  </div>
  <div class="checkbox-limit">
    <div class="box-container">
      <div class="box-limit">
        <h2 class="title-text">Escola Pública Estadual Professor Almeida Junior</h2>
      </div>
    </div>
    <img class="group-icon1" alt="" src="./assets/group.svg" />
  </div>
  <div class="checkbox-limit">
    <div class="box-container">
      <div class="box-limit">
        <h2 class="title-text">Escola Pública Estadual Professor Almeida Junior</h2>
      </div>
    </div>
    <img class="group-icon1" alt="" src="./assets/group.svg" />
  </div>
  <div class="checkbox-limit">
    <div class="box-container">
      <div class="box-limit">
        <h2 class="title-text">Escola Pública Estadual Professor Almeida Junior</h2>
      </div>
    </div>
    <img class="group-icon1" alt="" src="./assets/group.svg" />
  </div>
  <div class="checkbox-limit">
    <div class="box-container">
      <div class="box-limit">
        <h2 class="title-text">Escola Pública Estadual Professor Almeida Junior</h2>
      </div>
    </div>
    <img class="group-icon1" alt="" src="./assets/group.svg" />
  </div>
</section>

<div class="agir-ambiental-todos">
  <p class="text-agir">© 2023 Agir Ambiental, Todos os direitos reservados</p>
</div>