import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rota-home',
  templateUrl: './rota-home.component.html',
  styleUrls: ['./rota-home.component.css']
})
export class RotaHomeComponent {


  constructor(private router:Router){

  }

  irParahomeSp(){
    this.router.navigate(['home_sp'])
  }

  irParahomeCe(){
    this.router.navigate(['home_ce'])
  }
}
