const contacts = [
    { id: 1, name: 'chat.tabs.contacts.list.A.name' },
    { id: 2, name: 'chat.tabs.contacts.list.A.name2' },
    { id: 3, name: 'chat.tabs.contacts.list.C.name' },
    { id: 4, name: 'chat.tabs.contacts.list.D.name' },
    { id: 5, name: 'chat.tabs.contacts.list.D.name2' },
    { id: 6, name: 'chat.tabs.contacts.list.I.name' },
    { id: 7, name: 'chat.tabs.contacts.list.J.name' },
    { id: 8, name: 'chat.tabs.contacts.list.J.name2' },
    { id: 9, name: 'chat.tabs.contacts.list.J.name3' },
    { id: 10, name: 'chat.tabs.contacts.list.M.name' },
    { id: 11, name: 'chat.tabs.contacts.list.M.name2' },
    { id: 12, name: 'chat.tabs.contacts.list.M.name3' },
    { id: 13, name: 'chat.tabs.contacts.list.O.name' },
    { id: 14, name: 'chat.tabs.contacts.list.P.name' },
    { id: 15, name: 'chat.tabs.contacts.list.P.name2' },
    { id: 16, name: 'chat.tabs.contacts.list.R.name' },
    { id: 17, name: 'chat.tabs.contacts.list.S.name' },
    { id: 18, name: 'chat.tabs.contacts.list.S.name2' },
    { id: 19, name: 'chat.tabs.contacts.list.S.name3' },
    { id: 20, name: 'chat.tabs.contacts.list.H.name' },
];

export { contacts };
