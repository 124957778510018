// data.service.ts
// Servico para compartilhar variaveis entre componentes
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  private email: string = '';

  setEmail(email: string) {
    this.email = email;
  }

  getEmail(): string {
    return this.email;
  }

  private atividadeIdSource = new BehaviorSubject<number | null>(null);
  atividadeId$ = this.atividadeIdSource.asObservable();
  setAtividadeId(id: number) {
    this.atividadeIdSource.next(id);
  }

  clickCount = 0;



  Resetclick() {
    this.clickCount = 0;
    // console.log('Zerando clickCount: ' + this.clickCount);
  }

  incrementClick() {
    this.clickCount++;
    // console.log('Incrementando clickCount: ' + this.clickCount);
  }
}
