<div (click)="onOutsideClick($event)">

<select class="estilo-select" id="ref_modal" [(ngModel)]="cidadeSelecionada" (change)="carregarTemaInicial()">
  <option class='text-select' *ngFor="let cidade of cidadesMockadas" [value]="cidade.id">
    {{ cidade.nome }}
  </option>
</select>


<div class="filtro-scroll">
  <div class="filtro-atividades">
    <div class="categorias-atividades">
      <button *ngFor="let tema of getTemasCidadeSelecionada()" class="btn-bolinha"
        [ngClass]="{'categoria-atividade-selecionada': tema.id == temaSelecionado}" (click)="selecionarTema(tema)"  (clicl)="getEventosRecentes()">
        <img class="filtro-bolinhas-icon" alt="" src="./assets/filtro_bolinha_vazia.svg" />
        <p class="texto-bolinha"
          [ngClass]="{'texto-selecionado': tema.id !== temaSelecionado && temaSelecionado !== null}">{{ tema.nomeTema }}
        </p>
      </button>
    </div>
  </div>
</div>
<app-modal-atividades-usuarios
[showModal]="showModal" 
[eventos]="eventos"
(FazerRequisicaoUsers)="onRequisitarNovamente()"
[modalPosition]="'default'"
 (closeModal)="closeModal()"></app-modal-atividades-usuarios>

<ng-container *ngFor="let evento of getEventosRecentes(); let i = index">
  <div (click)="openModal(evento.id_Atividade)" class="atividades-holder-{{ i % 3  }}">
    <div class="boto-61"></div>
    <div class="avatar-xs">
      <img class="img-evento" [src]="evento.img_evento ? (baseUploadUrl + evento.img_evento) : (baseUploadUrl + 'images/RsTWs8h7cu3ChOQZefJSpz35ej6CccUgZ10uGe72.png')">
    </div>
    <div class="text-conteiner-atividades">
      <div class="text-descricao" >{{ evento.descricao }}</div>
    <div class="horario">{{ formatarDataHora(evento.horario) }}h</div>
  </div>
</div>

</ng-container>

</div>