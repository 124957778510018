<div *ngIf="showModal" class="modal-container">
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="text-modal">{{ titulosPorNumero[selectedButton] }}</h2>
    </div>
    <div class="modal-body">
      <p1 class="text-conteudo">{{ conteudoPorNumero[selectedButton] }} </p1>
    </div>
    <div class="modal-footer">
      <!-- <button (click)="onCloseModal()">Fechar</button> -->
    </div>
  </div>
</div>