import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PwaInstallService } from 'src/services/pwa-install.service';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})


export class HomePageComponent implements OnInit {

  constructor(private router: Router,
    private elementRef: ElementRef,
    private pwaInstallService: PwaInstallService
  ) { }

  baixarApp: boolean;

  ngOnInit(): void {

    this.pwaInstallService.setupInstallBanner();
    const tokenCache = this.getCookie('token');
    if (tokenCache) {
      sessionStorage.setItem('token', tokenCache);
      this.router.navigate(['/modulos/index']);
    }
    else {
      // O usuário não está autenticado
    }
  }
  // Funcao para ancorar navegacao na home
  scrollToDiv(divId: string): void {
    const targetElement = this.elementRef.nativeElement.querySelector('#' + divId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  installPWA() {
    if (!this.pwaInstallService.isPWAInstalled()) {
      alert("Deseja baixar aplicativo para ter uma experiencia melhor ?")
      this.pwaInstallService.installPWA();
    }
    else {
      alert("Aplicativo ja instalado")
    }
  }

  irParaTelaLogin() {
    this.router.navigate(['/login']);
  }

  getCookie(name: string): string | null {
    const cookies: string[] = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie: string = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  irParaCadastro() {
    this.router.navigate(['/cadastro_usuario']);
  }

  /*Meotodo para abrir o modal de recarga-hdrica:
  O componente  possui um contador de cliques (`clickCount`) para rastrear os cliques fora do modal.
  Quando o modal estiver aberto e ocorrer um clique fora dele, o modal será fechado somente no segundo clique fora do modal,
  permitindo interações no primeiro clique. O contador é incrementado para rastrear o primeiro clique e resetado após o 
  segundo clique, garantindo o comportamento desejado.*/
  showModal: boolean = false;
  selectedButton: number | null = null;

  openModal(buttonNumber: number) {
    this.selectedButton = buttonNumber;
    this.showModal = true;
  }
  closeModal() {
    this.showModal = false;
    this.selectedButton = null;
  }
  private clickCount = 0;

  onOutsideClick(event: MouseEvent): void {
    console.log(this.clickCount);
    if (this.showModal && this.clickCount === 1) {
      const modalContent = this.elementRef.nativeElement.querySelector('.modal-content');
      const clickedElement = event.target as HTMLElement;

      if (clickedElement !== modalContent && !(modalContent as HTMLElement).contains(clickedElement)) {
        this.clickCount = 0;
        this.closeModal();
      }

    } else if (this.showModal) {
      this.clickCount++;
    }
  }

  

}
