import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mapeie',
  templateUrl: './mapeie.component.html',
  styleUrls: ['./mapeie.component.css']
})
export class MapeieComponent {
  constructor(private router: Router) {
  }
  irParaTelamodulosIndex() {
    this.router.navigate(['/modulos/index']);
  }
}
