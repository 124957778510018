import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MonitoreService } from 'src/services/monitore.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; // Importando FormBuilder e FormGroup
import { DbPwaService } from 'src/services/db-pwa.service';

@Component({
  selector: 'app-monitore-forms',
  templateUrl: './monitore-forms.component.html',
  styleUrls: ['./monitore-forms.component.css']
})
export class MonitoreFormsComponent {

  enviandoDados: boolean = false;
  Forms: any;
  formData: FormGroup; // Declarando formData como FormGroup


  camposVazios: string[] = [];
  opcoesSelecionadas: { [id: string]: string[] } = {};
  userID: number;
  formid: string
  localizacao: string;

  imagemUrls: { [key: string]: string } = {};
  fotoInput: HTMLInputElement | undefined;



  constructor(private router: Router,
    private route: ActivatedRoute,
    private monitoreService: MonitoreService,
    private formBuilder: FormBuilder,
    private DBpwa: DbPwaService
  ) {
  }

  ngOnInit() {

    this.userID = parseInt(sessionStorage.getItem('userID'), 10);

    // Inscrição para observar os parâmetros da rota
    this.route.params.subscribe((params) => {
      // Extrair o `id` dos parâmetros da rota

      const id_forms = params['id']
      this.formid = id_forms

      this.getByIDPerguntasFormulario(id_forms)
      // this.getByIDRespostasFormulario(id_forms)
    });

  }

  getByIDPerguntasFormulario(id_forms) {
    this.monitoreService.getByIDPerguntasFormulario(id_forms).subscribe(
      (data) => {
        this.Forms = data
        this.inicializarFormData(data);

      },
      (error) => {
        console.error('Erro ao buscar monitore:', error);

      }
    );
  }

  getByIDRespostasFormulario(id_forms) {
    this.monitoreService.ListarRespostasByIdForm(this.userID, id_forms).subscribe(
      (data) => {
        //  this.Forms = data
        //   this.inicializarFormData(data);
      },
      (error) => {
        console.error('Erro ao buscar monitore:', error);
        console.log(error.status)
        if (error.status == 404) {

        }
      }
    );
  }

  inicializarFormData(dynamicValues: any) {
    const formControls = {};
    // Itera sobre os valores dinâmicos para criar os controles do formulário
    dynamicValues.forEach(form => {
      formControls[form.tipo + '_' + form.id] = ['', Validators.required];

    });

    this.formData = this.formBuilder.group(formControls);
  }
  onCheckboxChange(isChecked: boolean, opcao: string, perguntaId: number) {
    if (isChecked) {
      if (!this.opcoesSelecionadas[perguntaId]) {
        this.opcoesSelecionadas[perguntaId] = [];
      }
      this.opcoesSelecionadas[perguntaId].push(opcao);
    } else {
      if (this.opcoesSelecionadas[perguntaId]) {
        const index = this.opcoesSelecionadas[perguntaId].indexOf(opcao);
        if (index !== -1) {
          this.opcoesSelecionadas[perguntaId].splice(index, 1);
        }
      }
    }

  }

  enviarRespostas() {
    this.enviandoDados = true;

    const formData = new FormData();

    this.camposVazios = [];
    const payload = {
      usuario_id: this.userID,
      respostas: []
    };

    // Verifica se algum campo está vazio
    Object.keys(this.formData.controls).forEach(key => {
      if (!this.formData.get(key).value) {
        this.camposVazios.push(key);
      }
    });

    if (this.camposVazios.length > 0) {
      console.log('Por favor, preencha todos os campos!');
      console.log('Campos vazios:', this.camposVazios);
      this.enviandoDados = false;

      return;
    }

    Object.keys(this.formData.controls).forEach(key => {
      const pergunta_id = parseInt(key.split('_')[1]);
      const control = this.formData.get(key);

      if (key.includes('checkBox')) {
        const opcoesSelecionadas = this.opcoesSelecionadas[pergunta_id];

        formData.append(`respostas[${payload.respostas.length}][pergunta_id]`, pergunta_id.toString());
        formData.append(`respostas[${payload.respostas.length}][formulario_id]`, this.formid.toString());
        formData.append(`respostas[${payload.respostas.length}][resposta]`, opcoesSelecionadas.join(', '));

        payload.respostas.push({
          pergunta_id: pergunta_id,
          formulario_id: this.formid,
          resposta: opcoesSelecionadas.join(', ')
        });
      } else if (key.includes('imagem')) {
        if (control.value) {
          const fileInput = document.getElementById('imagem_' + pergunta_id) as HTMLInputElement;
          const file = fileInput.files[0];

          formData.append(`respostas[${payload.respostas.length}][pergunta_id]`, pergunta_id.toString());
          formData.append(`respostas[${payload.respostas.length}][formulario_id]`, this.formid.toString());
          formData.append(`respostas[${payload.respostas.length}][resposta]`, file);
          formData.append('imagem_' + pergunta_id, file);
          payload['imagem_' + pergunta_id] = file;

          payload.respostas.push({
            pergunta_id: pergunta_id,
            formulario_id: this.formid,
            resposta: file // Adiciona o arquivo diretamente como resposta
          });
        }
      } else if (control.value) {

        formData.append(`respostas[${payload.respostas.length}][pergunta_id]`, pergunta_id.toString());
        formData.append(`respostas[${payload.respostas.length}][formulario_id]`, this.formid.toString());
        formData.append(`respostas[${payload.respostas.length}][resposta]`, control.value);

        payload.respostas.push({
          pergunta_id: pergunta_id,
          formulario_id: this.formid,
          resposta: control.value
        });
      }
    });

    formData.append('usuario_id', payload.usuario_id.toString());


    // console.log('Imprimindo FormData:');
    // console.log('---------------------');
    // formData.forEach((value, key) => {
    //   console.log(key, ':', value);
    // });
    // console.log('---------------------');

    this.monitoreService.enviarRespostas(formData).subscribe(
      (response) => {
        alert(response.message)
        this.irParaMonitore()
      },
      (error: any) => {
        this.enviandoDados = false;

        //Sem internet slavando localmente...
        if (error.status == 0) {
          this.DBpwa.addTask(payload)
          alert('Respostas salvas localmente.')
          this.irParaMonitore()


        }

        console.error('Erro ao salvar dados do formulário:', error);
        if (error.status == 409) {
          alert(error.error.message)
        }  
      
      }
    );
  }


  irParaMonitore() {
    this.router.navigate(['/modulos/monitore']);
  }

  obterGeolocalizacao(perguntaId: any): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
          // Faça o que quiser com as coordenadas aqui
          this.formData.get('Geolocalizacao_' + perguntaId).setValue(`Latitude: ${latitude}, Longitude: ${longitude}`);
          this.localizacao = `Latitude: ${latitude}, Longitude: ${longitude}`;

        },
        (error) => {
          this.formData.get('Geolocalizacao_' + perguntaId).setValue(`Erro ao obter localização`);
          this.localizacao = 'Erro ao obter localização';
          console.error('Erro ao obter geolocalização:', error.message);
        }
      );
    } else {
      console.error('Geolocalização não suportada pelo navegador.');
    }
  }



  removerFoto(perguntaId: string) {
    // Limpa a URL da imagem exibida para o perguntaId específico
    delete this.imagemUrls[perguntaId];

    // Limpa o valor do campo de entrada de arquivo
    const input: HTMLInputElement | null = document.getElementById('imagemInput_' + perguntaId) as HTMLInputElement;
    if (input) {
      input.value = ''; // Limpa o valor do campo de entrada de arquivo
    }

    // Remove o campo vazio da lista de campos vazios
    const index = this.camposVazios.indexOf('imagem_' + perguntaId);
    if (index !== -1) {
      this.camposVazios.splice(index, 1);
    }
  }


  onFileSelected(event: any, formId: string) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        // Armazena a URL da imagem usando o formId como chave
        this.imagemUrls[formId] = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

}
