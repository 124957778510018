<div *ngIf="showModal" class="modal-container" [ngClass]="modalPosition">
  <div class="custom-modal">
    <div class="modal-header">
      Central de Notificações
      <img src="../../assets/xverde.png" class="icon-fechar" (click)="fecharmodal()">
    </div>
    <div class="modal-text-conteudo">
      <div class="text-conteudo">
        <ng-container *ngFor="let notification of DadosNotification; let i = index">
          <div class="linha-tracada"></div>

          <strong>Descrição:</strong> {{ notification.descricao_notificacao }} <br>
          <strong>Tela:</strong> {{ notification.tela_notificacao }}  <i (click)="irParaComponente(notification.tela_notificacao)" class="fa-solid fa-arrow-up-right-from-square"></i>
          <br>
          <br>
          <!-- Use i (índice) se necessário -->
        </ng-container>
      </div>
      
    </div>
  </div>

</div>