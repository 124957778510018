import { Component } from '@angular/core';

@Component({
  selector: 'app-sobre-recarrega-ce',
  templateUrl: './sobre-recarrega-ce.component.html',
  styleUrls: ['./sobre-recarrega-ce.component.css']
})
export class SobreRecarregaCeComponent {

}
