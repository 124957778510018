  export const opcoesGenero = [
      { value: '', label: 'SELECIONE O GÊNERO' },
      { value: 'masculino', label: 'MASCULINO' },
      { value: 'feminino', label: 'FEMINIO' },
      { value: 'outro', label: 'OUTRO' },
      { value: 'nao-binario', label: 'NÃO-BINÁRIO' },
      { value: 'transexual-transgenero', label: 'TRANSEXUAL/TRANSGÊNERO' },
      { value: 'prefiro-nao-dizer', label: 'PREFIRO NÃO DIZER' }
    ];

