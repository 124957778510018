import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appMaskPhoneNumber]'
})
export class MaskPhoneNumberDirective {
  constructor(private el: ElementRef) {}

  /**
   * Diretiva para aplicar uma máscara a um campo de entrada de telefone.
   * A máscara é exibida enquanto o usuário digita e permite a exclusão do traço (-) durante a edição.
   * Ela formata o número no formato (99) 9 9999-9999.
   *
   * @param event O evento de entrada de texto.
   */
  @HostListener('input', ['$event'])
  onInput(event: any) {
    // Remove todos os caracteres não numéricos
    let telefone = event.target.value.replace(/\D/g, '');

    // Aplica a máscara com base no tamanho do número de telefone
    if (telefone.length <= 10) {
      telefone = telefone.replace(/^(\d{2})(\d{1,4})(\d{0,4}).*/, '($1) $2-$3');
    } else {
      telefone = telefone.replace(/^(\d{2})(\d{1,5})(\d{0,4}).*/, '($1) $2-$3');
    }

    // Atualiza o valor do campo de entrada com a máscara
    event.target.value = telefone;
  }

  /**
   * Manipula o evento keydown para permitir a exclusão do traço (-) apenas durante a edição.
   *
   * @param event O evento de tecla pressionada.
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement; // Cast para HTMLInputElement

    // Verifica se a tecla pressionada é o 'Backspace' e se não há seleção de texto
    if (event.key === 'Backspace' && target.selectionStart === target.selectionEnd) {
      let telefone = target.value.replace(/\D/g, '');

      // Permite a exclusão do traço (-) apenas durante a edição
      if (telefone.length <= 9) {
        telefone = telefone.substring(0, telefone.length - 1);
      }

      // Aplica a máscara novamente com base no tamanho do número de telefone
      if (telefone.length <= 10) {
        telefone = telefone.replace(/^(\d{2})(\d{1,4})(\d{0,4}).*/, '($1) $2-$3');
      } else {
        telefone = telefone.replace(/^(\d{2})(\d{1,5})(\d{0,4}).*/, '($1) $2-$3');
      }

      // Atualiza o valor do campo de entrada com a máscara
      target.value = telefone;
    }
  }
}
