<!DOCTYPE html>
<html>
  
<app-carregamento-padrao *ngIf="isLoading"></app-carregamento-padrao>
<div *ngIf="!isLoading">
  <app-menu-desk></app-menu-desk>
  <app-menu></app-menu>


  <app-modal-atividades-usuarios
  [showModal]="showModal" 
  [MinhdasAtividades]="mostrarMinhasAtividades"
  [eventos]="eventos"
  [id_Atividades]="eventos.id_Atividades"
  (FazerRequisicaoUsers)="onRequisitarNovamente()"
  [modalPosition]="'custom'"
   (closeModal)="closeModal()"></app-modal-atividades-usuarios>

   <app-modal-notificaton
    [showModal]="showModalNotification" 
    [DadosNotification]="notification"
     (closeModal)="closeModalNotification()"></app-modal-notificaton>

  <div (click)="onOutsideClick($event)" class="backgroud">

    <section class="box-top">
      <div class="content-box">
        <div class="icon-1" (click)="uploadCaminho(eventos.foto_perfil)">
          <img [src]="foto_perfil ? (baseUploadUrl + foto_perfil) : '../../assets/perfil_menor.png'"  id="referencia_modal" alt="">  
        </div>
        <div class="content-text">
          <p class="text title-box-top">Olá, {{nome}}</p>
          <div class="content-2">
            <br/>
            <br/>
            <!-- <div class="icon-text"><img src="../../assets/gota_pequena.png" alt=""></div> -->
            <!-- <p class="text subtitle-box-top">xxx gotas</p> -->
          </div>
        </div>
        <div class="box-buttom-desk">
          <div class="buttom-desk"> <!--Div feita para responsividade em desktop-->
          <select  [ngClass]="{ 'fica-invisivel': mostrarMinhasAtividades }" id="cidade" [(ngModel)]="cidadeSelecionada" (change)="carregarTemaInicial()">
            <option *ngFor="let cidade of cidadesMockadas" [value]="cidade.id">
              {{ cidade.nome }}
            </option>
          </select>
    
          <select  [ngClass]="{ 'fica-invisivel': mostrarMinhasAtividades }"name="tema" id="tema" [(ngModel)]="temaSelecionado">
            <option *ngFor="let tema of getTemasCidadeSelecionada()" [value]="tema.id">
              {{ tema.nomeTema }}
            </option>
          </select>
        </div>
        <div class="toggle-container">
          <input type="checkbox" id="toggle-btn" class="toggle-checkbox" [(ngModel)]="mostrarMinhasAtividades" />
          <label for="toggle-btn" class="toggle-label"
            [ngClass]="{ 'minhas-atividades-toggle': mostrarMinhasAtividades }"></label>
          <p class="text text-lembrar-senha">MINHAS ATIVIDADES</p>
        </div>
        </div>
        <div class="icon-2">
          <ng-container *ngIf="NotificationAtiva; else sinoativo">
            <img src="../../assets/sinoon.svg"  (click)="OpenModalNotification(notification)" alt="">
          </ng-container>
          <ng-template #sinoativo>
          <img src="../../assets/SinoAtual.svg" alt="">
          </ng-template>
        </div>
              </div>      
      <div class="box-bol-absolute" >
        <div class="box-bol" (click)="irParaNovidades()" >
          <div class="bol">
            <div class="img-nov">
              <img src="../../assets/icone_novidades.png" alt="">
            </div>
            <div class="novidades">
              <p>MURAL</p>
            </div>
          </div>
          <!-- <p class="text">NOVIDADES</p> -->
        </div>
        <div class="box-bol">
          <div class="bol-1">
            <div class="img-ativ">
              <img src="../../assets/icone_atividades.png" alt="">
            </div>
            <div class="atividades">
              <p>ATIVIDADES</p>
            </div>
          </div>
          <!-- <p class="text">ATIVIDADES</p> -->
        </div>
      </div>
    </section>
    
    <section class="box-buttom">
      <select  [ngClass]="{ 'fica-invisivel': mostrarMinhasAtividades }" id="cidade" [(ngModel)]="cidadeSelecionada" (change)="carregarTemaInicial()">
        <option *ngFor="let cidade of cidadesMockadas" [value]="cidade.id">
          {{ cidade.nome }}
        </option>
      </select>

      <select  [ngClass]="{ 'fica-invisivel': mostrarMinhasAtividades }"name="tema" id="tema" [(ngModel)]="temaSelecionado">
        <option *ngFor="let tema of getTemasCidadeSelecionada()" [value]="tema.id">
          {{ tema.nomeTema }}
        </option>
      </select>

      <div class="toggle-container">
        <input type="checkbox" id="toggle-btn" class="toggle-checkbox" [(ngModel)]="mostrarMinhasAtividades" />
        <label for="toggle-btn" class="toggle-label"
          [ngClass]="{ 'minhas-atividades-toggle': mostrarMinhasAtividades }"></label>
        <p class="text text-lembrar-senha">MINHAS ATIVIDADES</p>
      </div>
    </section>

    <section class="box-down" id="ref_modal">
      <ng-container *ngFor="let evento of (mostrarMinhasAtividades ? getEventosRecentesDoUsuario() : getEventosRecentes()); let i = index" >
        <div class="box-container"  (click)="openModal(evento.id_Atividade)" (click)="scrollToDiv('ref_modal')" [ngClass]="{ 'minhas-atividades': mostrarMinhasAtividades }" >
          <div class="box-limit">
            <p class="text text-down">{{ evento.descricao }}</p>
            <p class="text-horario">{{formatarDataHora(evento.horario)}} h</p>
          </div>
          <div class="avatar-xs">
            <img class="img-evento" [src]="evento.img_evento ? (baseUploadUrl + evento.img_evento) : (baseUploadUrl + 'images/RsTWs8h7cu3ChOQZefJSpz35ej6CccUgZ10uGe72.png')">
          </div>
        </div>
      </ng-container>   
      <br>
      <br>
      <br>
    </section>

 

  </div>
</div>

</html>