import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable, Subject,map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket$: WebSocketSubject<any>;
  private newMessageSubject: Subject<any> = new Subject<any>();
  private onlineUsersSubject: Subject<string[]> = new Subject<string[]>();
  private isConnected: boolean = false;
  private userId: number;

  constructor() {
    this.userId = 0;
  }

  async connectWebSocket(userId: number) {
    const authKey = '4a7b9c2e-f1d8-4f96-8b39-7c31e18c5a23';
    this.socket$ = webSocket(`wss://api.recarrega.app.br:3000?authKey=${authKey}&userId=${userId}`);

    this.socket$.subscribe(
      () => {
        this.isConnected = true;
      },
      (error) => {
        this.isConnected = false;
        console.error('Falha ao conectar ao servidor WebSocket:', error);
      }
    );

    this.socket$.subscribe(
      (message: any) => {
        this.newMessageSubject.next(message);
      },
      (error: any) => {
        this.isConnected = false;
        console.error('Erro ao receber mensagem do servidor WebSocket:', error);
      },
      () => {
        this.isConnected = false;
      }
    );
  }

  getSocket() {
    return this.socket$;
  }

  async sendMessage(message: any): Promise<boolean> {
    try {
      const messageJSON = JSON.stringify(message);
      this.socket$.next(message);
      return true;
    } catch (error) {
      console.error('Erro ao enviar mensagem para o servidor WebSocket:', error);
      return false;
    }
  }

  getNewMessageObservable(): Observable<any> {
    return this.newMessageSubject.asObservable();
  }

  getConnectionStatus(): boolean {
    return this.isConnected;
  }

  // Método para obter a lista de usuários online
  getOnlineUsersList(): Observable<string[]> {
    return this.socket$.pipe(
      map((message: any) => {
        if (message.type == 'updateUserList') {
          return message.users;
        }
        return []; // Retorna uma lista vazia se a mensagem não for de atualização
      })
    );
  }
  
}
