import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Groups } from 'src/app/chat/chat-index/chat.model';

@Injectable({
  providedIn: 'root'
})


export class ForumService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }
  private token = sessionStorage.getItem('token'); // Obtém o token de autorização da sessionStorage


  getConversationsInForum(user_id: number): Observable<Groups[]> {
    const url = `${this.apiUrl}/forums/conversations/${user_id}`;
    const token = sessionStorage.getItem('token'); // Obtém o token de autorização da sessionStorage
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // Define o cabeçalho com o token de autorização
    return this.http.get<Groups[]>(url, { headers });
  }

  getConversationsInForumBySuporte(user_id: number): Observable<Groups[]> {
    const url = `${this.apiUrl}/forums/conversations/suporte/${user_id}`;
    const token = sessionStorage.getItem('token'); // Obtém o token de autorização da sessionStorage
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // Define o cabeçalho com o token de autorização
    return this.http.get<Groups[]>(url, { headers });
  }
  
  getUsersByForum(user_id: number): Observable<Groups[]> {
    const url = `${this.apiUrl}/users_forum/${user_id}`;
    const token = sessionStorage.getItem('token'); // Obtém o token de autorização da sessionStorage
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // Define o cabeçalho com o token de autorização

    return this.http.get<Groups[]>(url, { headers });

  }

  postConversationInForum(forumId: number, conversationData: any): Observable<any> {
    const url = `${this.apiUrl}/forums/${forumId}/conversations`;
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post(url, conversationData, { headers });
  }

  postConversationInForumDocumentos(forumId: number, conversationData: any): Observable<any> {
    const url = `${this.apiUrl}/forums/${forumId}/storeconversation`;
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post(url, conversationData, { headers });
  }

  addForumToUser(atividade: number, user_id: number) {
    const url = `${this.apiUrl}/user/${user_id}/atividade/${atividade}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
    return this.http.post<Groups[]>(url, { headers });
  }

  markMessagesAsRead(messageIds: number[]) {
    const url = `${this.apiUrl}/read`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
    return this.http.post(url, { message_ids: messageIds }, { headers });
  }

  MessagesUnReadByUserid( user_id: number) {
    const url = `${this.apiUrl}/unread/${user_id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
    return this.http.post(url, { user_id: user_id }, { headers });
  }


  EditMessagesById(messageId: number, content: string) {
    const payload = {
      content: content
    };
    const url = `${this.apiUrl}/user/message/${messageId}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
    return this.http.post(url,payload,{ headers });
  }

  DeleteMessagesById(messageId: number) {
    const payload = {
    };
    const url = `${this.apiUrl}/user/message/delete/${messageId}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
    return this.http.post(url,payload ,{ headers });
  }
}
