import { Component, Input, Output, EventEmitter, ElementRef, HostListener,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/services/dataService';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap'; // Importe o NgbCarousel



@Component({
  selector: 'app-modal-novidades',
  templateUrl: './modal-novidades.component.html',
  styleUrls: ['./modal-novidades.component.scss']
})
export class ModalNovidadesComponent  {
  imagesLoaded: number = 0;
  imageLoadStatus: { [src: string]: boolean } = {};
  isLoading: boolean = false; // variavel responsavel por mostrar carregamento enquanto carrega as imagens
  @Input() selectedButton: number | null = null;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  private clickedElement: HTMLElement | null = null;

  @ViewChild('carousel', { static: false }) carousel: NgbCarousel; // Obtenha a referência ao carrossel


  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    
  ) { }

  ngOnInit(): void {
    // Obter o valor do parâmetro da rota
    this.route.paramMap.subscribe(params => {
      const button = +params.get('button'); // Converte para número
      // Aqui você pode usar o valor de 'button' como desejar, por exemplo, selecionar a imagem correspondente
      this.selectedButton = button;
      
      // Adicione a propriedade 'loaded' a cada imagem
      this.imagensComLegendasPorOpcao[this.selectedButton].forEach((imagem) => {
        this.imageLoadStatus[imagem.src] = false;
      });

      // Dispare manualmente o evento 'load' para cada imagem
      this.imagensComLegendasPorOpcao[this.selectedButton].forEach((imagem) => {
        const imageElement = new Image();
        imageElement.onload = () => this.onImageLoad(imagem);
        imageElement.src = imagem.src;
      });
    });
  }
  imagensComLegendasPorOpcao: { [key: number]: { src: string; legenda: string }[] } = {
    1: [
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card1.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card2.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card3.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card4.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card5.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card6.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card7.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card8.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card9.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card10.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card11.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card12.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card13.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card14.png', legenda: '' },
      { src: './assets/tecnologias-sociais/TecnologiaSocialNatureza_Card15.png', legenda: '' },
    ],
    2: [
      { src: './assets/tecnologias-sociais/Por que conservar_Card1.png', legenda: '' },
      { src: './assets/tecnologias-sociais/Por que conservar_Card2.png', legenda: '' },
      { src: './assets/tecnologias-sociais/Por que conservar_Card3.png', legenda: '' },
      { src: './assets/tecnologias-sociais/Por que conservar_Card4.png', legenda: '' },
      { src: './assets/tecnologias-sociais/Por que conservar_Card5.png', legenda: '' },
      { src: './assets/tecnologias-sociais/Por que conservar_Card6.png', legenda: '' },
      { src: './assets/tecnologias-sociais/Por que conservar_Card7.png', legenda: '' },
      { src: './assets/tecnologias-sociais/Por que conservar_Card8.png', legenda: '' },
      { src: './assets/tecnologias-sociais/Por que conservar_Card9.png', legenda: '' },
    ],
    3: [
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card1.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card2.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card3.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card4.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card5.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card6.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card7.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card8.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card9.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card10.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card11.png', legenda: '' },
      { src: './assets/tecnologias-sociais/PobrezaMenstrual_Card12.png', legenda: '' },
    ]
  };


  ElementoClicadoGlobal(event: MouseEvent): void {
    this.clickedElement = event.target as HTMLElement;
  }
  irParaHomeENovidades() {
    // Navega para a rota /home
    this.router.navigateByUrl('/home');
    // Aguarda um pequeno intervalo para garantir que a navegação tenha ocorrido
    setTimeout(() => {
      this.scrollToDiv('novidades'); // Rola até o elemento com o ID 'novidades'
    }, 100);
  }

  scrollToDiv(divId: string) {
    const targetDiv = document.getElementById(divId);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' }); // Rola até a div de destino de forma suave
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const modalContent = this.elementRef.nativeElement.querySelector('.ngb-carousel');
    const clickedElement = event.target as HTMLElement;

    // Verifica se o elemento clicado não é descendente da modal-content
    if (modalContent && !modalContent.contains(clickedElement)) {
      this.irParaHomeENovidades();
    }
  }

  
  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    // Registre a posição inicial do toque
    const touchStartX = event.touches[0].clientX;
    this.elementRef.nativeElement.setAttribute('data-touchstart-x', touchStartX.toString());
  }

  @HostListener('document:touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    // Compare a posição inicial e final para determinar o deslizamento
    const touchEndX = event.changedTouches[0].clientX;
    const touchStartX = parseFloat(this.elementRef.nativeElement.getAttribute('data-touchstart-x'));

    if (touchStartX < touchEndX) {
      this.detectSwipe('right');
    } else if (touchStartX > touchEndX) {
      this.detectSwipe('left');
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    // Registre a posição inicial do mouse
    this.elementRef.nativeElement.setAttribute('data-mousestart-x', event.clientX.toString());
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    const mouseEndX = event.clientX;
    const mouseStartX = parseFloat(this.elementRef.nativeElement.getAttribute('data-mousestart-x'));

    if (mouseStartX < mouseEndX) {
      this.detectSwipe('right');
    } else if (mouseStartX > mouseEndX) {
      this.detectSwipe('left');
    }
  }

  detectSwipe(direction: string) {
    // Função para lidar com o deslizamento (swipe)
    if (direction === 'left') {
      this.carousel.next(); // Avançar para o próximo slide
    } else if (direction === 'right') {
      this.carousel.prev(); // Voltar para o slide anterior
    }
  }

  onImageLoad(imagem): void {
    // Quando uma imagem é carregada, marque-a como carregada
    this.imageLoadStatus[imagem.src] = true;
    this.imagesLoaded++;

    // Verifique se todas as imagens foram carregadas
    if (this.imagesLoaded == this.imagensComLegendasPorOpcao[this.selectedButton].length) {
      this.isLoading = true;
    }
  }

  
}
