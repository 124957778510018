<div class="carrossel">
  <div class="carrossel-01-01-parent">
    <!-- Slide 1 -->
    <!-- <div class="carrossel-01-01">
      <img class="imagem-fundo-texto" alt="" src="./assets/mask-group@2x.png" />
      <img class="carrossel-01-01-child" alt="" src="./assets/group-1000005263.svg" />
      <div class="corpo-texto">
        Interdum et malesuada fames ac ante ipsum primis in faucibus.
        Sed sapien purus, pharetra non consectetur nec,
      </div>
      <b class="titulo-texto">Lorem ipsum dolor</b>
    </div> -->
    
    
    <!-- Slide 3 -->
    <div class="carrossel-01-01">
      <div class="bg-carrossel"></div>
      <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
        <div class="corpo-texto">
          Interdum et malesuada fames ac ante ipsum primis in faucibus.
          Sed sapien purus, pharetra non consectetur nec,
        </div> -->
        <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Compostagem.png" />
      </div>
      
      <!-- Slide 4 -->
      <div class="carrossel-01-01">
        <div class="bg-carrossel"></div>
        <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
          <div class="corpo-texto">
            Interdum et malesuada fames ac ante ipsum primis in faucibus.
            Sed sapien purus, pharetra non consectetur nec,
          </div> -->
          <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Armazenamento_chuva.png" />
        </div>
        
        <!-- Slide 5 -->
        <div class="carrossel-01-01">
          <div class="bg-carrossel"></div>
          <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
            <div class="corpo-texto">
              Interdum et malesuada fames ac ante ipsum primis in faucibus.
              Sed sapien purus, pharetra non consectetur nec,
            </div> -->
            <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Quintal_produtivo.png" />
          </div>
          <!-- Slide 6 -->
          <div class="carrossel-01-01">
            <div class="bg-carrossel"></div>
            <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
              <div class="corpo-texto">
                Interdum et malesuada fames ac ante ipsum primis in faucibus.
                Sed sapien purus, pharetra non consectetur nec,
              </div> -->
              <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Pobreza_menstrual.png" />
            </div>
            <!-- Slide 7 -->
            <div class="carrossel-01-01">
              <div class="bg-carrossel"></div>
              <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
                <div class="corpo-texto">
                  Interdum et malesuada fames ac ante ipsum primis in faucibus.
                  Sed sapien purus, pharetra non consectetur nec,
                </div> -->
                <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Manejo_solo.png" />
              </div>
              
              
              <!-- Slide 8 -->
              <div class="carrossel-01-01">
                <div class="bg-carrossel"></div>
                <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
                  <div class="corpo-texto">
                    Interdum et malesuada fames ac ante ipsum primis in faucibus.
                    Sed sapien purus, pharetra non consectetur nec,
                  </div> -->
                  <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Conservação_Florestal.png" />
                </div>
                
                <!-- Slide 9 -->
                <div class="carrossel-01-01">
                  <div class="bg-carrossel"></div>
                  <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
                    <div class="corpo-texto">
                      Interdum et malesuada fames ac ante ipsum primis in faucibus.
                      Sed sapien purus, pharetra non consectetur nec,
                    </div> -->
                    <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Saneamento_rural.png" />
                  </div>
                  
                  <!-- Slide 10 -->
                  <div class="carrossel-01-01">
                    <div class="bg-carrossel"></div>
                    <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
                      <div class="corpo-texto">
                        Interdum et malesuada fames ac ante ipsum primis in faucibus.
                        Sed sapien purus, pharetra non consectetur nec,
                      </div> -->
                      <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Meliponicultura.png" />
                    </div>
                    
                    <!-- Slide 11 -->
                    <div class="carrossel-01-01">
                      <div class="bg-carrossel"></div>
                      <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
                        <div class="corpo-texto">
                          Interdum et malesuada fames ac ante ipsum primis in faucibus.
                          Sed sapien purus, pharetra non consectetur nec,
                        </div> -->
                        <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Agrossilvipastoril.png" />
                      </div>
                      
                      <!-- Slide 2 -->
                      <div class="carrossel-01-01">
                        <div class="bg-carrossel"></div>
                        <!-- <b class="titulo-texto">Lorem ipsum dolor</b>
                          <div class="corpo-texto">
                            Interdum et malesuada fames ac ante ipsum primis in faucibus.
                            Sed sapien purus, pharetra non consectetur nec,
                          </div> -->
                          <img class="imagem-fundo-texto" src="./assets/tecnologias-sociais/Aquaponia.png" />
    </div>
  </div>
</div>