
import { Component, OnInit, Renderer2, ElementRef, ViewChild, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Route } from '@angular/router';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap'; // Importe o NgbCarousel
import { Router } from '@angular/router';
import 'hammerjs';
import { AprendaService } from 'src/services/Aprenda.service';
@Component({
  selector: 'app-aprenda-storys',
  templateUrl: './aprenda-storys.component.html',
  styleUrls: ['./aprenda-storys.component.css']
})
export class AprendaStorysComponent  {
  selectedFile: File;
  pages: any = [];
  convertedText: string;
  textohtml:string;
  obejetoS3: any = [];
  newold: boolean;

  idconteudoaprenda:number;
  carouselAtivo: boolean = false;

  @ViewChild('carousel', { static: false }) carousel: NgbCarousel; // Obtenha a referência ao carrossel

  constructor(
    private renderer: Renderer2, private el: ElementRef, private sanitizer: DomSanitizer,
    private router:Router,
    private route: ActivatedRoute ,
    private serviceAPrenda: AprendaService,
    
    ) { }

  ngAfterViewInit() {
    // Aplica a classe aos indicadores após o conteúdo ser renderizado
    this.adicionarClasseAoH1();
    this.addSizeClassToImages();
  }

  /*Recebe como parametro o id da subatividade, para fazer requisição e carrega o storys*/
  ngOnInit() {

    this.route.params.subscribe((params) => {
      const id_contudo_aprenda = params['id']
     this.carrega_sub_modulos(id_contudo_aprenda);
    });

  }



  carrega_sub_modulos(id: number) {

    this.serviceAPrenda.getAprendaSubConteudoById(id).subscribe(
      (data: any) => {
        console.log(data)
        this.textohtml = data[0].textohtml;
        this.idconteudoaprenda =  data[0].id_aprenda_conteudo;
        this.convertedText =  this.textohtml;
        this.splitContentIntoPages(this.convertedText);
        this.addFixedStyleToClass('carousel-indicators');
        this.obejetoS3 = data.objetosS3
        console.log(this.obejetoS3)
        if (this.obejetoS3 == undefined){
          this.newold = true;
        } else {
          this.newold = false
        }
      },
      (error) => {
        console.error('Erro ao buscar cidades e temas:', error);
      }
    )

  }
 
  private touchStartX: number;
  private touchStartY: number;
  private touchStartTime: number;

  // ...

  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    // Armazena as coordenadas iniciais e o tempo de início do toque
    this.touchStartX = event.changedTouches[0].clientX;
    this.touchStartY = event.changedTouches[0].clientY;
    this.touchStartTime = event.timeStamp;
  }

  @HostListener('document:touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    // Armazena as coordenadas finais e o tempo de término do toque
    const touchEndX = event.changedTouches[0].clientX;
    const touchEndY = event.changedTouches[0].clientY;
    const touchEndTime = event.timeStamp;
  
    // Calcula a diferença nas coordenadas
    const deltaX = touchEndX - this.touchStartX;
    const deltaY = touchEndY - this.touchStartY;
  
    // Calcula o tempo decorrido
    const deltaTime = touchEndTime - this.touchStartTime;
  
    // Defina limiares para considerar um movimento como deslize
    const horizontalThreshold = 50;
    const verticalThreshold = 50;
    const timeThreshold = 500;
  
    // Verifica se o movimento atende aos critérios de deslize horizontal ou vertical
    const horizontalSwipe = Math.abs(deltaX) > horizontalThreshold && deltaTime < timeThreshold;
    const verticalSwipe = Math.abs(deltaY) > verticalThreshold && deltaTime < timeThreshold;
  
    // Determina a direção com base na diferença nas coordenadas
    if (horizontalSwipe && Math.abs(deltaX) > Math.abs(deltaY)) {
      if (deltaX > 0) {
        this.detectSwipe('right');
      } else {
        this.detectSwipe('left');
      }
    } else if (verticalSwipe && Math.abs(deltaY) > Math.abs(deltaX)) {
      if (deltaY > 0) {
        // Aqui você pode adicionar lógica para lidar com o deslize para baixo
        console.log('Deslize para baixo');
      } else {
        // Aqui você pode adicionar lógica para lidar com o deslize para cima
        console.log('Deslize para cima');
      }
    }
  }
  

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    // Registre a posição inicial do mouse
    this.el.nativeElement.setAttribute('data-mousestart-x', event.clientX.toString());
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    // Compare a posição inicial e final do mouse para determinar o deslizamento
    const mouseEndX = event.clientX;
    const mouseStartX = parseFloat(this.el.nativeElement.getAttribute('data-mousestart-x'));



    if (mouseStartX < mouseEndX) {
      this.detectSwipe('right');
    } else if (mouseStartX > mouseEndX) {
      this.detectSwipe('left');
    }
  }

  detectSwipe(direction: string) {

    if (direction === 'left') {
      this.carousel.next(); // Avançar para o próximo slide
    } else if (direction === 'right') {
      this.carousel.prev(); // Voltar para o slide anterior
    }
  }

  adicionarClasseAoH1() {
    const elements = document.querySelectorAll('.text h1');
    elements.forEach((element) => {
      element.classList.add('text-h1');
    });
  }

  addSizeClassToImages() {
    const imgElements = this.el.nativeElement.querySelectorAll('img');
    imgElements.forEach((imgElement) => {
      const alt = imgElement.getAttribute('alt');
      if (alt) {
        if (alt.includes('pequena')) {
          this.renderer.addClass(imgElement, 'img-pequena');
        } else if (alt.includes('media')) {
          this.renderer.addClass(imgElement, 'img-media');
        } else if (alt.includes('grande')) {
          this.renderer.addClass(imgElement, 'img-grande');
        }
      }
    });
  }

   addFixedStyleToClass(className) {
    const elements = document.querySelectorAll('.' + className);
  
    elements.forEach((element) => {
      if (element instanceof HTMLElement) {
        element.style.position = 'fixed';
      }
    });
  }
  // Chame a função com o nome da classe que você deseja estilizar
  splitContentIntoPages(content: string) {
    // Divide o conteúdo em páginas com base na marcação <!-- Nova Página -->
    const pagesArray = content.split('<!-- Nova Página -->');

    for (let i = 0; i < pagesArray.length; i++) {
      let pageContent = pagesArray[i].trim();

      // Verifica se o conteúdo da página contém uma tag iframe
      const iframeRegex = /<iframe[^>]*src=["'](https?:\/\/[^"']+)["'][^>]*>/i;

      const tamanhoDaImagem = this.getTamanhoDaImagem(pageContent);
      const tamanhoClass = tamanhoDaImagem !== 'desconhecido' ? `imagem-${tamanhoDaImagem}` : '';

      if (pageContent) { // Verifica se o conteúdo da página não está vazio
        this.pages.push({
          content: this.sanitizer.bypassSecurityTrustHtml(pageContent),
          legend: `Página ${this.pages.length + 1}`, // Pode personalizar as legendas aqui
          tamanhoClass: tamanhoClass,
        });
      }
    }
  }


  getTamanhoDaImagem(html: string): string {
    if (html.includes('classe="grande"')) {
      return 'grande';
    } else if (html.includes('classe="media"')) {
      return 'media';
    } else if (html.includes('classe="pequena"')) {
      return 'pequena';
    } else {
      return 'desconhecido'; // Se nenhuma classe for encontrada
    }
  }

  processConvertedText(html: string) {
    // Verifica se o HTML contém um iframe
    const iframeRegex = /<iframe[^>]*src=["'](https?:\/\/[^"']+)["'][^>]*>/i;
    const match = iframeRegex.exec(html);

    if (match) {
      const iframeUrl = match[1];
      this.pages[0].videoUrl = iframeUrl; // Adiciona o URL do iframe à primeira página
    }
  }

  irParaTelaModelAprenda() {
    this.router.navigate(['/modulos/aprenda/modal/',   this.idconteudoaprenda ]);
  }
}

