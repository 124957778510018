import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { AprendaService } from 'src/services/Aprenda.service';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.css']
})
export class ModelComponent {
  sub_conteudos_aprenda: any[] = [];

  conteudoTitulo: String;
  botaoSelecionado: any = null;

  conteudosubModulo: string = 'Título padrão';
  idsubconteudo: string ;
  conteudoDescricao: string = 'Descrição padrão';
  imgConteudo:string='https://api.recarrega.app.br/uploads/images/RsTWs8h7cu3ChOQZefJSpz35ej6CccUgZ10uGe72.png'
  corbackground: string;
  isLoading: boolean = false; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serviceAPrenda: AprendaService) { }

  /**
 * Método ngOnInit
 *
 * Este método é chamado automaticamente quando o componente é inicializado.
 * Ele é usado para observar os parâmetros da rota e buscar os dados do card
 * com base no `id` fornecido nos parâmetros da rota. Os dados do card são
 * então armazenados na propriedade `this.card` para uso posterior no
 * componente.
 */
  ngOnInit() {
    // Inscrição para observar os parâmetros da rota
    this.route.params.subscribe((params) => {
      // Extrair o `id` dos parâmetros da rota


      const id_contudo_aprenda = params['id']

      this.carrega_sub_modulos(id_contudo_aprenda);

      this.carrega_modulos(id_contudo_aprenda);
    });
  }


  atualizarConteudo(subconteudo: any): void {
    console.log(subconteudo.id)
    // Atualize as variáveis com base no subconteudo clicado
    this.conteudosubModulo =subconteudo.descricao_conteudo;
    this.conteudoDescricao =  subconteudo.titulo;
    this.idsubconteudo = subconteudo.id;


    if (this.botaoSelecionado !== subconteudo) {
      // Desmarque o botão atualmente selecionado (se houver)
      if (this.botaoSelecionado) {
        this.botaoSelecionado.selecionado = false;
      }
      subconteudo.selecionado = true;
      // Atualize o botão selecionado
      this.botaoSelecionado = subconteudo;
  }
}
  carrega_sub_modulos(id: number) {

    this.isLoading = true;

    this.serviceAPrenda.getAprendaSubConteudo(id).subscribe(
      (data: any) => {

        this.sub_conteudos_aprenda = data;
        console.log(this.sub_conteudos_aprenda[0])
        this.atualizarConteudo(this.sub_conteudos_aprenda[0])// definindo como padrao o primeiro valor do array
        this.isLoading = false;


      },
      (error) => {
        console.error('Erro ao buscar cidades e temas:', error);
      }
    )
  }

  carrega_modulos(id: number) {

    this.serviceAPrenda.getAprendaConteudoById(id).subscribe(
      (data: any) => {
        //  this.cidadesMockadas = data;
        this.conteudoTitulo = data.nome
        this.corbackground = data.cor
        this.imgConteudo = data.imagemUrl
        this.isLoading = false;


      },
      (error) => {
        this.isLoading = false;

        console.error('Erro ao buscar cidades e temas:', error);
      }
    )
  }
  irParaTelaModelStorysAprenda() {
    this.router.navigate(['/modulos/aprenda/storys/',this.idsubconteudo]);
  }

  irParaTelaAprenda() {
    this.router.navigate(['/modulos/aprenda']);
  }

}
