import { Component } from '@angular/core';

@Component({
  selector: 'app-tecnologias-sociais-ce',
  templateUrl: './tecnologias-sociais-ce.component.html',
  styleUrls: ['./tecnologias-sociais-ce.component.css']
})
export class TecnologiasSociaisCeComponent {

}
