import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patrocinadores-ce',
  templateUrl: './patrocinadores-ce.component.html',
  styleUrls: ['./patrocinadores-ce.component.css']
})
export class PatrocinadoresCeComponent {

  constructor(private router: Router
  ) { }


  IrParaCadastro() {
    this.router.navigate(['/cadastro_usuario']);
  }

}
