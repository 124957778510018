
<app-carregamento-padrao *ngIf="isLoading"></app-carregamento-padrao>
<app-menu-desk></app-menu-desk>

<!-- <app-menu-desk></app-menu-desk> -->
<div class="background2" *ngIf="!isLoading">
    <div class="header-text">Aprenda</div>
  </div>

  <div class="content-box ">

    <div *ngFor="let conteudo_aprenda of conteudos_aprenda" class="theme">
      <div class="content-background1"  (click)="irParaTelaModelAprenda(conteudo_aprenda.id)" [style.background-color]="conteudo_aprenda.cor">
      <div class="content-image">
          <img class="content-img"  [src]="conteudo_aprenda.imagemUrl" alt="Imagem" />
        </div>
        <div class="conteudo-atividade">
          <div class="content-text">{{ conteudo_aprenda.nome }}</div>
          <div class="itens-icones">
            <div class="icon1">
              <div class="circle">
                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="gota-aprenda">
                  <path d="M7 1.07061C6.9016 1.1724 6.7916 1.28691 6.67183 1.41269C6.148 1.96283 5.43945 2.72638 4.6992 3.58115C3.9578 4.43724 3.19128 5.37718 2.54821 6.28093C1.92973 7.15012 1.45577 7.94426 1.21328 8.57651V8.58248L1.1686 8.70607C1.11692 8.849 1.07146 8.97504 1.03241 9.09848L7 1.07061ZM7 1.07061C7.09843 1.17242 7.20846 1.28697 7.32827 1.4128C7.8522 1.96304 8.56095 2.72679 9.30161 3.58197C10.0434 4.43846 10.8106 5.37903 11.4545 6.28374C12.0908 7.17759 12.5751 7.99284 12.8127 8.63524C12.8228 8.67399 12.8352 8.70892 12.848 8.7398C12.8912 8.85489 12.9348 8.9812 12.9732 9.10254C13.1535 9.68719 13.25 10.3138 13.25 10.9596C13.25 14.4367 10.4466 17.25 7 17.25C3.55292 17.25 0.75 14.4426 0.75 10.9596C0.75 10.3135 0.846569 9.68775 1.03239 9.09856L7 1.07061ZM1.21328 8.45106C1.21328 8.45059 1.21336 8.45366 1.21328 8.45961V8.45106Z" stroke="white" stroke-width="1.5"/>
              </svg>              </div>
            </div>
            <div class="icon2">
              <div class="circle">
                <div>{{ conteudo_aprenda.porcentagem }}%</div>
              </div>
            </div>
            <div class="progress-bar">
              <div>{{ conteudo_aprenda.qtd_sub_temas }} subtemas</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- essa div eh para da espaco para o menu ficar em baixo... -->
    <div>
      <p class="">&nbsp;</p>
      <p class="">&nbsp;</p>
    </div>
    <app-menu></app-menu>

  </div>

