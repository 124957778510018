
<div class="modal_ref" >

<img class="mask-group-icon1" alt="" src="./assets/tecnologias-sociais/PobrezaMenstrual_Thumbnail.png" />
<img class="mask-group-icon2" alt="" src="./assets/tecnologias-sociais/Por que conservar_Thumbnail.png" />
<img class="mask-group-icon3" alt="" src="./assets/tecnologias-sociais/TecnologiaSocialNatureza_Thumbnail.png" />

<div class="Borda-laranga-1"  (click)="openComponenteModal(1)"></div>
<div class="Borda-laranga-2" (click)="openComponenteModal(2)"></div>
<div class="Borda-laranga-3" (click)="openComponenteModal(3)"></div>

<b class="text-borda-1">O que é pobreza menstrual?</b>
<b class="text-borda-2">Por que conservar?</b>
<b class="text-borda-3">O que são tecnologias sociais baseadas na natureza?</b>


<img *ngIf="isWebShareSupported"  (click)="compartilharLink('1','O que é pobreza menstrual?','')"class="group-icon1" alt="" src="./assets/group.svg" />
<img *ngIf="isWebShareSupported"  (click)="compartilharLink('2','Por que conservar?','')" class="group-icon2" alt="" src="./assets/group.svg" />
<img   *ngIf="isWebShareSupported" (click)="compartilharLink('3','O que são tecnologias sociais baseadas na natureza?','')"class="group-icon3" alt="" src="./assets/group.svg" />
</div>
