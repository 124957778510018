<b class="conteneir-recarrega">O Recarrega</b>
<div class="logo-recarrega1">
    <img class="logo-recarrega-child" alt="" src="./assets/group-10000052441.svg" />
    <b class="recarrega">Recarrega</b>
</div>

<div class="fundo-verde-patrocinadores">
    <div class="conteiner-cidades">
        <div class="so-paulo">
            <div (click)="irParahomeSp()" class="rectangle-parent">
                <div class="so-paulo1">São Paulo</div>
                <div class="group-child"></div>
                <div class="group-item"></div>
            </div>
            <div (click)="irParahomeCe()" class="cear">
                <div class="rectangle-group">
                    <div class="cear1">Ceará</div>
                    <div class="group-child"></div>
                    <div class="group-child1"></div>
                </div>
            </div>
        </div>
    </div>
</div>

 <div class="carouse-full3 rotate-180">
    <div class="slide">
        <img src="./assets/logo_agir_ambiental.png" alt="Slide 1">
    </div>
    <div class="slide">
        <img src="./assets/logo_avina_novo.png" alt="Slide 2">
    </div>
   
</div>
<div class="fundo-realicacoes">
    <div class="carousel-container">
        <b class="realizao">Realização</b>
        
    </div>
</div>
<div class="carouse-full1 rotate-180">
    <div class="slide">
        <img src="./assets/logo_heineken_correcao (2).png" alt="Slide 1">
    </div>
    <div class="slide">
        <img src="./assets/logo_hilton_novo.png" alt="Slide 2">
    </div>
    <div class="slide">
        <img src="./assets/logo_hilton_global_novo.png" alt="Slide 3">
    </div>  
</div>
<div class="fundo-parceiros">
    <div class="carousel-container">
        <b class="parceiros">Patrocinador</b>
        
    </div>
</div> -

<div class="carouse-full2 rotate-180">
    <div class="slide">
        <img src="./assets/logo_global_novo.png" alt="Slide 3">
    </div>
    <div class="slide">
        <img src="./assets/logo_sisar_novo.png" alt="Slide 1">
    </div>
    <div class="slide">
        <img src="./assets/logo_jardim_novo.png" alt="Slide 4">
    </div>
    <div class="slide">
        <img src="./assets/logo_ceu_novo.png" alt="Slide 2">
    </div>
    <div class="slide">
        <img src="./assets/logo_clã.png" alt="Slide 4">
    </div>
    <!-- Adicione mais slides, se necessário -->
</div>

<div class="fundo-patrocinadores">
    <div class="carousel-container">
        <b class="patrocinadores">Parceiro</b>
        
    </div>
</div>