import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class Atividades_service {
    private apiUrl = environment.apiUrl;
  
    constructor(private http: HttpClient) { }
    private token = sessionStorage.getItem('token'); // Obtém o token de autorização da sessionStorage

  
    getCidadeTema() {
      const url = `${this.apiUrl}/cidades_temas`;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
      return this.http.get(url, { headers });

    }

    getAtividades() {
        const url = `${this.apiUrl}/atividades`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
        return this.http.get(url, { headers });
  
      }
      getUsersAtividade(idusers: number) {
        const url = `${this.apiUrl}/atividades_usuarios/${idusers}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
        return this.http.get(url, { headers });
      }



      getAtividadesById(id_atividade: number) {
        const url = `${this.apiUrl}/atividades/id/${id_atividade}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
        return this.http.get(url, { headers });
  
      }

      getAtividadesByIdgrupotipos(idgrupotipos: number) {
        const url = `${this.apiUrl}/atividades/idgrupotipos/${idgrupotipos}`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
        return this.http.get(url, { headers });
  
      }

      postUsersAtividade(iduser: string, id_atividade: number): Observable<any> {
        const payload = {
          id_users: iduser,
          id_atividade: id_atividade
        }
      
        const url = `${this.apiUrl}/atividades_usuarios`; // Substitua "/users" pelo endpoint correto de atualização do usuário
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); // Define o cabeçalho com o token de autorização
        return this.http.post<any>(url, payload, { headers });
      }
  }
