
import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb-browser';

@Injectable({
    providedIn: 'root'
})

export class DbPwaService {



    private db: any

    constructor() {
        this.db = new PouchDB('teste')
    }

    public addTask = (data) => {
        // Verifica se já existe um _id nos dados
        if (!data._id) {
            // Se não houver um _id, gera um novo
            data._id = new Date().toISOString();
        }
    
        this.db.get(data._id).then((doc: any) => {
            delete data._id;
            doc = Object.assign(doc, data);
            this.db.put(doc);
        }).catch(() => {
            // Se não encontrou, simplesmente insere os dados
            this.db.put(data);
        });
    }
    
    public getAll = () => new Promise((resolve, reject) => {
        this.db.allDocs({
            include_docs: true,
            attachaments: true
        }).then((rows) => {
            resolve(rows)
        }).catch(() => {
            reject(null);
        })
    })

    public clearTask(id): Promise<any> {
        return this.db.allDocs({ include_docs: true }).then((result) => {
            // Encontra o documento com a data correspondente
            const docToDelete = result.rows.find(row => row.doc._id === id);
            if (docToDelete) {
                // Remove o documento encontrado
                return this.db.remove(docToDelete.doc);
            } else {
                throw new Error('Documento não encontrado com a data fornecida.');
            }
        }).catch((error) => {
            console.error('Erro ao remover tarefa:', error);
            throw error;
        });
    }
    
}