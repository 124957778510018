import { Component, ElementRef, OnInit,HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/services/user.service';
import { Router } from '@angular/router';
import { Atividades_service } from 'src/services/Atividades.service';
import { DataService } from 'src/services/dataService';
import { Tokenfcm } from 'src/services/Tokenfcm.service';
import { NotificationService } from 'src/services/notification.service';
import { DatePipe } from '@angular/common';



interface Cidade {
  id: number;
  nome: string;
  temas: { id: number; nomeTema: string }[];
}

interface Atividade {
  id_Atividade: number;
  id_tema: number;
  descricao: string;
  horario: string;
  descricao_completa: string,
  local: string,
  cidade_id: number,
}

interface UserAtividades {
  id_users: number;
  atividadesCadastradas: number[]; // Array de IDs de atividades nas quais o usuário está cadastrado
}


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  larguraDaTela: number;
  alturaDaTela: number;

  cidadeSelecionada: number = 1;
  temaSelecionado: number | null = null;
  eventosRecentes: Atividade[] = [];
  eventos_usuario: Atividade[] = [];
  userInfo: any; // Armazenará as informações do usuário
  isLoading: boolean = true; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao
  mostrarMinhasAtividades = false; // Inicialmente, não mostra apenas as atividades do usuário atual
  private apiUrl: string;
  nome: string = '';
  foto_perfil: string = '';
  userID: number;
  NotificationAtiva: boolean;

  camposVazios: any;
  isCamposVazios: boolean;
  fotoPerfil: string;
  baseUploadUrl = 'https://api.recarrega.app.br/uploads/';


  cidadesMockadas: Cidade[] = [];
  usuariosAtividades: UserAtividades[] = [];
  eventos: Atividade[] = [];

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    private atividadesService: Atividades_service,
    private elementRef: ElementRef,
    private dataservice: DataService,
    private authServiceFCM: Tokenfcm,
    private notification: NotificationService,
    private datePipe: DatePipe

  ) {
    this.apiUrl = environment.apiUrl;
    this.obterTamanhoDaTela();

  }

  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.obterTamanhoDaTela();
  }

  obterTamanhoDaTela() {
    this.larguraDaTela = window.innerWidth;
    this.alturaDaTela = window.innerHeight;
  }
  ngOnInit(): void {
    this.getUserInfo(); // função responsavel por controlar tudp
    // Definir o primeiro ID como valor inicial selecionado
    this.eventos_usuario = this.eventos;

    this.authServiceFCM.requestPermission();

    this.getNotification();

  }

  showModalNotification: boolean = false;

  OpenModalNotification(data: any) {
    this.showModalNotification = true;
    this.notification = data;

  }

  closeModalNotification() {
    this.showModalNotification = false;

  }
  getNotification() {

    this.notification.getUserNotification().subscribe(
      (data: any) => {
        this.notification = data;

        if (data.length > 0) {
          this.NotificationAtiva = true
        } else {
          this.NotificationAtiva = false
        }

      },
      (error: any) => {
        console.error('erro:', error)
      }
    )
  }

  gettUserData(): void {
    const token = sessionStorage.getItem('token');

    this.userService.getUserData(token).subscribe(
      (userData: any) => {
        // ...
        this.userService.setUserInfo(userData); // Compartilhar dados do usuário
        // ...
      },
      (error: any) => {
        console.error('Erro ao obter dados do usuário:', error);
      }
    );
  }


  getUserInfo(): void {
    const token = sessionStorage.getItem('token');
    if (token) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      this.http.get(`${this.apiUrl}/auth/me`, { headers }).subscribe(
        (response: any) => {
          this.userInfo = response;

          //this.fotoPerfil = this.baseUploadUrl + this.userInfo.foto_perfil;

          sessionStorage.setItem('userID', this.userInfo.id);
          sessionStorage.setItem('name', this.userInfo.name);
          sessionStorage.setItem('email', this.userInfo.email);
          sessionStorage.setItem('foto_perfil', this.userInfo.foto_perfil);

          // if (this.userInfo.foto_perfil !== null) {
          //   sessionStorage.setItem('fotoPerfil',  this.fotoPerfil);
          // }else{
          //   sessionStorage.setItem('fotoPerfil',  null);
          //   this.fotoPerfil='../../assets/perfil_menor.png'
          // }

          this.nome = this.userInfo.name;
          this.foto_perfil = this.userInfo.foto_perfil;
          this.userID = this.userInfo.id;
          this.verificarCamposUsuario(this.userInfo.id);
          this.Carrega_cidade_tema_full();


        },
        (error: any) => {
          console.error('Erro ao obter informações do usuário:', error);
          this.logout();
        }
      );
    }
  }

  Carrega_cidade_tema_full() {
    this.atividadesService.getCidadeTema().subscribe(
      (data: any) => {
        this.cidadesMockadas = data;
        this.isLoading = false;
        this.carregarTemaInicial();

      },
      (error) => {
        this.isLoading = false;
        console.error('Erro ao buscar cidades e temas:', error);
      }
    );

    this.atividadesService.getAtividades().subscribe(
      (data: Atividade[]) => {
        this.eventos = data;
        // Call the method to load initial events after getting the data
      },
      (error) => {
        this.isLoading = false;
        console.error('Erro ao buscar eventos:', error);
      }
    );

    this.atividadesService.getUsersAtividade(this.userID).subscribe(
      (data: any[]) => {
        this.usuariosAtividades = data;
        // Call the method to load initial events after getting the data
      },
      (error) => {
        this.isLoading = false;
        console.error('Erro ao buscar eventos associados ao usuario:', error);
        location.reload()
      }
    );
  }

  //Carrega as Cidades temas Atividades, e usuario vinculados as atividades
  carregarTemaInicial() {
    const temas = this.getTemasCidadeSelecionada();

    if (temas.length > 0) {
      this.temaSelecionado = temas[0].id;
      this.carregarEventosRecentes();
    } else {
      this.temaSelecionado = null;
      this.eventosRecentes = [];
    }
  }

  getTemasCidadeSelecionada(): { id: number; nomeTema: string }[] {

    var cidadeSelecionada = this.cidadesMockadas.find(
      (cidade) => cidade.id == this.cidadeSelecionada
    );

    // Setando um valor para quando vim indefinido
    if (typeof cidadeSelecionada === 'undefined') {
      this.cidadeSelecionada = this.cidadesMockadas[0].id
      var cidadeSelecionada = this.cidadesMockadas[0]
    }
    return cidadeSelecionada ? cidadeSelecionada.temas : [];
  }

  selecionarTema(temaId: number) {
    this.temaSelecionado = temaId;
    this.carregarEventosRecentes();
  }

  carregarEventosRecentes() {
    const eventos = this.getEventosRecentes();
    this.eventosRecentes = eventos.slice(0, 3);
  }

  getEventosRecentesDoUsuario() {
    // Encontra o objeto de atividades cadastradas do usuário atual
    const usuarioAtualAtividades = this.usuariosAtividades.find(
      (userAtividades) => userAtividades.id_users == this.userInfo.id
    );

    // Se o usuário atual não estiver cadastrado em nenhuma atividade, retorna um array vazio
    if (!usuarioAtualAtividades) {
      return [];
    }

    // Filtra as atividades com base nas atividades cadastradas do usuário e tema
    // const eventosTemaSelecionado = this.eventos.filter((evento) => evento.id_tema == this.temaSelecionado);
    // const atividadesFiltradas = eventosTemaSelecionado.filter((evento) =>
    //   usuarioAtualAtividades.atividadesCadastradas.includes(evento.id_Atividade)
    // );

    // Filtra as atividades do usuario, todas 
    const atividadesFiltradas = this.eventos.filter((evento) =>
      usuarioAtualAtividades.atividadesCadastradas.includes(evento.id_Atividade)
    );

    return atividadesFiltradas;
  }


  getEventosRecentes() {
    const eventosFiltrados = this.eventos.filter((evento) => evento.id_tema == this.temaSelecionado && evento.cidade_id == this.cidadeSelecionada) || [];
   
    // Logica oara ordenar as atividades do para vim do mais recente em cima pro ususarios
    eventosFiltrados.sort((a, b) => {
      const horarioA = new Date(a.horario).getTime();
      const horarioB = new Date(b.horario).getTime();

      // Comparando os timestamps
      return horarioA - horarioB;
    });

    return eventosFiltrados;

  }

  verificarCamposUsuario(idusuario: number) {
    this.userService.verificarCamposUsuario(idusuario).subscribe(
      (response: any) => {
        this.isCamposVazios = response.status;
        // this.camposVazios = response.campos;
        if (this.isCamposVazios) {
          //complementar dados cadastrais
          this.router.navigate(['/atualizar_cadastro']);
        }


      },
      (error) => {
        console.error('Erro ao verificar campos do usuário:', error);
      }
    );
  }

  logout(): void {
    alert('Sessão expirada, por favor acesse novamente.');
    //Removendo token do cookie
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    sessionStorage.removeItem('token');
    window.location.reload();
  }

  // Codigo chamando o modal
  scrollToDiv(divId: string) {
    const targetDiv = document.getElementById(divId) ;
    if (targetDiv) {
      const offset = targetDiv.offsetTop;
      let top: number = this.larguraDaTela > 768 ? 50 : 0;

      console.log(top)
      window.scrollTo({
        top: offset - top,
        behavior: 'smooth' // Adiciona um efeito de rolagem suave
      });
   }
  }

  showModal: boolean = false;
  id_Atividades: number;

  openModal(idAtividade: number) {
    this.id_Atividades = idAtividade;
    this.dataservice.setAtividadeId(this.id_Atividades); // Define o valor do ID usando o serviço compartilhado
    this.showModal = true;
  }
  closeModal() {
    this.showModal = false;
    this.id_Atividades = null;
    this.dataservice.Resetclick();

  }

  onOutsideClick(event: MouseEvent): void {
    const modalContent = this.elementRef.nativeElement.querySelector('.modal-container');

    if (modalContent != null) {

      if (this.showModal && this.dataservice.clickCount >= 1) {
        const clickedElement = event.target as HTMLElement;

        if (clickedElement !== modalContent && !(modalContent as HTMLElement).contains(clickedElement)) {
          // console.log('resetando contangem')
          this.closeModal();
        }

      } else {
        this.dataservice.incrementClick();
      }
    }
  }


  onRequisitarNovamente() {
    // Coloque aqui a lógica para fazer a nova requisição
    this.Carrega_cidade_tema_full();
    // ...
  }
  irParaNovidades() {
    this.router.navigate(['/modulos/novidades']);
  }

  formatarDataHora(dataHora: string): string {
    const dataHoraObj = new Date(dataHora);
    return this.datePipe.transform(dataHoraObj, 'dd/MM/yyyy HH:mm');
  }

}