// import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ForumService } from 'src/services/Forum_Service';
import { Component, ElementRef, HostListener } from '@angular/core';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-menu-desk',
  templateUrl: './menu-desk.component.html',
  styleUrls: ['./menu-desk.component.css']
})
export class MenuDeskComponent {

  urlAtual: string;
  userInfo: any; // Armazenar� as informa��es do usu�rio

  private apiUrl: string;

  constructor(
    private router: Router,
    private location: Location,
    private http: HttpClient,
    private forumService: ForumService,
    private elementRef: ElementRef,
    private userService: UserService,
  ){
    this.urlAtual = this.location.path();
  }

  SelecionarItem(Item: string){
  
  }

  irParaTelaLogin() {
    this.router.navigate(['/login']);
  }

  irParaChat(tipo: string) {
    this.router.navigate(['/modulos/chat', tipo]);
  }

  irParaMural() {
    this.router.navigate(['/modulos/index']);
  }

  irParaaprenda() {
    this.router.navigate(['/modulos/aprenda']);
  }

  irParaMonitore() {
    this.router.navigate(['/modulos/monitore']);
  }
  irParaMapeia() {
    this.router.navigate(['/modulos/mapeie']);
  }

  irParausuarios() {
    this.router.navigate(['modulos/usuario']);
  }

  irParaColabore(tipo: string) {
    this.router.navigate(['modulos/chat', tipo]);
  }


  irParaRotaHome() {
    this.router.navigate(['modulos/rota_home']);
  }

  irParaORecarrega(){
    this.router.navigate(['/modulos/rota_home']);
  }

  logout(): void {
    console.log('saindo')
    const token = sessionStorage.getItem('token');
    //Removendo token do cookie
    if (token) {
          document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          sessionStorage.removeItem('token');
          this.userInfo = null;
          window.location.reload(); // Atualiza a p�gina

    }
  }

}
