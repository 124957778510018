<!DOCTYPE html>
<html>
  <div class="backgroud">

  <section class="box-top">
    <nav>
      <div class="icon"><img src="../../assets/perfil.png" alt=""></div>
      <div (click)="irParaTelaLogin()" class="icon"> <img src="../../assets/seta.png" alt=""></div>
    </nav>
    <div class="box-text-top">
      <h1 class="text-box-top">Cheque <br> seu email</h1>
    </div>
  </section>
  <section class="box-bottom">
    <p class="text1 text">Enviamos um link de confirmação para o seu e-mail</p>
    <p class="text2 text">{{email}}</p>
    <p class="text1 text">Se você não recebeu o e-mail,
      verifique sua caixa de spam</p>
    <div class="container-checar-e-mail">
      <p  (click)="redirectToEmailProvider(email)" class="checar-e-mail">Checar e-mail</p>
    </div>
    <h5 class="text-login" (click)="irParaTelaLogin()">IR PARA LOGIN > </h5>

  </section>
</div>

</html>