
<app-menu *ngIf="estouLogado"></app-menu>

<div *ngIf="!estouLogado" class="menu">
  <div class="menu-child"></div>
  <a (click)="scrollToDiv('inicio')">
  <div class="incio">
    <a (click)="scrollToDiv('inicio')" class="incio1">Inicio</a>
    <img class="incio-child" alt="" src="./assets/group-1000005236.svg" />
  </div>
  </a>
  <a (click)="scrollToDiv('tecnologias')">
  <div class="tecnologias">
    <a (click)="scrollToDiv('tecnologias')" class="tecnologias1">Tecnologias</a>

    <img class="tecnologias-child" alt="" src="./assets/group-1000005234.svg" />
  </div>
  </a>
  <a (click)="scrollToDiv('novidades')">
  <div class="atividades1">
    <div>
      <a (click)="scrollToDiv('novidades')" class="atividades2">Novidades</a>
    </div>
    <img class="vector-icon8" alt="" src="./assets/vector8.svg" />
  </div>
  </a>
  <div class="entrar2">
    <div class="entrar3">entrar</div>
    <img class="group-icon4"  (click)="irParaTelaLogin()" alt="" src="./assets/group1.svg" />
  </div>
  <div (click)="installPWA()"class="mais">
    <div class="mais1">download</div>
    <img class="mais-child" alt="" src="./assets/menu_download.svg" />
  </div>

  
</div>

<app-menu-desk></app-menu-desk>

<div class="visivelPc">


  <!-- backgroud envolve todo o fundo do projeto -->
  <div class="backgroud">

    <!-- backgroud envolve a secao 1 do projeto-->
    <div class="secao-1">
      <div class="img-texto">
        <img class="logo-recarrega-pc" alt="" src="./assets/group-1000005244.svg" />
        <p class="titulo">Recarrega</p>
        <p class="cidade">• São Paulo •</p>

      </div>
      <div>

        <div class="botos">
          <div class="boto-1-pc">
            Execução:
            <br>
            fevereiro/2023 até o momento
          </div>
          <div class="boto-2-pc">
            <div class="texto-boto-4"> 
            A partir de metodologia de recarga hídrica desenvolvida pelo Agir Ambiental e Fundación Avina,
            o projeto Recarrega busca promover a conservação e a segurança hídrica através da implementação de
            tecnologias sociais de forma colaborativa e integrada, garantindo também
            segurança alimentar, geração de renda e empoderamento feminino.
            Com a nossa rede de organizações e comunidades apoiadoras, o Recarrega está presente nas cidades de
            Embu das Artes, Guarulhos, Santo André e São Paulo (região oeste).
          </div>
        </div>


        </div>
      </div>

    </div>

    <div class="secao-2">
      <div class="boto-3-pc">
        <app-sobre-recarrega-sp></app-sobre-recarrega-sp>
      </div>

    </div>

    <div class="secao-3">

        <app-tecnologias-socias-sp></app-tecnologias-socias-sp>

        <div class="boto-4-pc">
         
         Com adesão de parceiros, são ofertados espaços estratégicos para instalação destas tecnologias, buscando
          contribuir com a conservação de água e da biodiversidade dos açudes Gavião, Riachão e Pacoti, fontes
          responsáveis pelo abastecimento de água da região metropolitana de Fortaleza. 
        </div>
    </div>

    <div class="secao-4">
      <div class="boto-6-pc">
        <b id="novidades" class="novidades">Onde
          estamos?</b>

        <div class="cointer-mapa">
          <div id="mapa"></div>
        </div>
      </div>
      <div (click)="IrParaHortas()" class="container-inicar-modulo">
        <p class="btn-inicar-modulo">CONHEÇA AS HORTAS DO PROJETO</p>
      </div>
    </div>


    <app-caire></app-caire>
    <!-- div caire -->


   <div class="secao-5" >
    <app-patrocinadores-sp></app-patrocinadores-sp>
   </div>



   <div class="secao-6">
    <div class="boto-7-pc">
      © 2023 Agir Ambiental, Todos os direitos reservados
    </div>

   </div>

  </div>