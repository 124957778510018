import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class AtualizarSenha {
    private apiUrl: string;
    private token = sessionStorage.getItem('token'); // Obtém o token de autorização da sessionStorage

    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl;
    }

    enviarEmailRedefinicaoSenha(email: string): Observable<any> {
        const payload = {
            email: email
        };

        const url = `${this.apiUrl}/enviar-email-redefinicao-senha`;
        return this.http.post<any>(url, payload);
    }

    alterarSenha(id: string, password: string, token: string): Observable<any> {
        const payload = {
          id: id,
          password: password,
          token: token
        };
    
        const url = `${this.apiUrl}/alterar-senha`;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    
        return this.http.post<any>(url, payload, { headers });
      }
}
