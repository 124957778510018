<div class="container-title background-color">
    <h1 class="title1">Hortas e pomares</h1>
    <img (click)="IrParaRotaHome()" class="seta" src="./assets/fechar.svg" />
  </div>
  
  <section class="box-down">
    <div *ngFor="let item of DadosMocados" class="checkbox-limit">
      <div class="box-container">
        <div class="box-limit">
          <h2 class="title-text">{{item.escola}}</h2>
        </div>
      </div>
      <img  (click)="compartilharLink(item.id, item.escola, '')" class="group-icon1" alt="" src="./assets/group.svg" />
    </div>
  </section>
  <br>
  <br>
  <br>
  <div class="agir-ambiental-todos">
    <p class="text-agir">© 2023 Agir Ambiental, Todos os direitos reservados</p>
  </div>