import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/services/dataService';
import { NotificationService } from 'src/services/notification.service';
import { novidadesServices } from 'src/services/novidades_services';

@Component({
  selector: 'app-novidades',
  templateUrl: './novidades.component.html',
  styleUrls: ['./novidades.component.scss']
})
export class NovidadesComponent {

  nome: string = '';
  novidades: any[] = []; // Inicialmente vazia
  userID: number;
  lido: boolean;
  numeroDeItensLidos: number;
  isLoading: boolean = true; // variavel responsavel por mostrar carregamento enquanto faz alguma requisicao
  foto_perfil: string;
  baseUploadUrl = 'https://api.recarrega.app.br/uploads/';
  NotificationAtiva: boolean;

  NovasNotification: any[] = []; // Inicialmente vazia


  constructor(
    private router: Router,
    private novidades_service: novidadesServices,
    private elementRef: ElementRef,
    private notification: NotificationService,
    private dataservice: DataService,

  ) { }
  estado_botao: boolean = false; // Defina o valor inicial

  ngOnInit(): void {
    this.nome = sessionStorage.getItem('name');
    this.userID = parseInt(sessionStorage.getItem('userID'), 10);
    this.CarregaAtividades();
    this.foto_perfil = sessionStorage.getItem('foto_perfil')
    this.getNotification();
  }

  CarregaAtividades() {
    this.novidades_service.getUsersNovidades(this.userID).subscribe(
      (data: any) => {
        this.novidades = data;
        // this.novidades = [{ tipo_id: 'BOAS-VINDAS AO APP!', descricao: 'Que bom ter você aqui no aplicativo do Recarrega!  Se você se inscreveu em uma oficina do Recarrega, fique atento à data, hora e local da atividade. ', lida: 1, descricao_completa: 'Que bom ter você aqui no aplicativo do Recarrega! Se você se inscreveu em uma oficina do Recarrega, fique atento à data, hora e local da atividade. Você também tem à disposição duas áreas importantes para trocar informações sobre o tema da atividade selecionada. No Colabore (acrescentar ícone) você pode trocar mensagens com outros participantes da oficina. Já no Aprenda (acrescentar ícone) há mais informações sobre o tema; é um material de apoio que pode ajudar você a implementar as tecnologias do Recarrega. E se tiver qualquer problema dentro do app, você pode entrar em contato com o suporte. ' }, { tipo_id: 'Notícia', descricao: 'teste', lida: 1, descricao_completa: 'lorem kkkkkkkkkkkkkkkkkkkkk' }]
        this.numeroDeItensLidos = this.novidades.filter(novidade => novidade.lida === false).length;
        this.isLoading = false;
      },
      (error) => {
        console.error('Erro ao buscar atividades:', error);
        alert('erro')
      }
    );

  }

  lerAtividades(novidadeId: number) {
    this.lido = true;
    this.novidades_service.postUsersNovidades(this.userID, this.lido, novidadeId)
      .subscribe(
        response => {
          alert(response.message);
          this.CarregaAtividades();
        },
        error => {
          console.error('Erro ao cadastrar :', error);
          alert(error.error.message)
        }
      );
  }

  deleteNovidade(lido_ID: number) {

    this.novidades_service.deleteUsersNovidades(lido_ID).subscribe(
      (response) => {
        alert(response.message);
        this.CarregaAtividades();
        // Faça algo após a exclusão bem-sucedida
      },
      (error) => {
        console.error('Erro ao deletar novidade:', error);
      }
    );
  }

  scrollToDiv(divId: string): void {
    const targetElement = this.elementRef.nativeElement.querySelector('#' + divId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  getStyle(notificationtipo: string) {
    switch (notificationtipo) {
      case 'Atualizações app':
        return { background: '#ED7202' };
      case 'Agende':
        return { background: '#9DA34A' };
      case 'Novo conteúdo':
        return { background: '#532014' };
      case 'Notícia':
        return { background: '#003636' };
      default:
        return {}; // Se não corresponder a nenhum tipo, retorna estilos vazios
    }
  }

  toggleEstadoBotao() {
    this.estado_botao = !this.estado_botao;
  }

  irParaAtividades() {
    this.router.navigate(['/modulos/index']);
  }

  showModalNotification: boolean = false;
  OpenModalNotification(data: any) {
    this.showModalNotification = true;
    this.NovasNotification = data;


  }

  closeModalNotification() {
    this.showModalNotification = false;

  }
  getNotification() {

    this.notification.getUserNotification().subscribe(
      (data: any) => {
        this.notification = data;

        if (data.length > 0) {
          this.NotificationAtiva = true
        } else {
          this.NotificationAtiva = false
        }

      },
      (error: any) => {
        console.error('erro:', error)
      }
    )
  }

  DadosMural: any[] = [];


  mostrarModal = false; // Inicialmente, o modal está oculto
  dadosDoMural: any[]; // Aqui você pode definir os dados a serem passados para o modal
  
  abrirModal(data:any[]) {
    this.mostrarModal = true;
    this.DadosMural = data;
    // Defina os dados a serem passados para o modal em this.dadosDoMural, se necessário
  }
  
  fecharModal() {
    this.mostrarModal = false;
    this.dataservice.Resetclick();
  }

  onOutsideClick(event: MouseEvent): void {
    const modalContent = this.elementRef.nativeElement.querySelector('.modal-container');

    if (modalContent != null) {

      if (this.mostrarModal && this.dataservice.clickCount >= 1) {
        const clickedElement = event.target as HTMLElement;
        console.log("fechando Modal pelo componente pai")

        if (clickedElement !== modalContent && !(modalContent as HTMLElement).contains(clickedElement)) {
          this.fecharModal();
          console.log("fechando Modal pelo componente pai")
        }

      } else {
        this.dataservice.incrementClick();
      }
    }
  }
}
