<div class="backgroud">
  <app-carregamento-padrao *ngIf="isLoading"></app-carregamento-padrao>

  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="voltar-desk">
      <h5 class="text-voltardesk" (click)="irParaTelaHome()">
        < VOLTAR </h5>
    </div>
    <div class="tudo">
      <div>
        <img class="img-login" src="../../assets/img_login.png" alt="">
      </div>
    <div class="root">
      <div class="container-title">
        <h1 class="title">Entrar</h1>
      </div>
      <div class="root-social">
        <div class="container-social">
          <div class="social">
            <img class="img" src="../.././../assets/facebook.png" alt />
          </div>
          <div class="social">
            <img class="img" src="../.././../assets/google.png" alt (click)="loginWithGoogle()" />
          </div>

          <!-- <div class="social">
            <img class="img" src="../.././../assets/apple.png" alt />
          </div> -->
        </div>
      </div>
      <div class="login">
        <div class="containerName" [class.error-input]="showEmailError">
          <input class="input" type="text" formControlName="email" autocomplete="new-password" placeholder="E-MAIL"
            (input)="inputEmail()" />
          <span *ngIf="showEmailError" class="error-message alert alert-danger" role="alert">{{ emailErrorMessage
            }}</span>
        </div>

        <div class="containerName" [class.error-input]="showPasswordError">
          <input class="input" type="password" autocomplete="new-password" formControlName="password"
            placeholder="SENHA" (input)="inputPassword()" />
          <span *ngIf="showPasswordError" class="error-message alert alert-danger" role="alert">{{ passwordErrorMessage
            }}</span>
        </div>
        <div class="root-toogle">
          <div class="toggle-container">
            <input type="checkbox" id="toggle-btn" class="toggle-checkbox" />
            <label for="toggle-btn" class="toggle-label"></label>
            <h2 class="text-lembrar-senha">LEMBRAR SENHA</h2>
            <h4 class="text-social" (click)="irParaAlterasenha()">ALTERAR SENHA</h4>
          </div>
        </div>
      </div>
      <div class="botoes">
      <div class="root-btn">
        <div class="buttomLogin">
          <button type="submit" class="text-btn custom-button">
            ACESSAR O RECARREGA</button>
        </div>
      </div>
      <div (click)="irParaTelaCadastroUser()" class="cadastro">
      <div class="root-btn2">
        <div class="buttomLogin2">
          <button type="submit" class="text-btn custom-button">
            CRIAR UMA CONTA</button>
        </div>
      </div> 
    </div>
    </div>

      <div class="root-voltar">
        <h5 class="text-voltar" (click)="irParaTelaHome()">
          < VOLTAR </h5>
      </div>
    </div>
  </div>
  </form>
</div>