<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css"
  integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">


<div *ngIf="Forms" class="backgroud">

  <div class="container-title background-color">
    <h1 class="title">Monitore</h1>
    <img (click)="irParaMonitore()" class="seta" src="./assets/seta.png" />
  </div>

  <br />

  <form class="form-all" [formGroup]="formData" (ngSubmit)="enviarRespostas()">

    <div *ngFor="let form of Forms">
      <div *ngIf="form.tipo === 'normal'">
        <label class="label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <input class="input form-control" [ngClass]="{ 'campo-vazio': camposVazios.includes('normal_' + form.id) }"
            formControlName="{{ 'normal_' + form.id }}" type="text" placeholder="" required />
          <span *ngIf="camposVazios.includes('normal_' + form.id)" class="campo-vazio-mensagem">Campo
            obrigatório*</span>
        </div>
      </div>


      <div *ngIf="form.tipo === 'data'">
        <label class="label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <input class="input form-control" formControlName="{{ 'data_' + form.id }}" type="date" />
          <span *ngIf="camposVazios.includes('data_' + form.id)" class="campo-vazio-mensagem">Campo obrigatório*</span>
        </div>

      </div>


      <div class="largura-formulario" *ngIf="form.tipo === 'Geolocalizacao'">
        <label class=" inputopcoes label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <div class="loc espaco-top" (click)="obterGeolocalizacao(form.id )"><i class="fa-solid fa-location-dot">
            </i>Localização</div>
          <div class="label-text">{{ localizacao }}</div>
          <input class="input form-control" formControlName="{{ 'Geolocalizacao_' + form.id }}" type="hidden" />
          <span *ngIf="camposVazios.includes('Geolocalizacao_' + form.id)" class="campo-vazio-mensagem">Campo
            obrigatório*</span>
        </div>
      </div>


      <div *ngIf="form.tipo === 'lista'">
        <label class="label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <select class="input form-control" formControlName="{{ 'lista_' + form.id }}" [(ngModel)]="opcaoSelecionada">
            <option [value]="opcao" *ngFor="let opcao of form.opcoes; first as isFirst" [selected]="opcao">{{ opcao }}
            </option>
          </select>
          <span *ngIf="camposVazios.includes('lista_' + form.id)" class="campo-vazio-mensagem">Campo obrigatório*</span>
        </div>
      </div>


      <div class="largura-formulario" *ngIf="form.tipo === 'alternativa'">
        <label class="question">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <div class="inputopcoes" *ngFor="let opcao of form.opcoes">
            <label class="radio-label">
              <input formControlName="{{ 'alternativa_' + form.id }}" type="radio" [value]="opcao">{{ opcao }}
            </label>
          </div>
          <span *ngIf="camposVazios.includes('alternativa_' + form.id)" class="campo-vazio-mensagem">Campo
            obrigatório*</span>
        </div>
      </div>


      <div class="largura-formulario" *ngIf="form.tipo === 'checkBox'">
        <label class="label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <div class="inputopcoes" *ngFor="let opcao of form.opcoes">
            <input class="inputchebox" formControlName="{{ 'checkBox_' + form.id }}" type="checkbox" [value]="opcao"
              (change)="onCheckboxChange($event.target.checked, opcao, form.id)">
            {{ opcao }}
          </div>
          <span *ngIf="camposVazios.includes('checkBox_' + form.id)" class="campo-vazio-mensagem">Campo
            obrigatório*</span>
        </div>
      </div>


      <div class="largura-formulario" *ngIf="form.tipo === 'imagem'">
        <label for="imagemInput" class="label-text inputopcoes">{{ form.pergunta }}</label>
        <div *ngIf="form.resposta!== undefined">
          <img [src]="form.resposta " alt="Imagem" class="foto" />
        </div>


        <div *ngIf="form.resposta == undefined">
          <label for="{{'imagemInput_'+form.id}}" class="bt-foto espaco-top"><i
              class="fa-regular fa-images"></i>Selecionar
            Foto</label>
          <input id="{{'imagemInput_'+form.id}}" #fotoInput formControlName="{{ 'imagem_' + form.id }}" type="file"
            (change)="onFileSelected($event,form.id)" style="display: none;">
          <div *ngIf="imagemUrl" class="foto-container">
            <img [src]="imagemUrl" alt="Imagem" class="foto" />
            <i (click)="removerFoto(form.id )" class="fa-solid fa-xmark botao-remover"></i>
          </div>
          <span *ngIf="camposVazios.includes('imagem_' + form.id)" class="campo-vazio-mensagem">Campo
            obrigatório*</span>
        </div>

      </div>

    </div>

    <div *ngIf="camposVazios.length > 0">
      <p class="campo-vazio-mensagem">Por favor, preencha todos os campos!</p>
      <!-- <p>Campos vazios:</p>
      <ul>
        <li *ngFor="let campo of camposVazios">{{ campo }}</li>
      </ul> -->
    </div>

    <!-- Desativo botão de editar
    <br />
    <div class="root-btn">
      <div class="tela-7-inner">
        <div class="boto-2-parent">
          <button type="submit" class="custom-button">
            <div class="boto-2"></div>
            <div class="quero-me-cadastrar-wrapper">
              <div class="text-input">SALVAR DADOS</div>
            </div>
          </button>
        </div>
      </div>
    </div> -->

    <div (click)="irParaMonitore()" class="root-voltar">
      <h5 class="text-voltar">
        < VOLTAR </h5>
    </div>
  </form>
</div>